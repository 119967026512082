/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import ItemPickup from "../../shared/components/item-pickup";
import { Row, Col, Alert } from "antd";
import classes from "./equipment-pickup.module.scss";
import { useHistory } from "react-router-dom";
import LocationSelection from "../../shared/components/location-selection";
import RequestDetails from "../../shared/components/request-details";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import {
  fetchCampuses,
  fetchUnits,
  fetchReqSlas,
} from "../../shared/reducers/actions";
import {
  fetchPickupItems,
  placePickUpOrder,
  clearErrorMessage,
} from "../equipment-pickup/action";
import { setSuccessMessage } from "../home/action";
import { PICKUP_REQUEST_TYPE_ID } from "../../../common/shared-constants";
import { login } from "../login/actions";

const EquipmentPickup = ({
  campuses,
  units,
  fetchUnits,
  pickupItems,
  slas,
  fetchPickupItems,
  fetchReqSlas,
  placePickUpOrder,
  errorMessage,
  clearErrorMessage,
  login,
  anonymous,
  account,
  campusData,
}: any) => {
  const history = useHistory();
  const [cartItems, setCartItems] = useState<any>([]);
  const [locationValues, setLocationValues] = useState<any>();
  const [loginError, setLoginError] = useState<any>();
  const [view, setView] = useState("form");
  useEffect(() => {
    campusData.id && fetchReqSlas(PICKUP_REQUEST_TYPE_ID, campusData.id);
  }, [campusData]);

  const onItemsSelected = (items: any) => {
    setCartItems(items);
  };

  const quantityEdit = (itemId: any, action: any, newQuantity?: number) => {
    const items = cartItems.map((cartItem: any) => {
      if (cartItem.item.id === itemId) {
        if (action === "INCREASE") {
          cartItem.quantity += 1;
        } else if (action === "EDIT") {
          cartItem.quantity = newQuantity;
        } else {
          cartItem.quantity -= 1;
        }
      }
      return cartItem;
    });
    setCartItems(items);
  };

  const onItemRemove = (removedId: any) => {
    let itemRemoving = [...cartItems];
    itemRemoving = itemRemoving.filter(
      (cartItem) => cartItem.item.id !== removedId
    );
    setCartItems(itemRemoving);
  };

  const removeItems = (removeIds: any) => {
    let itemRemoving = [...cartItems];
    itemRemoving = itemRemoving.filter(
      (cartItem) => !removeIds.includes(cartItem.item.id)
    );
    setCartItems(itemRemoving);
  };

  const onNext = async (data: any) => {
    setLocationValues(data);
    await fetchPickupItems(data.unitId, data.campusId);
    if (data !== false) {
      setView("itemselection");
    }
  };

  const backHome = () => {
    history.push("/");
  };

  const fetchUnitsByCampusId = (campusId: any) => {
    fetchUnits(campusId);
  };

  const goBack = (viewVal: any) => {
    setView(viewVal);
  };

  const onItemAdd = (added: any) => {
    const found = cartItems.find(
      (cartItem: any) => cartItem.item.id === added.id
    );
    if (found === undefined) {
      setCartItems([...cartItems, { item: added, quantity: 1 }]);
    }
  };

  const onFinalSubmit = async (orderData: any) => {
    if (anonymous) {
      const loginResponse = await login(
        {
          name: orderData.name,
          phone: orderData.phoneNumber,
          email: orderData.email,
        },
        anonymous
      );
      if (loginResponse.status === "success") {
        setLoginError("");
        const res = await placePickUpOrder({
          ...orderData,
          externalUserId: loginResponse.user.id,
        });
        if (res.status === "success") {
          history.push("/request");
        }
      } else {
        setLoginError(loginResponse.errormessage);
      }
    } else {
      const res = await placePickUpOrder({
        ...orderData,
        externalUserId: account.id,
      });
      if (res.status === "success") {
        history.push("/request");
      }
    }
  };

  const onItemAllClear = (e: any) => {
    setCartItems(e);
  };

  const onCartCheckout = (cart: any) => {
    setView("requestdetail");
  };

  const viewSelection = (view: any) => {
    switch (view) {
      case "form":
        return (
          <LocationSelection
            onNext={(e: any) => {
              onNext(e);
            }}
            backHome={backHome}
            campusId={campusData.id}
            units={units}
            fetchUnits={fetchUnitsByCampusId}
            locationValues={locationValues}
            cartItems={cartItems}
            removeItems={removeItems}
          />
        );
      case "itemselection":
        return (
          <ItemPickup
            cartItems={cartItems}
            items={pickupItems}
            onItemsSelected={onItemsSelected}
            quantityEdit={quantityEdit}
            goBack={goBack}
            onItemRemove={onItemRemove}
            clear={onItemAllClear}
            checkout={onCartCheckout}
          />
        );
      case "requestdetail":
        return (
          <RequestDetails
            setLoginError={setLoginError}
            quantityEdit={quantityEdit}
            goBack={goBack}
            locationValues={locationValues}
            campus={campusData}
            units={units}
            cartItems={cartItems}
            slas={slas}
            onItemAdd={onItemAdd}
            onItemRemove={onItemRemove}
            onSubmit={onFinalSubmit}
            requestTypeId={PICKUP_REQUEST_TYPE_ID}
            errorMessage={errorMessage}
            clearErrorMessage={clearErrorMessage}
            account={account}
          />
        );
      default:
        return (
          <LocationSelection
            onNext={(e: any) => {
              onNext(e);
            }}
            backHome={backHome}
            campusId={campusData.id}
            units={units}
            fetchUnits={fetchUnitsByCampusId}
            locationValues={locationValues}
            cartItems={cartItems}
            removeItems={removeItems}
          />
        );
    }
  };

  return (
    <div className={classes.equipmentContainer}>
      <Row>
        <Col span={24}>
          <p className={classes.titleText}>Equipment Pickup</p>
        </Col>
        {loginError && (
          <Col span={24}>
            <Alert message={loginError} closable type="error"></Alert>
          </Col>
        )}
      </Row>
      {viewSelection(view)}
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  requestData,
  pickUpManagement,
  homeReducer,
}: IRootState) => ({
  campuses: requestData.campuses,
  units: requestData.units,
  errorMsg: requestData.errorMessage,
  slas: requestData.slas,
  pickupItems: pickUpManagement.pickupItems,
  errorMessage: pickUpManagement.errorMessage,
  successMessage: pickUpManagement.successMessage,
  anonymous: authentication.anonymous,
  account: authentication.account,
  campusData: homeReducer.currentCampus,
});
const mapDispatchToProps = {
  fetchCampuses,
  fetchUnits,
  fetchPickupItems,
  fetchReqSlas,
  placePickUpOrder,
  setSuccessMessage,
  clearErrorMessage,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentPickup);
