import { Dispatch } from "redux";
import { adminRootInstance } from "../../../common/config/axios-config";
import { ORDER_STATUS_MAP } from "../../../common/shared-constants";
import {
  FETCH_SLAs_FAIL,
  FETCH_SLAs_SUCCESS,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAIL,
  FETCH_UNITS_SUCCESS,
  FETCH_UNITS_FAIL,
  FETCH_STATUSES_SUCCESS,
  // FETCH_STATUSES_FAIL,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAIL,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_SPECIALITYITEMS_SUCCESS,
  FETCH_SPECIALITYITEMS_FAIL,
  CLEAR_ALL,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAIL,
  SET_REPORT_FILTER,
  FETCH_REPORT_START,
} from "./action-types";
const api_dateFormat = "YYYY-MM-DD";
//Common method to fetch the report respone and set the data in redux / set error message if it fails
interface QueryParams {
  pageSize: string | number;
  page: string | number;
  type?: "summary" | "detailed";
  request?: string;
  unit?: string;
  from: string;
  to: string;
  sla?: string;
  status?: string;
  items?: string;
  users?: string;
  splItems?: string;
}
const fetchReports = async (
  endpoint: string,
  queryParams: QueryParams,
  dispatch: Dispatch
) => {
  return await adminRootInstance
    .get(endpoint, {
      params: queryParams,
    })
    .then((res: any) => {
      if (res?.data)
        dispatch({
          type: FETCH_REPORT_SUCCESS,
          payload: {
            reportData: res.data.reports,
            totalCount: res.data.pagination.totalCount,
          },
        });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: FETCH_REPORT_FAIL,
        payload: { errorMessage: err?.response?.data?.message },
      });
      return { success: false };
    });
};
// set Filter Data for Report (form data)
export const setReportFilterData = (data: Object) => async (dispatch: any) => {
  dispatch({
    type: SET_REPORT_FILTER,
    payload: { filterData: data },
  });
};

export const resetReportState = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_ALL,
  });
};
//fetch all SLAS --Completed
export const fetchAllSLAs = () => async (dispatch: any) => {
  return adminRootInstance //change it back to adminRootInstance once api is available
    .get("sla/list")
    .then((res: any) => {
      dispatch({
        type: FETCH_SLAs_SUCCESS,
        payload: {
          slas: res.data.data,
          successMessage: "Fetched SLAs Succesfully",
        },
      });
      return { success: true };
    })
    .catch((err: any) => {
      dispatch({
        type: FETCH_SLAs_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};

// Get Order / Request Types --Completed
export const fetchAllOrderTypes = () => async (dispatch: any) => {
  await adminRootInstance
    .get("request-types/")
    .then((res: any) => {
      dispatch({
        type: FETCH_ORDERS_SUCCESS,
        payload: {
          orderTypes: res.data.data,
        },
      });
    })
    .catch((err: any) => {
      dispatch({
        type: FETCH_ORDERS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};

// Get all units with name and id --Completed
export const fetchAllUnits = () => async (dispatch: any) => {
  await adminRootInstance
    .get("units/all")
    .then((res: any) => {
      dispatch({
        type: FETCH_UNITS_SUCCESS,
        payload: {
          units: res.data.data,
        },
      });
    })
    .catch((err: any) => {
      dispatch({
        type: FETCH_UNITS_FAIL,
        payload: { fetchErrorMessage: err.response?.data?.message },
      });
    });
};
//SLA BASED REPORTS
export const fetchSLABasedReport =
  ({ search = "", limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { reportType, sla, orderType, units, fromDate, toDate } = rest;
    dispatch({ type: FETCH_REPORT_START });
    return await fetchReports(
      "reports/sla",
      {
        pageSize: limit,
        page,
        type: reportType,
        sla: reportType === "detailed" ? sla : sla.join(","),
        request: orderType.join(","),
        unit: units.join(","),
        from: fromDate.format(api_dateFormat),
        to: toDate.format(api_dateFormat),
      },
      dispatch
    );
  };
//fetch All Statuses (order statuses)
export const fetchAllStatuses = () => async (dispatch: any) => {
  dispatch({
    type: FETCH_STATUSES_SUCCESS,
    payload: {
      statuses: ORDER_STATUS_MAP,
    },
  });
};
//STATUS BASED REPORTS
export const fetchStatusBasedReportSummary =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { reportType, status, orderType, units, fromDate, toDate } = rest;
    dispatch({ type: FETCH_REPORT_START });
    return await fetchReports(
      "reports/status",
      {
        pageSize: limit,
        page,
        type: reportType,
        status: reportType === "detailed" ? status : status.join(","),
        request: orderType.join(","),
        unit: units.join(","),
        from: fromDate.format(api_dateFormat),
        to: toDate.format(api_dateFormat),
      },
      dispatch
    );
  };
//Fetch all items
export const fetchAllItems = () => async (dispatch: any) => {
  return adminRootInstance //change it back to adminRootInstance once api is available
    .get("item/list/all")
    .then((res: any) => {
      dispatch({
        type: FETCH_ITEMS_SUCCESS,
        payload: {
          items: res.data.data,
          successMessage: "Fetched Items Succesfully",
        },
      });
      return { success: true, data: res.data.data };
    })
    .catch((err: any) => {
      dispatch({
        type: FETCH_ITEMS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false, data: [] };
    });
};

//ITEMS BASED REPORTS
export const fetchItemBasedReport =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { reportType, items, orderType, units, fromDate, toDate } = rest;
    dispatch({ type: FETCH_REPORT_START });
    return await fetchReports(
      "reports/item",
      {
        pageSize: limit,
        page,
        type: reportType,
        items: reportType === "detailed" ? items : items.join(","),
        request: orderType.join(","),
        unit: units.join(","),
        from: fromDate.format(api_dateFormat),
        to: toDate.format(api_dateFormat),
      },
      dispatch
    );
  };

//
export const fetchAllUsers = () => async (dispatch: any) => {
  return adminRootInstance //change it back to adminRootInstance once api is available
    .get("user/list")
    .then((res: any) => {
      dispatch({
        type: FETCH_USERS_SUCCESS,
        payload: {
          allUsers: res.data.data,
          successMessage: "Fetched Users Succesfully",
        },
      });
      return { success: true };
    })
    .catch((err: any) => {
      dispatch({
        type: FETCH_USERS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};
//PRODUCTIVITY REPORT
export const fetchProductivityReport =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { reportType, users, orderType, units, fromDate, toDate } = rest;
    dispatch({ type: FETCH_REPORT_START });
    return await fetchReports(
      "reports/productivity",
      {
        pageSize: limit,
        page,
        type: reportType,
        users: reportType === "detailed" ? users : users.join(","),
        request: orderType.join(","),
        unit: units.join(","),
        from: fromDate.format(),
        to: toDate.format(api_dateFormat),
      },
      dispatch
    );
  };

//
export const fetchAllSpecialityItems = () => async (dispatch: any) => {
  return adminRootInstance
    .get("item/splitem/list")
    .then((res: any) => {
      dispatch({
        type: FETCH_SPECIALITYITEMS_SUCCESS,
        payload: {
          items: res.data.data,
          successMessage: "Updated Successfully",
        },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: FETCH_SPECIALITYITEMS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};
//FETCH SPECILAITY ITEM REPORTS DATA
export const fetchSpecialityItemsReport =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { reportType, specialityItem, orderType, units, fromDate, toDate } =
      rest;
    dispatch({ type: FETCH_REPORT_START });
    return await fetchReports(
      "reports/spl-item",
      {
        pageSize: limit,
        page,
        type: reportType,
        splItems: specialityItem.join(","),
        request: orderType.join(","),
        unit: units.join(","),
        from: fromDate.format(api_dateFormat),
        to: toDate.format(api_dateFormat),
      },
      dispatch
    );
  };
//FETCH OVERALL REPORT DATA
export const fetchOverallReport =
  ({ limit = 30, page = 1, ...rest }: any) =>
  async (dispatch: any) => {
    const { fromDate, toDate } = rest;
    dispatch({ type: FETCH_REPORT_START });
    return await fetchReports(
      "reports/overall",
      {
        pageSize: limit,
        page,
        from: fromDate.format(api_dateFormat),
        to: toDate.format(api_dateFormat),
      },
      dispatch
    );
  };
export const clearDetails = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_ALL,
  });
};
