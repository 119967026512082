/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import { Button, Alert, Typography } from "antd";
import { FormOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import ListComponent from "../../shared/components/list-component/list-component";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  VIEW_TEXT,
  EDIT_TEXT,
  DELETE_TEXT,
  CREATE,
  VIEW,
  EDIT,
  DELETE,
  SLA_PAGE,
  BUTTON_GREEN_HEX,
} from "../../../common/shared-constants";
import DeleteModal from "../../shared/components/delete-modal/delete-modal";
import { AbilityContext } from "../../shared/ability/can";
import { useAbility } from "@casl/react";
import { Can } from "../../shared/ability/can";
import { Helmet } from "react-helmet";
import classes from "./sla.module.scss";
import { clearMessage } from "../../../common/actions/common-actions";
import { CLEAR_SLA_FETCH_ERROR_MSG, CLEAR_SLA_MSG } from "./action-types";
import {
  fetchSLAs,
  createSLA,
  updateSLA,
  deleteSLA,
  fetchRequests,
} from "./actions";
import SLAViewModal from "./sla-modals/sla-view-modal";
import SLAModal from "./sla-modals/sla-modal";
import { fetchAllCampuses } from "../campus/actions";
const { Title } = Typography;

const SLAPage = ({
  totalCount,
  errorMsg,
  fetchSLAs,
  successMsg,
  fetchErrorMsg,
  clearMessage,
  createSLA,
  updateSLA,
  deleteSLA,
  slas,
  requests,
  fetchRequests,
  slasLoading,
  fetchAllCampuses,
  allCampuses,
}: any) => {
  const ability = useAbility(AbilityContext);
  const [loading, setLoading] = useState(false);
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: [30, 50, 100, 120],
    showSizeChanger: true,
  });

  const { search, page, limit, showSizeChanger, pageSizeOptions } = tableOpt;

  const [modalOp, setModalOp] = useState({
    visible: false,
    mode: CREATE,
    sla: null,
    title: "Delete SLA",
    label: "SLA",
  });

  useEffect(() => {
    fetchSLAs({ search: search, limit, page: DEFAULT_PAGE });
    fetchRequests();
    fetchAllCampuses();
    return () => {
      clearMessages();
      clearFetchError();
    };
  }, []);

  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  const clearMessages = () => {
    clearMessage(CLEAR_SLA_MSG);
  };
  const clearFetchError = () => {
    clearMessage(CLEAR_SLA_FETCH_ERROR_MSG);
  };

  const handleCancel = () => {
    setModalOp({
      ...modalOp,
      visible: false,
    });
  };

  const onView = (sla: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: VIEW,
      sla,
    });
  };
  const onDelete = (sla: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: DELETE,
      sla,
    });
  };
  const onEdit = (sla: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: EDIT,
      sla,
    });
  };

  const onSearch = ({ target }: any) => {
    setOperation({
      ...tableOpt,
      search: target.value,
      page: DEFAULT_PAGE,
    });
    fetchSLAs({ search: target.value, limit, page: DEFAULT_PAGE });
  };

  const handleTableChange = (pagination: any) => {
    setOperation({
      ...tableOpt,
      page: pagination.current,
      limit: pagination.pageSize,
    });
    fetchSLAs({ search, limit, page: pagination.current });
  };

  const onAdd = () => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: CREATE,
      sla: null,
    });
  };

  const handleSubmit = async (sla: any) => {
    setLoading(true);
    let result: any;
    if (modalOp.mode === CREATE) {
      result = await createSLA(sla);
    } else if (modalOp.mode === EDIT) {
      result = await updateSLA(sla);
    } else {
      result = await deleteSLA(sla);
    }
    if (result.success) {
      fetchSLAs({ search, limit, page: page });
      setModalOp({
        ...modalOp,
        visible: false,
      });
    }
    setLoading(false);
  };

  const columns: any = [
    {
      align: "center",
      title: "Name",
      dataIndex: "name",
      width: "20%",
      key: "name",
      render: (name: String) => name,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Mapped Campus",
      dataIndex: "campuses",
      key: "campuses",
      width: "20%",
      render: (campuses: any) =>
        campuses.length
          ? campuses
              ?.map((campus: any) =>
                allCampuses.find((unit: any) => unit.id === campus)
              )
              .reduce(
                (accumulator: any, currentValue: any, index: any) =>
                  index === 0
                    ? `${currentValue?.name ? currentValue?.name : ""}`
                    : `${accumulator} ${currentValue?.name ? "," : ""} ${
                        currentValue?.name ? currentValue?.name : ""
                      }`,
                ""
              )
          : "-",
      ellipsis: true,
    },
    {
      align: "center",
      title: "Time Limit",
      dataIndex: "timeLimit",
      key: "timeLimit",
      width: "20%",
      render: (limit: String) => limit,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Color",
      dataIndex: "color",
      key: "color",
      width: "20%",
      render: (color: any) => {
        return (
          <div
            className={classes.slaColor}
            style={{
              backgroundColor: color,
            }}
          />
        );
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Disclaimer",
      dataIndex: "disclaimerStatus",
      key: "disclaimerStatus",
      width: "20%",
      render: (disclaimer: boolean) => (disclaimer ? "ON" : "OFF"),
      ellipsis: true,
    },
    {
      align: "center",
      title: "Actions",
      key: "action",
      width: "20%",
      ellipsis: true,
      render: (record: any) => (
        <div className="actionIcons">
          <Button
            size="small"
            title={VIEW_TEXT}
            className="icon-btn"
            onClick={() => {
              onView(record);
            }}
          >
            <EyeOutlined
              style={{ color: BUTTON_GREEN_HEX }}
              className="edit-icon"
            />
          </Button>
          {ability.can("edit", "SLA") && (
            <Button
              size="small"
              title={EDIT_TEXT}
              className="icon-btn"
              onClick={() => {
                onEdit(record);
              }}
            >
              <FormOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="edit-icon"
              />
            </Button>
          )}
          <Can I="delete" a="SLA">
            <Button
              size="small"
              title={DELETE_TEXT}
              className="icon-btn"
              onClick={() => {
                onDelete(record);
              }}
            >
              <DeleteOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="delete-icon"
              />
            </Button>
          </Can>
        </div>
      ),
      responsive: ["lg", "md"],
    },
  ];

  return (
    <div>
      <Helmet>
        <title>SLAs | Admin Portal</title>
      </Helmet>
      <Title className={classes.titleText} level={5}>
        SLAs
      </Title>
      {successMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={successMsg}
          type="success"
          closable
          afterClose={clearMessages}
        />
      ) : null}
      {fetchErrorMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={fetchErrorMsg}
          type="error"
          closable
          afterClose={clearFetchError}
        />
      ) : null}
      <ListComponent
        type={SLA_PAGE}
        loading={slasLoading}
        dataSource={slas}
        columns={columns}
        handleTableChange={handleTableChange}
        onSearch={onSearch}
        onAdd={onAdd}
        pagination={{
          current: page,
          pageSize: limit,
          total: totalCount,
          showSizeChanger,
          pageSizeOptions,
        }}
        addButtonLabel={"Add New SLA"}
        searchLabel={"Search by name"}
      />
      {modalOp.mode === VIEW ? (
        <SLAViewModal
          handelClose={handleCancel}
          visible={modalOp.visible}
          sla={modalOp.sla}
          allCampuses={allCampuses}
          requests={requests}
        />
      ) : null}
      {(modalOp.mode === EDIT || modalOp.mode === CREATE) && modalOp.visible ? (
        <SLAModal
          visible={modalOp.visible}
          allCampuses={allCampuses}
          sla={modalOp.sla}
          mode={modalOp.mode}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          loading={loading}
          requests={requests}
        />
      ) : null}
      {modalOp.mode === DELETE && modalOp.visible ? (
        <DeleteModal
          loading={loading}
          entity={modalOp.sla}
          visible={modalOp.visible}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          title={modalOp.title}
          label={modalOp.label}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ slaManagement, campusManagement }: IRootState) => ({
  errorMsg: slaManagement.errorMessage,
  successMsg: slaManagement.successMessage,
  slasLoading: slaManagement.loading,
  slas: slaManagement.slas,
  totalCount: slaManagement.totalCount,
  fetchErrorMsg: slaManagement.fetchErrorMessage,
  requests: slaManagement.requestTypes,
  allCampuses: campusManagement.allCampuses,
});
const mapDispatchToProps = {
  fetchSLAs,
  createSLA,
  updateSLA,
  deleteSLA,
  fetchAllCampuses,
  clearMessage,
  fetchRequests,
};
export default connect(mapStateToProps, mapDispatchToProps)(SLAPage);
