import {
  FETCH_REQUEST_ALL_CAMPUSES_FAIL,
  FETCH_REQUEST_ALL_CAMPUSES_SUCCESS,
  FETCH_CAMPUS_UNITS_SUCCESS,
  FETCH_CAMPUS_UNITS_FAIL,
  FETCH_REQ_SLA_FAIL,
  FETCH_REQ_SLA_SUCCESS,
  FETCH_REQUEST_START,
} from "../reducers/action-types";
import { requestorRootInstance } from "../../../common/config/axios-config";

export const fetchCampuses = () => async (dispatch: any) => {
  dispatch({
    type: FETCH_REQUEST_START,
  });
  await requestorRootInstance
    .get(`campuses/all`)
    .then((res: any) => {
      const campuses = res.data.data.filter((campus: any) => campus.id !== 1);
      dispatch({
        type: FETCH_REQUEST_ALL_CAMPUSES_SUCCESS,
        payload: {
          campuses: campuses,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_REQUEST_ALL_CAMPUSES_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};

export const fetchUnits = (campusId: any) => async (dispatch: any) => {
  return await requestorRootInstance
    .get(`units/${campusId}`)
    .then((res) => {
      dispatch({
        type: FETCH_CAMPUS_UNITS_SUCCESS,
        payload: { units: res.data.data },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_CAMPUS_UNITS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};

export const fetchReqSlas =
  (reqId: any, campusId: number) => async (dispatch: any) => {
    await requestorRootInstance
      .get(`sla/request-type/${reqId}/${campusId}`)
      .then((res: any) => {
        dispatch({
          type: FETCH_REQ_SLA_SUCCESS,
          payload: {
            slas: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_REQ_SLA_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
      });
  };
