import imageCompression from "browser-image-compression";

export const compressImage: any = async (image: any) => {
  const imageFile = image;
  let file;
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    // useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    file = new File([compressedFile], image.name, { type: image.type });
  } catch (error) {
    console.log(error);
  }
  return file;
};
