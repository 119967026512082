/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { Row, Col, Alert } from "antd";
import LocationSelection from "../../shared/components/location-selection";
import ItemSelection from "../../shared/components/item-selection";
import RequestDetails from "../../shared/components/request-details";
import classes from "./equipment-delivery.module.scss";
import { useHistory } from "react-router-dom";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { fetchUnits, fetchReqSlas } from "../../shared/reducers/actions";
import {
  fetchCategoriesWithItems,
  placeDeliveryOrder,
  fetchSearchedItems,
  clearErrorMessage,
} from "./action";
import { setSuccessMessage } from "../home/action";
import {
  DELIVERY_REQUEST_TYPE_ID,
  PATIENTMODAL_SINGLE_NEW,
  PATIENTMODAL_SINGLE_REMOVE,
} from "../../../common/shared-constants";
import { login } from "../login/actions";
import PatientDetailsModal from "../../../common/components/PatientDetailsModal/patient-details-modal";
const EquipmentDelivery = ({
  campusData,
  units,
  fetchUnits,
  fetchCategoriesWithItems,
  categoriesItems,
  placeDeliveryOrder,
  slas,
  fetchReqSlas,
  setSuccessMessage,
  searchItems,
  fetchSearchedItems,
  errorMessage,
  clearErrorMessage,
  anonymous,
  login,
  account,
}: any) => {
  const history = useHistory();
  const [locationValues, setLocationValues] = useState<any>();
  const [view, setView] = useState("form");
  const [cartItems, setCartItems] = useState<any>([]);
  const [loginError, setLoginError] = useState<any>();
  const [showPatientModal, setShowPatientModal] = useState<any>(false);
  const fetchItemsByUnitId = () => {
    fetchCategoriesWithItems(locationValues);
  };

  useEffect(() => {
    campusData.id && fetchReqSlas(DELIVERY_REQUEST_TYPE_ID, campusData.id);
  }, [campusData]);

  const fetchUnitsByCampusId = (campusId: any) => {
    fetchUnits(campusId);
  };

  const onNext = (data: any) => {
    setLocationValues(data);
    setView("itemselection");
  };

  const backHome = () => {
    history.push("/");
  };

  const goBack = (viewVal: any) => {
    setView(viewVal);
  };

  const onItemAdd = (added: any) => {
    const found = cartItems.find(
      (cartItem: any) => cartItem.item.id === added.id
    );
    if (found === undefined) {
      if (added.specialtyItem)
        setShowPatientModal({
          id: added.id,
          mode: PATIENTMODAL_SINGLE_NEW,
          newItem: added,
        });
      else setCartItems([...cartItems, { item: added, quantity: 1 }]);
    }
    document.getElementById(added.id)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const onItemRemove = (removedId: any) => {
    let itemRemoving = [...cartItems];
    itemRemoving = itemRemoving.filter(
      (cartItem) => cartItem.item.id !== removedId
    );
    setCartItems(itemRemoving);
  };

  const removeItems = (removeIds: any) => {
    let itemRemoving = [...cartItems];
    itemRemoving = itemRemoving.filter(
      (cartItem) => !removeIds.includes(cartItem.item.id)
    );
    setCartItems(itemRemoving);
  };

  const quantityEdit = (itemId: any, action: any, newQuantity?: number) => {
    document.getElementById(itemId)?.scrollIntoView({
      behavior: "smooth",
    });
    const items = cartItems.map((cartItem: any) => {
      if (cartItem.item.id === itemId) {
        if (action === "INCREASE") {
          if (cartItem.item.specialtyItem) {
            setShowPatientModal({ id: itemId, mode: PATIENTMODAL_SINGLE_NEW });
          } else cartItem.quantity += 1;
        } else if (action === "EDIT") {
          cartItem.quantity = newQuantity;
        } else {
          if (cartItem.item.specialtyItem || cartItem.item.specialityItem) {
            cartItem?.patients?.length &&
              setShowPatientModal({
                id: itemId,
                mode: PATIENTMODAL_SINGLE_REMOVE,
              });
          } else cartItem.quantity -= 1;
        }
      }
      return cartItem;
    });
    setCartItems(items);
  };

  const onItemAllClear = (e: any) => {
    setCartItems(e);
  };

  const onCartCheckout = (cart: any) => {
    setView("requestdetail");
  };

  const onOrderSubmit = async (orderData: any) => {
    if (anonymous) {
      const loginResponse = await login(
        {
          name: orderData.name,
          phone: orderData.phoneNumber,
          email: orderData.email,
        },
        anonymous
      );
      if (loginResponse.status === "success") {
        setLoginError("");
        const res = await placeDeliveryOrder({
          ...orderData,
          externalUserId: loginResponse.user.id,
        });
        if (res.status === "success") {
          history.push("/request");
        }
      } else {
        setLoginError(loginResponse.errormessage);
      }
    } else {
      const res = await placeDeliveryOrder({
        ...orderData,
        externalUserId: account.id,
      });
      if (res.status === "success") {
        history.push("/request");
      }
    }
  };

  const fetchSearchList = async (keyword: any) => {
    const trimmedKeyword = keyword.trim();
    if (trimmedKeyword !== "") {
      await fetchSearchedItems(
        trimmedKeyword,
        locationValues.unitId,
        campusData.id
      );
    }
  };

  const viewSelection = (view: any) => {
    switch (view) {
      case "form":
        return (
          <LocationSelection
            onNext={(e: any) => {
              onNext(e);
            }}
            campusId={campusData.id}
            backHome={backHome}
            units={units}
            fetchUnits={fetchUnitsByCampusId}
            locationValues={locationValues}
            cartItems={cartItems}
            removeItems={removeItems}
          />
        );
      case "itemselection":
        return (
          <ItemSelection
            quantityEdit={quantityEdit}
            cartItems={cartItems}
            onItemAdd={onItemAdd}
            onItemRemove={onItemRemove}
            allCategories={categoriesItems}
            fetchCategoriesWithItems={fetchItemsByUnitId}
            goBack={goBack}
            clear={onItemAllClear}
            checkout={onCartCheckout}
            searchItems={searchItems}
            fetchSearchList={fetchSearchList}
            requestTypeId={DELIVERY_REQUEST_TYPE_ID}
          />
        );
      case "requestdetail":
        return (
          <RequestDetails
            quantityEdit={quantityEdit}
            goBack={goBack}
            locationValues={locationValues}
            campus={campusData}
            units={units}
            cartItems={cartItems}
            slas={slas}
            onItemAdd={onItemAdd}
            onItemRemove={onItemRemove}
            onSubmit={onOrderSubmit}
            requestTypeId={DELIVERY_REQUEST_TYPE_ID}
            errorMessage={errorMessage}
            setLoginError={setLoginError}
            clearErrorMessage={clearErrorMessage}
            account={account}
            showViewPatients={true}
          />
        );
      default:
        return (
          <LocationSelection
            onNext={(e: any) => {
              onNext(e);
            }}
            campusId={campusData.id}
            backHome={backHome}
            units={units}
            fetchUnits={fetchUnitsByCampusId}
            locationValues={locationValues}
            cartItems={cartItems}
            removeItems={removeItems}
          />
        );
    }
  };

  return (
    <div className={classes.equipmentContainer}>
      <Row>
        <Col span={24}>
          <p className={classes.titleText}>Equipment Delivery</p>
        </Col>
        {loginError && (
          <Col span={24}>
            <Alert message={loginError} closable type="error"></Alert>
          </Col>
        )}
      </Row>
      {viewSelection(view)}
      <PatientDetailsModal
        visible={!!showPatientModal}
        toggleModal={() => setShowPatientModal(false)}
        cart={cartItems} //just to remove items
        setCart={setCartItems} //just to remove items
        itemId={showPatientModal.id}
        mode={showPatientModal.mode}
        {...(showPatientModal.newItem
          ? {
              newItem: showPatientModal.newItem,
            }
          : {})}
      />
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  requestData,
  deliveryManagement,
  homeReducer,
}: IRootState) => ({
  units: requestData.units,
  errorMsg: requestData.errorMessage,
  errorMessage: deliveryManagement.errorMessage,
  slas: requestData.slas,
  categoriesItems: deliveryManagement.categoriesWithItems,
  categoryError: deliveryManagement.errorMessage,
  searchItems: deliveryManagement.searchItems,
  anonymous: authentication.anonymous,
  account: authentication.account,
  campusData: homeReducer.currentCampus,
});
const mapDispatchToProps = {
  fetchUnits,
  fetchCategoriesWithItems,
  fetchReqSlas,
  setSuccessMessage,
  placeDeliveryOrder,
  fetchSearchedItems,
  clearErrorMessage,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentDelivery);
