import {
  FETCH_SUMMARY_START,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAIL,
  FETCH_ASSETS_START,
  FETCH_ASSETS_SUCCESS,
  FETCH_ASSETS_FAIL,
  CLEAR_ALL_MESSAGES,
  SET_FILTER_DATA,
  UPDATE_ASSET_START,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAILED,
  CLEAR_AIM_DASHBOARD,
} from "./action-types";

const initialState = {
  assets: [] as any,
  assetsLoading: false,
  errorMessage: "" as string,
  totalCount: 0,
  summary: {} as any,
  summaryLoading: false,
  filterData: {} as any,
  assetUpdateError: "" as string,
};

export type AIMDashboardManagementState = Readonly<typeof initialState>;

const aimDashboardState = (
  state = initialState,
  action: any
): AIMDashboardManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUMMARY_START:
      return { ...state, summaryLoading: true };
    case FETCH_SUMMARY_SUCCESS:
      return { ...state, summaryLoading: false, summary: payload.data };
    case FETCH_SUMMARY_FAIL:
      return {
        ...state,
        summaryLoading: false,
        summary: {},
        errorMessage: payload.error,
      };
    case FETCH_ASSETS_START:
      return { ...state, assetsLoading: true };
    case FETCH_ASSETS_SUCCESS:
      return { ...state, assets: payload.data, assetsLoading: false };
    case FETCH_ASSETS_FAIL:
      return {
        ...state,
        assetsLoading: false,
        assets: {},
        errorMessage: payload.error,
      };

    case UPDATE_ASSET_START:
      return {
        ...state,
        assetsLoading: true,
      };
    case UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        assetsLoading: false,
      };

    case UPDATE_ASSET_FAILED:
      return {
        ...state,
        assetsLoading: false,
        assetUpdateError: payload.error,
      };
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: payload.data,
      };

    case CLEAR_ALL_MESSAGES:
      return {
        ...state,
        errorMessage: "",
      };
    case CLEAR_AIM_DASHBOARD:
      return initialState;
    default:
      return state;
  }
};

export default aimDashboardState;
