import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Input, Table, InputNumber } from "antd";
import classes from "./add-item-modal.module.scss";
import {
  DELIVERY_REQ,
  PATIENTMODAL_SINGLE_NEW,
  PATIENTMODAL_SINGLE_REMOVE,
  PATIENTMODAL_VIEW,
  PICKUP_REQ,
  REQ_EDIT,
  SHARP_REQ,
  SPECIALTY_HOVER_TITLE,
  SPECIALTY_INPUT_NUMBER_DISABLED_TITLE,
  VIEW_PATIENTS_TEXT,
} from "../../shared-constants";
import { allowOnlyNumbers } from "../../utils/trimUtil";
import PatientDetailsModal from "../PatientDetailsModal/patient-details-modal";
import DelayModal from "../DelayModal/DelayModal";

interface addItemProps {
  onItemsSelected: Function;
  visible: boolean;
  handleCancel: any;
  editCart: Array<any>;
  allItems: Array<any>;
  type:
    | typeof DELIVERY_REQ
    | typeof PICKUP_REQ
    | typeof SHARP_REQ
    | typeof REQ_EDIT;
  showDelayFlag?: boolean;
  showSpecialityFlag?: boolean;
  patientDetails?: boolean;
}

const AddItemModal = ({
  onItemsSelected = () => {},
  visible = false,
  handleCancel = () => {},
  editCart = [],
  allItems = [],
  type = REQ_EDIT,
  showDelayFlag = true,
  showSpecialityFlag = true,
  patientDetails,
}: addItemProps) => {
  const [deletedItems, setDeletedItems] = useState();
  useEffect(() => {
    setDeletedItems(
      editCart.reduce((acc: any, item: any) => {
        const { item: itemDetails } = item;
        if (!allItems.find((it: any) => it.id === itemDetails.id)) {
          return [...acc, item];
        }
        return acc;
      }, [])
    );
  }, [editCart, allItems]);
  const isDeliveryReq = type === DELIVERY_REQ;
  const isSharpReq = type === SHARP_REQ;
  const isPickupReq = type === PICKUP_REQ;
  const [tempModalCart, setTempModalCart] = useState<any>([
    ...editCart.reduce((acc: any, cart: any) => {
      return [...acc, ...(cart ? [{ ...cart }] : [])];
    }, []),
  ]);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [searchResults, setSearchResults] = useState<any>([...allItems]);
  const [selectedIDs, setSelectedIDs] = useState<any>([
    ...editCart.reduce((acc: any, cart: any) => {
      return [...acc, ...(cart?.item?.id ? [cart?.item?.id] : [])];
    }, []),
  ]);
  const [delayDetails, setDelayDetails] = useState<any>();
  const [showPatientModal, setShowPatientModal] = useState<any>(false);
  const delayModal = (record: any) => {
    setDelayDetails(record);
  };
  const getQuantity = (id: any) => {
    const item = tempModalCart.find(
      (cartItem: any) => cartItem?.item?.id === id
    );
    if (item) return item.quantity;
    return 0;
  };

  const rowSelection = {
    selectedRowKeys: tempModalCart.map((cart: any) => {
      return cart.item.id;
    }),
    type: "checkbox" as any,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowIds: any, selectedRows: any) => {
      const cart = selectedRows
        .filter((s: any) => !!s)
        .map((item: any) => {
          const found = tempModalCart.find(
            (cart: any) => cart?.item?.id === item?.id
          );
          if (found) {
            return found;
          } else {
            if (item.specialtyItem && isDeliveryReq) {
              !item.notify &&
                setShowPatientModal({
                  id: item.id,
                  mode: PATIENTMODAL_SINGLE_NEW,
                  newItem: item,
                });
            } else return { item, quantity: getQuantity(item.id) || 1 };
          }
          return found;
        });
      setTempModalCart([
        ...(deletedItems || []),
        ...cart.filter((s: any) => s),
      ]);
      setSelectedIDs(
        cart
          .filter((s: any) => s)
          .map((cart: any) => {
            return cart.item.id;
          })
      );
    },
    onSelect: (record: any, selected: any, selectedRows: any) => {
      if (selected && record.notify && !isPickupReq && showDelayFlag) {
        delayModal(record);
      }
    },
  };
  const quantityEdit = (
    itemId: any,
    action: "DESELECT" | "INCREASE" | "EDIT" | "DECREASE",
    newQuantity?: number
  ) => {
    if (action === "DESELECT") {
      setTempModalCart([
        ...tempModalCart.filter((item: any) => item.item.id !== itemId),
      ]);
      setSelectedIDs([...selectedIDs.filter((id: any) => id !== itemId)]);
      return null;
    }
    const items = tempModalCart.map((cartItem: any) => {
      const getPatientsCond =
        (cartItem.item.specialtyItem ||
          cartItem.item.specialityItem ||
          cartItem.isSpecialtyItem) &&
        isDeliveryReq;
      if (cartItem.item.id === itemId) {
        if (action === "INCREASE") {
          if (getPatientsCond) {
            setShowPatientModal({ id: itemId, mode: PATIENTMODAL_SINGLE_NEW });
          } else cartItem.quantity += 1;
        } else if (action === "EDIT") {
          cartItem.quantity = newQuantity;
        } else {
          if (getPatientsCond) {
            cartItem?.patients?.length &&
              setShowPatientModal({
                id: itemId,
                mode: PATIENTMODAL_SINGLE_REMOVE,
              });
          } else cartItem.quantity -= 1;
        }
      }
      return cartItem;
    });
    setTempModalCart(items);
  };

  const buttonHandle = (itemId: any) => {
    const Id = selectedIDs.find((id: any) => id === itemId);
    return Id ? false : true;
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image: any, record: any) => {
        const selected = tempModalCart.find(
          (item: any) => item?.item?.id === record?.id
        );
        return (
          <div className={classes.requestModalImage}>
            <img key={record.id} id={record.id} src={image} alt={record.name} />
            {!selected ? (
              <div
                className={`imageOverlay_50 full`}
                onClick={() => {
                  if (
                    !!(record.specialtyItem || record.specialityItem) &&
                    isDeliveryReq
                  ) {
                    if (!record.notify)
                      setShowPatientModal({
                        id: record.id,
                        mode: PATIENTMODAL_SINGLE_NEW,
                        newItem: record,
                      });
                    else delayModal(record);
                  } else {
                    setTempModalCart([
                      ...tempModalCart,
                      {
                        item: { ...record },
                        quantity: getQuantity(record.id) || 1,
                      },
                    ]);
                    setSelectedIDs([...selectedIDs, record.id]);
                    if (record.notify && !isPickupReq && showDelayFlag) {
                      delayModal(record);
                    }
                  }
                }}
              >
                Add
              </div>
            ) : (
              <>
                <div
                  className={`imageOverlay_50 decrement`}
                  onClick={() => {
                    if (selected.quantity <= 1) {
                      quantityEdit(selected?.item?.id, "DESELECT");
                    } else quantityEdit(selected?.item?.id, "DECREASE");
                  }}
                >
                  -
                </div>
                <div
                  className={`imageOverlay_50 increment`}
                  onClick={() => {
                    quantityEdit(selected?.item?.id, "INCREASE");
                  }}
                >
                  +
                </div>
              </>
            )}
            {!!record?.delay && !isPickupReq && showDelayFlag && (
              <div className={classes.delay} title="Delay">
                *
              </div>
            )}
            {!!(record?.specialtyItem || record?.specialityItem) &&
              showSpecialityFlag && (
                <div
                  className={classes.specialty}
                  title={SPECIALTY_HOVER_TITLE}
                >
                  *
                </div>
              )}
          </div>
        );
      },
      width: "25%",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (id: any, record: any) => {
        return (
          <div className={classes.sharpsData}>
            <div className={classes.name}>
              {isSharpReq && <span className={classes.label}>Name: </span>}
              <span className={classes.value}>{record.name}</span>
            </div>
            {isSharpReq && (
              <div className={classes.size}>
                <span className={classes.label}>Size: </span>
                <span className={classes.value}>{record.size.name}</span>
              </div>
            )}
          </div>
        );
      },
      width: "45%",
    },
    {
      title: "ItemCount",
      padding: "10px",
      dataIndex: "id",
      className: "quantity",
      render: (id: any, record: any) => {
        const quantity = getQuantity(id) || 0;
        const { patients } =
          tempModalCart.find(({ item }: any) => item.id === record.id) || {};

        return (
          <div className={classes.quantity}>
            <button
              className={classes.minus}
              disabled={buttonHandle(id) || quantity === 0}
              onClick={() => {
                if (quantity <= 1) quantityEdit(id, "DESELECT");
                else quantityEdit(id, "DECREASE");
              }}
            ></button>
            <InputNumber
              value={quantity}
              onChange={(quant) => {
                quantityEdit(id, "EDIT", quant);
              }}
              disabled={
                !!(
                  (record.specialtyItem || record.specialityItem) &&
                  showSpecialityFlag
                )
              }
              title={
                !!(
                  (record.specialtyItem || record.specialityItem) &&
                  showSpecialityFlag
                )
                  ? SPECIALTY_INPUT_NUMBER_DISABLED_TITLE
                  : ""
              }
              onKeyDown={allowOnlyNumbers}
              min={1}
              max={99}
            />
            <button
              className={classes.add}
              disabled={buttonHandle(id) || quantity >= 99 || quantity === 0}
              onClick={() => {
                quantityEdit(id, "INCREASE");
              }}
            ></button>
            {patients?.length ? (
              <span
                className="linkLikeText"
                onClick={() => {
                  setShowPatientModal(
                    record.specialtyItem
                      ? { id: record.id, mode: PATIENTMODAL_VIEW }
                      : false
                  );
                }}
              >
                {VIEW_PATIENTS_TEXT}
              </span>
            ) : (
              <></>
            )}
          </div>
        );
      },
      width: "30%",
    },
  ];

  const searchHandle = (event: any) => {
    setSearchTerm(event.target.value);
    const results = allItems.filter((item: any) =>
      item.name.toLowerCase().includes(event.target.value.trim().toLowerCase())
    );
    setSearchResults(results);
  };

  const selectedItems = () => {
    onItemsSelected(tempModalCart);
    setSearchTerm("");
  };

  return (
    <>
      <Modal
        title="Add Item"
        visible={visible}
        onCancel={handleCancel}
        centered={true}
        footer={false}
        maskClosable={false}
        className="sharpItemModal"
      >
        <Form.Item name="search" rules={[{ required: false }]}>
          <div className={classes.addListBtn}>
            <Button
              onClick={() => {
                selectedItems();
              }}
              type="primary"
              disabled={tempModalCart.length < 1 ? true : false}
            >
              Add Selected Item
            </Button>
          </div>
          <Input
            className={classes.searchInput}
            onChange={searchHandle}
            placeholder="Search"
            value={searchTerm}
          />
        </Form.Item>
        <Table
          rowSelection={{
            ...rowSelection,
          }}
          showHeader={false}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={searchResults}
          className="sharpItemList"
          rowClassName="fadeIn"
          pagination={false}
          scroll={{
            y: "50vh",
          }}
        />
      </Modal>
      <DelayModal
        handleCancel={() => {
          setTempModalCart(
            tempModalCart.filter(
              (cart: any) => cart.item.id !== delayDetails.id
            )
          );
          setSelectedIDs(
            selectedIDs.filter((row: any) => row.id !== delayDetails.id)
          );
          setDelayDetails(null);
        }}
        handleOk={() => {
          setShowPatientModal(
            delayDetails.specialtyItem
              ? {
                  id: delayDetails.id,
                  mode: PATIENTMODAL_SINGLE_NEW,
                  newItem: delayDetails,
                }
              : false
          );
          setDelayDetails(null);
        }}
        isModalVisible={!!delayDetails}
        text={delayDetails?.delayDescription}
      />
      {patientDetails && showSpecialityFlag && (
        <PatientDetailsModal
          visible={!!showPatientModal}
          toggleModal={() => setShowPatientModal(false)}
          cart={tempModalCart}
          setCart={setTempModalCart}
          selectedIds={selectedIDs}
          setSelectedIDs={setSelectedIDs}
          itemId={showPatientModal.id}
          mode={showPatientModal.mode}
          {...(showPatientModal.newItem
            ? {
                newItem: showPatientModal.newItem,
              }
            : {})}
        />
      )}
    </>
  );
};

export default AddItemModal;
