import { adminRootInstance } from "../../../../../common/config/axios-config";
import {
  CLEAR_NOTES,
  FETCH_NOTES_FAIL,
  FETCH_NOTES_START,
  FETCH_NOTES_SUCCESS,
} from "../action-types";

export const fetchNotesDetails =
  ({ reqId }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: FETCH_NOTES_START,
    });
    await adminRootInstance
      .get("notes/" + reqId)
      .then((res: any) => {
        dispatch({
          type: FETCH_NOTES_SUCCESS,
          payload: {
            request: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_NOTES_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const clearNotes = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_NOTES,
  });
};
