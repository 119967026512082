import React from "react";
import { Form, Button, Select, Radio, DatePicker, Alert } from "antd";
import classes from "../common-reports.module.scss";
import { disabledDate } from "./utils";

const ReportSpecificFields: any = {
  specialityItem: {
    label: "Speciality Items",
    rules: [{ required: true, message: "Please select speciality Items!" }],
    placeholder: "Select Items",
  },
  sla: {
    label: "SLA",
    rules: [{ required: true, message: "Please select SLA!" }],
    placeholder: "Select SLAs",
  },
  status: {
    label: "Status",
    rules: [{ required: true, message: "Please select Status!" }],
    placeholder: "Select Statuses",
  },
  items: {
    label: "Items",
    rules: [{ required: true, message: "Please select Item!" }],
    placeholder: "Select Items",
  },
  users: {
    label: "Users",
    rules: [{ required: true, message: "Please select Users!" }],
    nameKey: "username",
    placeholder: "Select Users",
  },
};
export const FormViewComp = (props: any) => {
  const { form, onFinish, initialValues, errorMessage, dataLoading, config } =
    props;
  return (
    <div className={classes.reportsTypeContainer}>
      <div className={classes.reportsTypeForm}>
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          initialValues={initialValues}
          onFinish={onFinish}
          labelAlign="left"
        >
          {config.map((items: any, index: any) =>
            getComponent(items.name, config[index])
          )}

          {errorMessage && (
            <div>
              <Alert message={errorMessage} type="error" closable />
            </div>
          )}
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "50px 0 0",
            }}
          >
            <Button
              type="primary"
              className="nextBtn"
              htmlType="submit"
              loading={dataLoading}
            >
              Generate Report
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const getComponent = (type: string, props: any) => {
  const dateFormat = "MM-DD-YYYY";
  switch (type) {
    case "reportType":
      return (
        <Form.Item
          key={type}
          label="Type"
          name="reportType"
          initialValue="summary"
          rules={[{ required: true, message: "Please select report type!" }]}
        >
          <Radio.Group
            onChange={props.onChange}
            style={{ display: "flex" }}
            name="radiogroup"
            defaultValue={"summary"}
          >
            <Radio value="summary" checked>
              Summary
            </Radio>
            <Radio value="detailed">Detailed</Radio>
          </Radio.Group>
        </Form.Item>
      );
    case "fromDate":
      return (
        <Form.Item
          key={type}
          label="From"
          name="fromDate"
          rules={[
            {
              required: true,
              message: "Please select Start Date!",
            },
          ]}
        >
          <DatePicker
            getPopupContainer={(trigger): any => trigger.parentNode}
            allowClear={true}
            style={{ width: "100%" }}
            value={props.fromDate}
            format={dateFormat}
            placeholder="Select Date (e.g. 12-27-2020)"
            disabledDate={(current) =>
              disabledDate(current, "From", props.selectedValues)
            }
            onChange={props.onChange}
          />
        </Form.Item>
      );
    case "toDate":
      return (
        <Form.Item
          key={type}
          label="To"
          name="toDate"
          rules={[
            {
              required: true,
              message: "Please select End Date!",
            },
          ]}
        >
          <DatePicker
            getPopupContainer={(trigger): any => trigger.parentNode}
            allowClear={true}
            style={{ width: "100%" }}
            value={props.toDate}
            format={dateFormat}
            placeholder="Select Date (e.g. 12-30-2020)"
            disabledDate={(current) =>
              disabledDate(current, "To", props.selectedValues)
            }
            onChange={props.onChange}
          />
        </Form.Item>
      );
    case "sla":
    case "status":
    case "items":
    case "specialityItem":
    case "users":
      const multiSelect = props.radioValue === "summary" || props.multiSelect;
      return (
        <Form.Item
          key={type}
          label={ReportSpecificFields[type].label}
          name={type}
          rules={ReportSpecificFields[type].rules}
        >
          <Select
            showSearch
            showArrow
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={ReportSpecificFields[type].placeholder}
            optionFilterProp="children"
            mode={multiSelect ? "multiple" : undefined}
            onChange={props.onChange}
          >
            {!props.excludeAllOption &&
              multiSelect &&
              props.dropDownList.length && (
                <Select.Option key="all" value="all">
                  All
                </Select.Option>
              )}
            {props.dropDownList.map((status: any) => (
              <Select.Option key={status.id} value={status.id}>
                {status[ReportSpecificFields[type]?.nameKey || "name"]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "orderType":
      return (
        <Form.Item
          key={type}
          label="Order Type"
          name="orderType"
          rules={[{ required: true, message: "Please select Order Type!" }]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            placeholder="Select Order Types"
            optionFilterProp="children"
            onChange={props.onChange}
            mode="multiple"
            showArrow
          >
            {props.dropDownList.length && (
              <Select.Option key="all" value="all">
                All
              </Select.Option>
            )}
            {props.dropDownList.map((orderType: any) => (
              <Select.Option key={orderType.id} value={orderType.id}>
                {orderType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "units":
      return (
        <Form.Item
          key={type}
          label="Units"
          name="units"
          rules={[{ required: true, message: "Please select Units!" }]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            placeholder="Select Units"
            optionFilterProp="children"
            mode="multiple"
            onChange={props.onChange}
            showArrow
          >
            {props.dropDownList?.length && (
              <Select.Option key="all" value="all">
                All
              </Select.Option>
            )}
            {props.dropDownList
              ?.filter((unit: any) => unit.id !== 1)
              .map((unit: any) => (
                <Select.Option key={unit.id} value={unit.id}>
                  {unit.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      );
    default:
      <div />;
  }
};
