import React from "react";
import { Menu, Drawer, Row } from "antd";
import { Link, NavLink } from "react-router-dom";
import classes from "./admin-layout.module.scss";
import arrow from "../../../images/next-arrow.png";
import dashboardIcon from "../../../images/dashboard.png";
import userIcon from "../../../images/user.png";
import SLAIcon from "../../../images/SLA.png";
import campusIcon from "../../../images/campus.png";
import unitIcon from "../../../images/unit.png";
import itemIcon from "../../../images/item.png";
import reportsIcon from "../../../images/report.png";
import importIcon from "../../../images/import.png";
import dashboardActiveIcon from "../../../images/dashboard-active-icon.png";
import userActiveIcon from "../../../images/user-active-icon.png";
import SLAActiveIcon from "../../../images/SLA-active-icon.png";
import campusActiveIcon from "../../../images/campus-active-icon.png";
import unitActiveIcon from "../../../images/unit-active-icon.png";
import itemActiveIcon from "../../../images/item-active-icon.png";
import reportsActiveIcon from "../../../images/report-active-icon.png";
import importActiveIcon from "../../../images/import-active-icon.png";

// import AIMDashboardIcon from "../../../images/AIM/Asset Management.png";
import deconActiveIcon from "../../../images/AIM/cleaning-cart_W.png";
import deconInActiveIcon from "../../../images/AIM/cleaning-cart_G.png";
import assetCheckActiveIcon from "../../../images/AIM/clipboard_W.png";
import assetCheckInActiveIcon from "../../../images/AIM/clipboard_G.png";
import AIMDashboardActiveIcon from "../../../images/AIM/dashboard_W.png";
import AIMDashboardInActiveIcon from "../../../images/AIM/dashboard_G.png";

import logo from "../../../images/Logo.png";
import {
  ADMIN_DASHBOARD,
  AIM,
  DATA_IMPORTS,
  POST_LOGIN_SCREEN,
} from "../../../common/shared-constants";
import { Ability } from "@casl/ability";

interface SideBarInterface {
  onClose: (x: any) => void;
  visible: boolean;
  active: string;
  ability: Ability;
  variant?: typeof AIM | typeof ADMIN_DASHBOARD | typeof POST_LOGIN_SCREEN;
}
const SideBar = ({
  onClose,
  visible,
  active,
  ability,
  variant = ADMIN_DASHBOARD,
}: SideBarInterface) => {
  const canViewAdminPortal = ability.can("view", ADMIN_DASHBOARD);
  const linkConfigAdminPortal = [
    {
      itemClass:
        active.split("/").includes("dashboard") &&
        !active.split("/").includes("aim")
          ? "ant-menu-item-selected"
          : "",
      to: "/admin/dashboard", //also itemClass
      src:
        active.split("/").includes("dashboard") &&
        !active.split("/").includes("aim")
          ? dashboardActiveIcon
          : dashboardIcon,
      linkChild: "Dashboard",
      show: true,
    },
    {
      itemClass: "",
      to: "/admin/users",
      src: active === "/admin/users" ? userActiveIcon : userIcon,
      linkChild: "Users",
      show: true,
    },
    {
      itemClass: "",
      to: "/admin/campuses",
      src: active === "/admin/campuses" ? campusActiveIcon : campusIcon,
      linkChild: "Campuses",
      show: true,
    },
    {
      itemClass: "",
      to: "/admin/slas",
      src: active === "/admin/slas" ? SLAActiveIcon : SLAIcon,
      linkChild: "SLAs",
      show: true,
    },
    {
      itemClass: "",
      to: "/admin/units",
      src: active === "/admin/units" ? unitActiveIcon : unitIcon,
      linkChild: "Units",
      show: true,
    },

    {
      itemClass: "",
      to: "/admin/items",
      src: active === "/admin/items" ? itemActiveIcon : itemIcon,
      linkChild: "Items",
      show: true,
    },
    {
      itemClass: active.split("/").includes("reports")
        ? "ant-menu-item-selected"
        : "",
      to: "/admin/reports",
      src: active.split("/").includes("reports")
        ? reportsActiveIcon
        : reportsIcon,
      linkChild: "Reports",
      show: true,
    },
    {
      itemClass: "",
      to: "/admin/data-import",
      src: active === "/admin/data-import" ? importActiveIcon : importIcon,
      linkChild: "Data Import",
      show: ability.can("view", DATA_IMPORTS),
    },
    {
      itemClass: "",
      to: "/admin/aim/dashboard",
      src: active.includes("aim/dashboard")
        ? AIMDashboardActiveIcon
        : AIMDashboardInActiveIcon,
      linkChild: "AIM Dashboard",
      show: true,
    },
  ];
  const linkConfigAIM = [
    {
      itemClass: "",
      to: "/admin/aim/dashboard",
      src: active.includes("aim/dashboard")
        ? AIMDashboardActiveIcon
        : AIMDashboardInActiveIcon,
      linkChild: "AIM Dashboard",
      show: true,
    },
    {
      itemClass: "",
      to: "/admin/aim/checkin",
      src: active.includes("aim/checkin")
        ? assetCheckActiveIcon
        : assetCheckInActiveIcon,
      linkChild: "Asset Check-In",
      show: true,
    },
    {
      itemClass: "",
      to: "/admin/aim/decon",
      src: active.includes("aim/decon") ? deconActiveIcon : deconInActiveIcon,
      linkChild: "Asset Decon",
      show: true,
    },
    {
      itemClass: active.includes("aim/reports") ? "ant-menu-item-selected" : "",
      to: "/admin/aim/reports",
      src: active.includes("aim/reports") ? reportsActiveIcon : reportsIcon,
      linkChild: "Report",
      show: true,
    },
    {
      itemClass: "",
      to: "/admin/dashboard",
      src: active.includes("/admin/dashboard")
        ? dashboardActiveIcon
        : dashboardIcon,
      linkChild: "Dashboard",
      show: canViewAdminPortal,
    },
  ];
  const linkConfig =
    variant === ADMIN_DASHBOARD && canViewAdminPortal
      ? linkConfigAdminPortal
      : linkConfigAIM;

  const getHeader = () => {
    return (
      <Row justify={"center"}>
        <Link to="/admin" onClick={onClose}>
          <img alt="Logo" src={logo} className={classes.logoWrapper} />
        </Link>
      </Row>
    );
  };
  return (
    <Drawer
      drawerStyle={{ backgroundColor: "transparent" }}
      contentWrapperStyle={{ boxShadow: "none" }}
      headerStyle={{
        width: "80%",
        height: "75px",
        backgroundColor: "#d1cab6",
        borderBottom: "1px solid #555555",
      }}
      bodyStyle={{ padding: "0%" }}
      title={getHeader()}
      placement={"left"}
      mask={true}
      maskClosable={true}
      onClose={onClose}
      visible={visible}
      closable={false}
      className="adminSideBar"
    >
      <Menu
        className={classes.menuContainer}
        theme="light"
        mode="inline"
        defaultSelectedKeys={["/admin/dashboard"]}
        selectedKeys={[active]}
      >
        {linkConfig.map(
          (obj: any) =>
            obj.show && (
              <Menu.Item
                className={obj.itemClass}
                key={obj.to}
                onClick={onClose}
              >
                <NavLink
                  activeClassName={classes.activeLinkMenu}
                  to={obj.to}
                  className="menuitemtest"
                >
                  <img alt="" src={obj.src} className={classes.iconDimension} />
                  <span className="iconSpacing">{obj.linkChild}</span>
                </NavLink>
              </Menu.Item>
            )
        )}
      </Menu>
      <div
        className={`closeContainer ${classes.closeContainer}`}
        onClick={onClose}
      >
        <img alt="" src={arrow} className={classes.closeArrow} />
      </div>
    </Drawer>
  );
};

export default SideBar;
