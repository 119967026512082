/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import {
  Modal,
  Typography,
  Form,
  Spin,
  Button,
  Row,
  Col,
  Switch,
  Image,
  Input,
  Alert,
} from "antd";
import classes from "./item-view-modal.module.scss";
import { SHARPS_CATALOGUE_ID } from "../../../../common/shared-constants";
const { Text } = Typography;

const errorMessageFormatter = (text: string, className: any) => {
  return <span className={className}>{text}</span>;
};

const ItemViewModal = ({
  handleClose,
  item,
  categories,
  allUnits,
  catalogues,
  itemSize,
  visible,
  handleSubmit,
  loading,
  errorMsg,
  clearMessages,
  allCampuses,
}: any) => {
  const isSharpsItem = item.catalogueId === SHARPS_CATALOGUE_ID;
  const [showDescription, setShowDescription] = useState<any>(item.notify);
  const [form] = Form.useForm();
  useEffect(() => {
    clearMessages();
  }, []);
  const handleFinish = async (values: any) => {
    const modifiedValues = { ...values, name: item.name };
    await handleSubmit(modifiedValues);
  };

  const handleNotificationClick = (checked: boolean) => {
    setShowDescription(checked);
  };

  return (
    <Modal
      title={
        <div className="commonModalHeader">
          <div className="title">
            <p>View Item</p>
          </div>
          <div className="close">
            <Button className="closeBtn" onClick={handleClose}>
              <CloseOutlined className="close-icon" />
            </Button>
          </div>
        </div>
      }
      visible={visible}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
      closable={false}
      width={500}
    >
      {errorMsg ? (
        <Alert
          message={errorMsg}
          type="error"
          closable
          afterClose={clearMessages}
          className={`fadeIn ${classes.errorContainer}`}
        />
      ) : null}
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}>Name</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>{item.name} </Text>
        </Col>
      </Row>
      <Row>
        <Col span={11} className={classes.alignLableMiddle}>
          <Text className={classes.doubleDot}>Item Image</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            <Image src={item.image} className={classes.itemImage} />
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}> Item Size</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {
              itemSize.find((itemSize: any) => itemSize.id === item.sizeId)
                ?.name
            }
          </Text>
        </Col>
      </Row>
      {!isSharpsItem && (
        <Row>
          <Col span={11}>
            <Text className={classes.doubleDot}>
              Categor{item.categoryIds.length > 1 ? "ies" : "y"}
            </Text>
          </Col>
          <Col span={13}>
            <Text className={classes.popValue}>
              {item.categoryIds
                ?.map((categoryId: any) =>
                  categories.find((category: any) => category.id === categoryId)
                )
                .sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
                .reduce(
                  (accumulator: any, currentValue: any, index: any) =>
                    index === 0
                      ? `${currentValue.name}`
                      : `${accumulator}, ${currentValue.name}`,
                  ""
                )}
            </Text>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}>
            Mapped Campus{item.campusIds.length > 1 ? "es" : ""}
          </Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {item?.campusIds.length
              ? item?.campusIds
                  ?.map((campusId: any) =>
                    allCampuses.find((campus: any) => campus?.id === campusId)
                  )
                  .reduce(
                    (accumulator: any, currentValue: any, index: any) =>
                      index === 0
                        ? `${currentValue?.name ? currentValue?.name : ""}`
                        : `${accumulator} ${currentValue?.name ? "," : ""} ${
                            currentValue?.name ? currentValue?.name : ""
                          }`,
                    ""
                  )
              : "-"}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}>
            Mapped Unit{item.unitIds.length > 1 ? "s" : ""}
          </Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {item.unitIds
              ?.map((unitId: any) =>
                allUnits.find((unit: any) => unit.id === unitId)
              )
              .reduce(
                (accumulator: any, currentValue: any, index: any) =>
                  index === 0
                    ? `${currentValue.name}`
                    : `${accumulator}, ${currentValue.name}`,
                ""
              )}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}> Catalogue</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {
              catalogues.find(
                (catalogue: any) => catalogue.id === item.catalogueId
              )?.name
            }
          </Text>
        </Col>
      </Row>
      {!isSharpsItem && (
        <>
          <Row>
            <Col span={11}>
              <Text className={classes.doubleDot}> Speciality Item</Text>
            </Col>
            <Col span={13}>
              <Text className={classes.popValue}>
                {item.specialtyItem ? "Yes" : "No"}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Text className={classes.doubleDot}> Rental</Text>
            </Col>
            <Col span={13}>
              <Text className={classes.popValue}>
                {item.rental ? "Yes" : "No"}
              </Text>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col span={11}>
          <Text className={classes.doubleDot}> Added to Asset Inventory</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {item.assetTracking ? "Yes" : "No"}
          </Text>
        </Col>
      </Row>
      <Form
        form={form}
        layout="horizontal"
        initialValues={item}
        onFinish={handleFinish}
      >
        <Form.Item hidden name="id" />
        <Row>
          <Col span={24}>
            <Form.Item
              name="delay"
              label="Delay"
              wrapperCol={{ span: 13 }}
              labelCol={{ span: 11 }}
              labelAlign="left"
              className={classes.labelAlign}
              valuePropName="checked"
            >
              <Switch className={`${classes.valueAlign} delaySwitch`} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="notify"
              label="Notification"
              wrapperCol={{ span: 13 }}
              labelCol={{ span: 11 }}
              labelAlign="left"
              className={classes.labelAlign}
              valuePropName="checked"
            >
              <Switch
                className={classes.valueAlign}
                onClick={handleNotificationClick}
              />
            </Form.Item>
          </Col>
        </Row>
        {showDescription && (
          <Row className="fadeIn">
            <Col span={24}>
              <Form.Item
                hidden={!showDescription}
                name="delayDescription"
                label="Description"
                wrapperCol={{ span: 13 }}
                labelCol={{ span: 11 }}
                labelAlign="left"
                className={`fadeIn ${classes.labelAlign}`}
                rules={[
                  {
                    validator(rule, val) {
                      let value = val.trim();
                      if (!form.getFieldValue("notify")) {
                        return Promise.resolve();
                      }
                      if (value && value.length) {
                        if (value.length < 1) {
                          return Promise.reject(
                            errorMessageFormatter(
                              "Description should be minimum of 1 character.",
                              classes["margin-left-10"]
                            )
                          );
                        } else if (value.length > 200) {
                          return Promise.reject(
                            errorMessageFormatter(
                              "Description should be maximum of 200 characters.",
                              classes["margin-left-10"]
                            )
                          );
                        } else if (value.match(/^.*\s{2,}.*$/)) {
                          return Promise.reject(
                            errorMessageFormatter(
                              "Maximum one space is allowed.",
                              classes["margin-left-10"]
                            )
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject(
                          errorMessageFormatter(
                            "Please enter the Description!",
                            classes["margin-left-10"]
                          )
                        );
                      }
                    },
                    required: true,
                  },
                ]}
              >
                <Input.TextArea
                  className={classes.valueAlign}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row className={classes.btnContainer}>
          <Button
            type="primary"
            className="backBtn"
            htmlType="reset"
            onClick={handleClose}
          >
            Close
          </Button>
          <Spin spinning={loading}>
            <Button type="primary" className="nextBtn" htmlType="submit">
              Submit
            </Button>
          </Spin>
        </Row>
      </Form>
    </Modal>
  );
};

export default ItemViewModal;
