import { mergeDeep } from "../../../utils";
import { ORDER_STATUS_MAP } from "../../../../../../common/shared-constants";
import { productivitySummaryColumns } from "../../productivity/productivity-summary-report";
import { slaDetailedColumnList } from "../../sla/sla-detailed-report";
import { statusDetailedReportColumns } from "../../status/status-detailed-report";
import { itemDetailedReportColumns } from "../../items/items-detailed-report";
import { productivityDetailedReportColumns } from "../../productivity/productivity-detailed-report";
import { splItmReportColumns } from "../../speciality-items/speciality-items-table";
import { overallReportColumns } from "../../overall/overall-table";
import { columnConfig } from "./reports-table";

export const csvDataFormatterConfig: any = {
  items: {
    title: "Items",
    dataIndex: "items",
    render: (record: any) => ({
      items: record
        ?.map((item: any) => {
          return (
            (item.splItem ? "(s)" : "") + item.name + " - " + item.quantity
          );
        })
        .join(";\n"),
    }),
  },
  itemDetails: {
    title: "Item Details",
    dataIndex: "items",
    render: (currentField: any) => {
      let dataPresent = !!currentField;
      const modifiedData = currentField.reduce((acc: any, item: any) => {
        const { splItemDetails, splItem } = item;
        if (splItemDetails.length) {
          dataPresent = true;
        }
        const finalResult = splItem
          ? acc.concat(
              splItemDetails?.map((details: any) => {
                const {
                  id1Type,
                  id1Value,
                  id2Type,
                  id2Value,
                  patientFirstName,
                  patientLastName,
                } = details;
                let patientName =
                  !!patientLastName && !!patientFirstName
                    ? `${patientFirstName} ${patientLastName}`
                    : "";
                const itemDetails =
                  id1Type && id2Type
                    ? `; ${id1Type} - ${id1Value}; ${id2Type} - ${id2Value}`
                    : "";
                return `Patient name -${patientName}${itemDetails}`;
              })
            )
          : acc;
        return finalResult;
      }, []);
      let data = `${modifiedData.join(";\n")}${modifiedData.length ? ";" : ""}`;
      return {
        itemDetails: dataPresent ? data : "-",
      };
    },
  },
  duration: {
    title: "Duration",
    dataIndex: "duration",
    labels: [
      { label: "Queue Duration", key: "queueDuration" },
      { label: "Accept Duration", key: "acceptDuration" },
      { label: "Start Duration", key: "startDuration" },
      { label: "Deliver Duration", key: "deliverDuration" },
      { label: "Delay Duration", key: "delayDuration" },
      { label: "Round Trip Duration", key: "roundTripDuration" },
    ],
    render: (record: any) => ({
      queueDuration: record?.queue || "-",
      acceptDuration: record?.accept || "-",
      startDuration: record?.start || "-",
      deliverDuration: record?.deliver || "-",
      delayDuration: record?.delay || "-",
      roundTripDuration: record?.total || "-",
    }),
  },
};

export const deepDiveCSVDataGenerator = (
  rawData: any,
  itemDetails: boolean,
  headerList?: any
) => {
  let columnsConfig = columnConfig({
    noItemDetails: headerList.includes("itemDetails"),
    forCSV: true,
  });
  let labels = (headerList || Object.keys(rawData[0])).reduce(
    (acc: any, rowKeys: any) => {
      if (rowKeys === "duration") {
        return [...acc, ...csvDataFormatterConfig.duration.labels];
      }
      if (rowKeys === "items") {
        return [
          ...acc,
          {
            label: csvDataFormatterConfig.items.title,
            key: rowKeys,
          },
          ...(itemDetails
            ? [
                {
                  label: csvDataFormatterConfig.itemDetails.title,
                  key: "itemDetails",
                },
              ]
            : []),
        ];
      }
      if (rowKeys === "itemDetails") {
        return [
          ...acc,
          {
            label: csvDataFormatterConfig.itemDetails.title,
            key: "itemDetails",
          },
        ];
      }
      if (rowKeys === "dateTime") {
        return [
          ...acc,
          {
            label: columnsConfig.createdDate.title,
            key: columnsConfig.createdDate.dataIndex,
          },
          {
            label: columnsConfig.createdTime.title,
            key: columnsConfig.createdTime.dataIndex,
          },
        ];
      }
      return [
        ...acc,
        {
          label: columnsConfig[rowKeys].title,
          key: rowKeys,
        },
      ];
    },
    []
  );
  // Body Data
  let csvData = rawData.reduce((accumu: any, data: any) => {
    let rowData = data;
    if (itemDetails || headerList.includes("itemDetails"))
      rowData = { ...data, itemDetails: {} };
    let result = Object.keys(rowData).reduce((acc: any, field: any) => {
      const fieldObj = rowData[field];
      switch (field) {
        case "duration":
          return {
            ...acc,
            ...csvDataFormatterConfig.duration.render(fieldObj),
          };
        case "items":
          return {
            ...acc,
            ...csvDataFormatterConfig.items.render(fieldObj),
          };

        case "itemDetails":
          return {
            ...acc,
            ...csvDataFormatterConfig.itemDetails.render(
              rowData.items || fieldObj
            ),
          };
        default:
          return {
            ...acc,
            [field]:
              columnsConfig[field] &&
              columnsConfig[field].render(fieldObj, rowData),
          };
      }
    }, {});
    return accumu.concat([result]);
  }, []);
  return { data: csvData, labels };
};
export const getCSVFileConfig = (
  rawData: any,
  reportName:
    | "sla"
    | "status"
    | "item"
    | "productivity"
    | "speciality"
    | "overall",
  reportType: "summary" | "detailed"
) => {
  let csvData: any = [];
  let labels: any = [];
  if (!rawData?.length || !reportType) return null;
  switch (reportName) {
    case "sla":
      if (reportType === "summary") {
        let labelData: any = [];
        csvData = rawData.map((row: any) => {
          if (!row.slaData) return null;
          labelData.push(row.slaData);
          return Object.keys(row.slaData).reduce(
            (acc, curr) => {
              let rowData = row.slaData[curr];
              return {
                ...acc,
                ...Object.keys(rowData.data).reduce(
                  (ac, cu) => ({ ...ac, [`${curr}--${cu}`]: rowData.data[cu] }),
                  {}
                ),
              };
            },
            { date: row.date, totalOrders: row.total }
          );
        });
        let deep: any = mergeDeep(labelData);
        const finalObj = [
          {
            label: "Date",
            key: "date",
          },
          {
            label: "Total Orders",
            key: "totalOrders",
          },
        ];
        labels = finalObj.concat(
          Object.keys(deep).reduce((accumu: any, slaId: any) => {
            const { name: slaName, data: slaData } = deep[slaId];
            return [
              ...accumu,
              ...Object.keys(slaData).reduce(
                (a: any, c: any) =>
                  a.concat({
                    label: `${slaName} - ${c}`,
                    key: `${slaId}--${c}`,
                  }),
                []
              ),
            ];
          }, [])
        );
      }
      if (reportType === "detailed") {
        const { data, labels: generatedLabels } = deepDiveCSVDataGenerator(
          rawData,
          false,
          slaDetailedColumnList
        );
        csvData = data;
        labels = generatedLabels;
      }
      break;
    case "status":
      if (reportType === "summary") {
        let filteredColumns = rawData.reduce((acc: any, curr: any) => {
          return { ...acc, ...curr.statusData };
        }, {});
        let cols = [
          { label: "Date", key: "date" },
          { label: "Total Order", key: "totalOrders" },
          ...Object.keys(filteredColumns).reduce((acc: any, curr: any) => {
            const orderStatusData: any = ORDER_STATUS_MAP.find(
              (record: any) => record.id === parseInt(curr)
            );
            return [
              ...acc,
              {
                label: orderStatusData?.name,
                key: `${orderStatusData?.id}`,
              },
            ];
          }, []),
        ];
        let data = rawData.map((rowData: any) => {
          return {
            date: rowData.date,
            totalOrders: rowData.total,
            ...Object.keys(rowData.statusData).reduce((acc: any, curr: any) => {
              return { ...acc, [`${curr}`]: rowData.statusData[curr] };
            }, {}),
          };
        });
        csvData = data;
        labels = cols;
      }
      if (reportType === "detailed") {
        const { data, labels: generatedLabels } = deepDiveCSVDataGenerator(
          rawData,
          false,
          statusDetailedReportColumns
        );
        csvData = data;
        labels = generatedLabels;
      }
      break;
    case "item":
      if (reportType === "summary") {
        const { data, labels: generatedLabels } = getItemSummaryCSV(rawData);
        csvData = data;
        labels = generatedLabels;
      }
      if (reportType === "detailed") {
        const { data, labels: generatedLabels } = deepDiveCSVDataGenerator(
          rawData,
          false,
          itemDetailedReportColumns
        );
        csvData = data;
        labels = generatedLabels;
      }
      break;
    case "productivity":
      if (reportType === "summary") {
        labels = productivitySummaryColumns.map((column: any) => {
          return { label: column.title, key: column.dataIndex };
        });
        csvData = rawData;
      }
      if (reportType === "detailed") {
        const { data, labels: generatedLabels } = deepDiveCSVDataGenerator(
          rawData,
          false,
          productivityDetailedReportColumns
        );
        csvData = data;
        labels = generatedLabels;
      }
      break;
    default:
      const { data, labels: generatedLabels } = deepDiveCSVDataGenerator(
        rawData,
        false,
        reportName === "overall"
          ? overallReportColumns
          : reportName === "speciality"
          ? splItmReportColumns
          : null
      );
      csvData = data;
      labels = generatedLabels;
      break;
  }
  return {
    csvData,
    labelData: labels,
  };
};

//

function getItemSummaryCSV(rawData: any) {
  //row data
  let dataBody = rawData.map((rowData: any) => {
    let finalObj = { date: rowData.date, totalOrders: rowData.total };
    let restObj = Object.keys(rowData.itemData).reduce((acc, itemId) => {
      let itemObj = rowData.itemData[itemId];
      return {
        ...acc,
        ...itemObj.data.reduce((a: any, c: any) => {
          return { ...a, [`${itemId}--${c.id}`]: c.count };
        }, {}),
      };
    }, {});
    return { ...finalObj, ...restObj };
  });
  //column data
  let tempData = rawData.reduce((acc: any, dat: any) => {
    const { itemData } = dat;
    const itemIds: any = Object.keys(itemData);
    return {
      ...acc,
      ...itemIds.reduce((a: any, c: any) => {
        const itemObj = itemData[c];
        return {
          ...a,
          ...itemObj.data.reduce((accumu: any, curr: any) => {
            return {
              ...accumu,
              [`${c}--${curr.id}`]: {
                label: `${itemObj.name}-${curr.unit}`,
                primarySort: parseInt(c),
                secondarySort: parseInt(curr.id),
              },
            };
          }, {}),
        };
      }, {}),
    };
  }, {});
  let convertedObj = Object.keys(tempData).map((curr) => {
    return { key: curr, ...tempData[curr] };
  });
  let sortedLabels = convertedObj.sort((a, b) => {
    if (a.primarySort === b.primarySort) {
      return a.secondarySort > b.secondarySort ? 1 : -1;
    }
    if (a.primarySort > b.primarySort) return 1;
    else return -1;
  });
  let labelData = [
    { label: "Date", key: "date" },
    { label: "Total Orders", key: "totalOrders" },
    ...sortedLabels.map((label: any) => ({
      key: label.key,
      label: label.label,
    })),
  ];
  return { data: dataBody, labels: labelData };
}
