import React from 'react';
import { Layout } from 'antd';
import classes from './footer.module.scss';
import footerIcon from '../../../images/footer-icon.png';
const { Footer } = Layout;


const footer = (props: any) => {
    return (
        <Footer className={classes.footerContainer}>
            <img src={footerIcon} className={classes.footerIcon} alt="footer" />
        </Footer>
    );
};

export default footer;