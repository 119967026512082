import {
  CLEAR_ALL_MESSAGES,
  CLEAR_DECON_STATE,
  FETCH_DECON_DATA_FAIL,
  FETCH_DECON_DATA_START,
  FETCH_DECON_DATA_SUCCESS,
  SET_FILTER_DATA,
} from "./action-types";

const initialState = {
  assets: [] as any,
  assetsLoading: false,
  errorMessage: "" as string,
  totalCount: 0,
  filterData: {} as any,
};

export type AIMAssetDeconState = Readonly<typeof initialState>;

const aimAssetDeconState = (
  state = initialState,
  action: any
): AIMAssetDeconState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DECON_DATA_START:
      return { ...state, assetsLoading: true };
    case FETCH_DECON_DATA_SUCCESS:
      return { ...state, assets: payload.data, assetsLoading: false };
    case FETCH_DECON_DATA_FAIL:
      return {
        ...state,
        assetsLoading: false,
        assets: {},
        errorMessage: payload.error,
      };
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: payload.data,
      };

    case CLEAR_ALL_MESSAGES:
      return {
        ...state,
        errorMessage: "",
      };
    case CLEAR_DECON_STATE:
      return initialState;
    default:
      return state;
  }
};

export default aimAssetDeconState;
