import {
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAIL,
  LOAD_MORE_NOTIFICATIONS,
  NEW_NOTIFICATION,
  READ_NOTIFICATION,
  CLEAR_NOTIFICATION,
  START_READ_ALL_NOTIFICATION,
  READ_ALL_NOTIFICATION_SUCCESS,
  READ_ALL_NOTIFICATION_FAIL,
  FETCH_NOTIFICATION_START,
} from "./action-types";

const initialState = {
  notifications: [] as any[],
  notificationsWithSkeletons: [] as any[],
  initLoading: true,
  loading: false,
  totalCount: 0,
  readAllLoader: false,
  notificationUnreadCount: 0,
  errorMessage: null as null | string,
};

export type NotificationManagementState = Readonly<typeof initialState>;

const notificationManagement = (
  state = initialState,
  action: any
): NotificationManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_NOTIFICATION_START:
      return {
        ...state,
        loading: true,
        initLoading: true,
      };
    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        totalCount: payload.totalCount,
        loading: false,
        initLoading: false,
        notificationUnreadCount: payload.notificationUnreadCount,
        notifications: payload.replace
          ? payload.notifications
          : state.notifications.concat(payload.notifications),
        notificationsWithSkeletons: payload.replace
          ? payload.notifications
          : state.notifications.concat(payload.notifications),
      };
    case FETCH_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        initLoading: false,
        notificationsWithSkeletons: state.notifications,
        errorMessage: payload.errorMessage,
      };
    case LOAD_MORE_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        notificationsWithSkeletons: payload.notifications,
      };
    case NEW_NOTIFICATION:
      return {
        ...state,
        loading: false,
        notifications: [payload, ...state.notifications],
        notificationsWithSkeletons: [
          payload,
          ...state.notificationsWithSkeletons,
        ],
        totalCount: state.totalCount + 1,
        notificationUnreadCount: state.notificationUnreadCount + 1,
      };
    case READ_NOTIFICATION:
      const notificationId = payload.notificationId;
      const notifications = state.notifications.map((notification) => {
        if (notification.id === notificationId) {
          notification.read = true;
        }
        return notification;
      });
      return {
        ...state,
        loading: false,
        notifications,
        notificationUnreadCount: state.notificationUnreadCount - 1,
        notificationsWithSkeletons: notifications,
      };
    case START_READ_ALL_NOTIFICATION:
      return {
        ...state,
        readAllLoader: true,
      };
    case READ_ALL_NOTIFICATION_SUCCESS:
      let tempState = { ...state };
      tempState.notifications = tempState.notifications.map(
        (notification: any) => {
          return { ...notification, read: true };
        }
      );
      tempState.notificationsWithSkeletons =
        tempState.notificationsWithSkeletons.map((notification: any) => {
          return { ...notification, read: true };
        });
      tempState.notificationUnreadCount = 0;
      return {
        ...tempState,
        readAllLoader: false,
      };
    case READ_ALL_NOTIFICATION_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        readAllLoader: false,
      };
    case CLEAR_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};

export default notificationManagement;
