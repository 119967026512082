/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import { Button } from "antd";
import Title from "antd/lib/typography/Title";
import { Helmet } from "react-helmet";
import classes from "./AIMReports.module.scss";
import { useHistory } from "react-router";
import { clearMessage } from "../../../../common/actions/common-actions";
import { CLEAR_ITEM_MSG } from "../../items/action-types";
import { CLEAR_CAMPUS_MSG } from "../../campus/action-types";
import { CLEAR_ALL_MESSAGES } from "../AIMDashboard/action-types";
import { connect } from "react-redux";

const AIMReports = ({ clearMessage }: any) => {
  const history = useHistory();
  const routePrefix = "/admin/aim/reports/";
  const onClickHandler = (page: string) => {
    history.push(`${routePrefix}${page}`);
  };
  useEffect(() => {
    return () => {
      clearMessage(CLEAR_ITEM_MSG);
      clearMessage(CLEAR_CAMPUS_MSG);
      clearMessage(CLEAR_ALL_MESSAGES);
    };
  }, []);
  const routes = {
    "aim-overall": "AIM Reports",
    rental: "Rental Item",
    checkin: "Asset Check-in",
    decon: (
      <>
        Asset <br />
        Decontamination
      </>
    ),
  };
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Reports | Admin Portal</title>
      </Helmet>
      <Title
        style={{ paddingTop: "15px", textAlign: "left" }}
        className={classes.titleText}
        level={5}
      >
        Reports
      </Title>
      <div className={classes.reportsContainer}>
        <div className={classes.reportsBtnGrid}>
          {Object.entries(routes).map((pageConfig: any) => (
            <Button
              onClick={() => onClickHandler(pageConfig[0])}
              type="primary"
              className={classes.reportBtn}
            >
              {pageConfig[1]}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { clearMessage })(AIMReports);
