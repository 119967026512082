/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Layout, Popover, Typography } from "antd";
import { connect, useDispatch } from "react-redux";
import { logoutUser } from "../../modules/auth/actions";
import { IRootState } from "../../../common/redux/reducers";
import logo from "../../../images/Logo.png";
import userIcon from "../../../images/user-icon.png";
import notificationIcon from "../../../images/notification.png";
import logoutIcon from "../../../images/logout.png";
import NotificationList from "./notification/notification-list";
import {
  clearNotification,
  fetchNotifications,
  readNotification,
  refreshNotification,
} from "./notification/actions";
import {
  ADMIN_DASHBOARD,
  AIM,
  ALL_CAMPUS_ID,
  DEFAULT_NOTIFICATION_LIMIT,
  SUB_ROOM1,
  UNSUB_ROOM1,
} from "../../../common/shared-constants";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  getMySocket,
  subscribeToRoom,
  unSubscribeToRoom,
} from "../../../common/utils/socket";
import { NEW_NOTIFICATION } from "./notification/action-types";
import { fetchCampuses } from "../../modules/dashboard/actions";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../ability/can";
const { Header } = Layout;
const { Title } = Typography;

const HeaderComp = ({
  logoutUser,
  account,
  notificationUnreadCount,
  fetchNotifications,
  notifications,
  readNotification,
  clearNotification,
  refreshNotification,
  fetchCampuses,
  variant, //"AIM" or "ADMIN_DASHBOARD"
}: any) => {
  const ability = useAbility(AbilityContext);
  const [selectedCampus, setSelectedCampus] = useState<any>();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const [notificationVisisble, setNotificationVisible] = useState(false);
  const logout = () => {
    logoutUser();
  };
  const validDashboardView =
    ability.can("view", ADMIN_DASHBOARD) && variant === ADMIN_DASHBOARD;
  const myStateRef: any = React.useRef(notifications.length);
  const selectedCampusRef: any = React.useRef(selectedCampus);
  useEffect(() => {
    selectedCampusRef.current = selectedCampus;
  }, [selectedCampus]);
  useEffect(() => {
    myStateRef.current = {
      length: notifications.length,
      campus: selectedCampus,
    };
  }, [notifications, selectedCampus]);
  const dispatch = useDispatch();
  const initSocket = async () => {
    const connect = await subscribeToRoom(SUB_ROOM1);
    if (connect) {
      const socket = getMySocket();
      socket.on(account.id, listenToNotification);
    }
  };
  const listenToNotification = (res: any) => {
    if (res.refresh)
      refreshNotification(
        myStateRef.current?.length,
        myStateRef.current?.campus
      );
    else {
      const currentSelectedCampus = selectedCampusRef.current;
      if (
        currentSelectedCampus?.id === res.campusId ||
        currentSelectedCampus?.id === ALL_CAMPUS_ID
      )
        dispatch({
          type: NEW_NOTIFICATION,
          payload: res,
        });
    }
  };
  useEffect(() => {
    notificationVisisble && fetchCampuses();
  }, [notificationVisisble]);
  useEffect(() => {
    initSocket();
    return () => {
      unSubscribeToRoom(UNSUB_ROOM1);
      const socket = getMySocket();
      socket.off(account.id, listenToNotification);
      clearNotification();
    };
  }, []);

  useEffect(() => {
    const localSelectedCampus = JSON.parse(
      localStorage.getItem("selectedCampus") || "{}"
    );
    (async function () {
      const { campuses } = await fetchCampuses();
      const finalObj = campuses?.find(
        (campus: any) => campus.id === localSelectedCampus.id
      );
      const storableObj = finalObj
        ? { id: finalObj.id, name: finalObj.name }
        : {
            name: "All",
            id: ALL_CAMPUS_ID,
          };
      setSelectedCampus(storableObj);
      (storableObj.id !== localSelectedCampus.id ||
        storableObj.name !== localSelectedCampus.name) &&
        localStorage.setItem("selectedCampus", JSON.stringify(storableObj));
      validDashboardView &&
        (await fetchNotificationsofUser({
          campusId: storableObj.id || ALL_CAMPUS_ID,
        }));
    })();
  }, [variant, validDashboardView]);

  const fetchNotificationsofUser = async ({
    campusId = 1,
    replace = false,
  }: any) => {
    return await fetchNotifications({
      skip: replace ? 0 : notifications.length,
      limit: DEFAULT_NOTIFICATION_LIMIT,
      campus: campusId,
      replace,
    });
  };

  const onNotificationClick = async (
    notificationId: any,
    readStatus: any,
    orderNumber: any
  ) => {
    setNotificationVisible(false);
    const routeParams: any = routeMatch.params;
    const orderIdFromPath = routeParams.id;
    if (!readStatus) {
      const res = await readNotification(notificationId);
      if (res.success && orderNumber !== orderIdFromPath) {
        history.push(`/admin/dashboard/order/${orderNumber}`);
      }
    } else {
      orderNumber !== orderIdFromPath &&
        history.push(`/admin/dashboard/order/${orderNumber}`);
    }
  };

  const handleVisibleChange = (visible: any) => {
    setNotificationVisible(visible);
  };
  const memoizedCampus = useMemo(() => selectedCampus, [selectedCampus?.id]);
  return (
    <Header className="commonHeader">
      <div className="logo">
        <a href="/admin">
          <img alt="logo" src={logo} />
        </a>
      </div>
      <div className="title">
        <Title level={4}>
          {variant === AIM
            ? "ASSET INVENTORY MANAGEMENT (AIM)"
            : `${process.env.REACT_APP_CLIENT_NAME} ADMIN PORTAL`}
        </Title>
      </div>
      <div className="iconsContainer">
        <div>
          <img alt="" src={userIcon} className="userIcon" />
          <span className="usernameContainer">{account.username}</span>
        </div>
        {validDashboardView && (
          <div className="notificationBadge">
            <Popover
              visible={notificationVisisble}
              onVisibleChange={handleVisibleChange}
              trigger="click"
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              placement="bottomRight"
              overlayClassName="notificationPopover"
              content={
                <NotificationList
                  {...{
                    selectedCampus: memoizedCampus,
                    setSelectedCampus,
                  }}
                  onNotificationClick={onNotificationClick}
                  fetchNotifications={fetchNotificationsofUser}
                />
              }
            >
              <Badge
                count={
                  notificationUnreadCount > 0 ? (
                    <div className="ant-badge-count">
                      {notificationUnreadCount > 99
                        ? "99+"
                        : notificationUnreadCount}
                    </div>
                  ) : null
                }
              >
                <img
                  alt=""
                  src={notificationIcon}
                  className="notificationIcon"
                />
              </Badge>
            </Popover>
          </div>
        )}
        <div onClick={logout} className="logoutContainer">
          <img alt="" src={logoutIcon} className="logoutIcon" />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({
  adminAuthentication: { isAuthenticated, account },
  notificationState: { notificationUnreadCount, notifications },
}: IRootState) => ({
  account,
  notificationUnreadCount,
  notifications,
});

export default connect(mapStateToProps, {
  logoutUser,
  fetchNotifications,
  readNotification,
  clearNotification,
  refreshNotification,
  fetchCampuses,
})(HeaderComp);
