/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState } from "react";
import { Row, Modal, Button, Form, Input, Alert, Spin, Switch } from "antd";
import classes from "./campus-modal.module.scss";
import "../../../shared/scss/shared-modal.scss";
import { trimValues } from "../../../../common/utils/trimUtil";
import { CloseOutlined } from "@ant-design/icons";
import { EDIT } from "../../../../common/shared-constants";
import {
  campusNameValidator,
  campusLocationNameValidator,
} from "../../../../common/utils/formValidators";

const CampusModal = ({
  campus,
  mode,
  visible,
  handleCancel,
  handleSubmit,
  errorMsg,
  clearMessages,
  loading,
}: any) => {
  const [submitted, setSubmitted] = useState(false);

  const onFormSubmit = async (values: any) => {
    if (submitted) {
      return;
    }
    setSubmitted(true);
    const trimedValues = trimValues(values);
    await handleSubmit(trimedValues);
    setSubmitted(false);
  };

  return (
    <React.Fragment>
      <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> {mode === EDIT ? "Edit Campus" : "Create Campus"} </p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={visible}
        width={500}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        {errorMsg ? (
          <Alert
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
            className={`fadeIn ${classes.errorContainer}`}
          />
        ) : null}
        <Form
          layout="horizontal"
          initialValues={campus}
          onFinish={onFormSubmit}
        >
          <Row>
            <Form.Item name="id" hidden />
            <Form.Item
              style={{ width: "100%" }}
              name="name"
              label="Name"
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[
                () => ({
                  validator: campusNameValidator,
                  required: true,
                }),
              ]}
            >
              <Input
                autoFocus={mode !== EDIT}
                placeholder="Enter here"
                className="inputStyle"
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              style={{ width: "100%" }}
              name="location"
              label="Location"
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[
                () => ({
                  validator: campusLocationNameValidator,
                  required: true,
                }),
              ]}
            >
              <Input placeholder="Enter here" className="inputStyle" />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              style={{ width: "100%" }}
              name="bannerMessage"
              label="Banner Text"
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[
                {
                  required: false,
                  max: 250,
                  message: "Banner Text should be a maximum of 250 characters.",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter here"
                className="inputStyle"
                autoSize={{ minRows: 1, maxRows: 15 }}
              />
            </Form.Item>
          </Row>
          <Form.Item
            name="bannerStatus"
            label="Show Banner Text"
            valuePropName="checked"
            initialValue={false}
            wrapperCol={{ span: 16 }}
            labelCol={{ span: 8 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
          <div className="modalBtn">
            <Form.Item style={{ marginRight: "3%" }}>
              <Button
                className="cancelBtn"
                htmlType="reset"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Spin spinning={loading}>
                <Button className="submitBtn" htmlType="submit">
                  Submit
                </Button>
              </Spin>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default CampusModal;
