/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  Input,
  Button,
  Alert,
  Spin,
  InputNumber,
  Tooltip,
} from "antd";
import classes from "./request-details.module.scss";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import {
  contactNameValidator,
  emailValidator,
  phoneNumberValidator,
  shortDescriptionValidator,
} from "../../../common/utils/formValidators";
import { allowOnlyNumbers } from "../../../common/utils/trimUtil";
import {
  DELIVERY_REQUEST_TYPE_ID,
  PICKUP_REQUEST_TYPE_ID,
  SHARPS_REQUEST_TYPE_ID,
  SPECIALTY_HOVER_TITLE,
  SPECIALTY_INPUT_NUMBER_DISABLED_TITLE,
  VIEW_PATIENTS_TEXT,
} from "../../../common/shared-constants";
import PatientDetailsModal from "../../../common/components/PatientDetailsModal/patient-details-modal";
import DelayModal from "../../../common/components/DelayModal/DelayModal";

const RequestDetails = (props: any) => {
  const [form] = Form.useForm();
  const [showPatients, setShowPatients] = useState<any>(false);
  const { showViewPatients } = props;
  const { campus: { id: campusId = "", name: campusName = "" } = {} } = props;
  const [loader, setloader] = useState<boolean>(false);
  const goBack = () => {
    props.goBack("itemselection");
  };
  const { Option } = Select;
  useEffect(() => {
    return props.clearErrorMessage;
  }, []);
  const onFinish = async (values: any) => {
    setloader(true);
    let finalSubmitData;
    finalSubmitData = {
      cartItem: [
        ...props.cartItems.map((cartItem: any) => {
          return {
            id: cartItem.item.id,
            quantity: cartItem.quantity,
            ...(cartItem.patients ? { patients: cartItem.patients } : {}),
          };
        }),
      ],
      ...values,
    };
    await props.onSubmit(finalSubmitData);
    setloader(false);
  };

  const onPriorityChange = (slaId: any) => {
    const sla = props.slas.find((ele: any) => ele.id === slaId);
    if (sla.disclaimerStatus) {
      info(sla.disclaimerInfo);
    }
  };

  const [showInfo, setShowInfo] = useState("");
  const info = (data: any) => {
    setShowInfo(data);
  };

  if (props.cartItems.length < 1) {
    goBack();
  }
  let patientDetailsPresent =
    props.cartItems.reduce(
      (acc: any, item: any) => (item.patients ? acc + 1 : acc),
      0
    ) > 0;
  return (
    <div className={classes.requestContainer}>
      <Row>
        <Col span={4} className={classes.gobackBtn}>
          <button onClick={goBack}></button>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <h3 className={classes.itemTitle}>Items</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={classes.requestList}>
            {props.cartItems.map((cart: any, index: any) => {
              return (
                <div className={classes.requestGrid}>
                  <div className={classes.requestGridImg}>
                    <div className={classes.itemImageWrapper}>
                      <img src={cart.item.image} alt="operation-bed" />
                      <div
                        className={`imageOverlay_100 decrement`}
                        onClick={() => {
                          cart.quantity === 1
                            ? props.onItemRemove(cart.item.id)
                            : props.quantityEdit(cart.item.id, "DECREASE");
                        }}
                      >
                        -
                      </div>
                      <div
                        className={`imageOverlay_100 increment`}
                        onClick={() => {
                          props.quantityEdit(cart.item.id, "INCREASE");
                        }}
                      >
                        +
                      </div>
                    </div>
                    <Tooltip title={cart.item.name}>
                      <div className={classes.itemName}>{cart.item.name}</div>
                    </Tooltip>

                    {props.requestTypeId === SHARPS_REQUEST_TYPE_ID && (
                      <div className={classes.itemSize}>
                        <b>Size:</b> {cart?.item?.size?.name}
                      </div>
                    )}
                    {cart.item.specialtyItem &&
                      props.requestTypeId === DELIVERY_REQUEST_TYPE_ID && (
                        <div
                          className={classes.specialty}
                          title={SPECIALTY_HOVER_TITLE}
                        >
                          *
                        </div>
                      )}
                    {cart.item.delay &&
                      !(props.requestTypeId === PICKUP_REQUEST_TYPE_ID) && (
                        <div className={classes.delay} title="Delay">
                          *
                        </div>
                      )}
                  </div>
                  <div className={classes.requestQty}>
                    <p>Qty</p>
                    <div className={classes.requestCount}>
                      <button
                        onClick={(id) => {
                          cart.quantity === 1
                            ? props.onItemRemove(cart.item.id)
                            : props.quantityEdit(cart.item.id, "DECREASE");
                        }}
                        className={classes.minus}
                      ></button>
                      <InputNumber
                        value={cart.quantity}
                        onChange={(quant) => {
                          props.quantityEdit(cart.item.id, "EDIT", quant);
                        }}
                        disabled={
                          !!(
                            props.requestTypeId === DELIVERY_REQUEST_TYPE_ID &&
                            cart.item.specialtyItem
                          )
                        }
                        title={
                          !!(
                            props.requestTypeId === DELIVERY_REQUEST_TYPE_ID &&
                            cart.item.specialtyItem
                          )
                            ? SPECIALTY_INPUT_NUMBER_DISABLED_TITLE
                            : ""
                        }
                        onKeyDown={allowOnlyNumbers}
                        min={1}
                        max={99}
                      />
                      <button
                        onClick={(id) => {
                          props.quantityEdit(cart.item.id, "INCREASE");
                        }}
                        disabled={cart.quantity >= 99}
                        className={classes.add}
                      ></button>
                    </div>
                    {showViewPatients && cart.patients?.length ? (
                      <span
                        className="linkLikeText"
                        style={{ position: "relative", left: "5px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPatients({
                            itemId: cart.item.id,
                            mode: "VIEW",
                          });
                        }}
                      >
                        {VIEW_PATIENTS_TEXT}
                      </span>
                    ) : (
                      <div
                        style={
                          patientDetailsPresent
                            ? {
                                height: "20px",
                                width: "100%",
                              }
                            : {}
                        }
                      ></div>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      props.onItemRemove(cart.item.id);
                    }}
                    className={classes.close}
                  >
                    <span>X</span>
                  </button>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      <Form
        className={classes.requestForm}
        autoComplete="off"
        name="basic"
        labelAlign="left"
        initialValues={{
          ...props.locationValues,
          ...(props.account && props.account !== null
            ? {
                name: props.account.name,
                phoneNumber: props.account.phone,
                email: props.account?.email,
              }
            : {}),
        }}
        onFinish={onFinish}
        form={form}
      >
        <Row justify="space-between">
          <Col span={9}>
            <Form.Item
              hidden
              name="requestTypeId"
              initialValue={props.requestTypeId}
            />
            <Form.Item
              name="slaId"
              label="Request Priority"
              rules={[{ required: true, message: "Priority is required" }]}
              wrapperCol={{ lg: 13, xl: 14 }}
              labelCol={{ lg: 10, xl: 8 }}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Choose priority"
                showSearch
                filterOption={(input: any, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={onPriorityChange}
              >
                {props.slas?.map((sla: any) => {
                  return <Option value={sla.id}>{sla.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item
              label="Short Description"
              name="additionalInformation"
              className={classes.nonRequired}
              wrapperCol={{ lg: 18, xl: 19 }}
              labelCol={{ lg: 6, xl: 5 }}
              rules={[
                {
                  validator: shortDescriptionValidator,
                  required: false,
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <h3 className={classes.itemTitle}>Contact Information</h3>
        </Row>
        <Row justify="space-between">
          <Col lg={7}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  validator: contactNameValidator,
                },
              ]}
              wrapperCol={{ lg: 16, xl: 18 }}
              labelCol={{ lg: 8, xl: 6 }}
            >
              <Input disabled={props.account !== null ? true : false} />
            </Form.Item>
          </Col>
          <Col lg={7}>
            <Form.Item
              label="Phone No"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  validator: phoneNumberValidator,
                },
              ]}
              wrapperCol={{ lg: 16, xl: 18 }}
              labelCol={{ lg: 8, xl: 6 }}
            >
              <Input disabled={props.account !== null ? true : false} />
            </Form.Item>
          </Col>
          <Col lg={7}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: false,
                  validator: emailValidator,
                  message: "Please enter valid email!",
                  type: "email",
                },
              ]}
              wrapperCol={{ lg: 16, xl: 18 }}
              labelCol={{ lg: 8, xl: 6 }}
              className={classes.nonRequired}
            >
              <Input disabled={props.account !== null ? true : false} />
            </Form.Item>
          </Col>
          <Col lg={7}>
            <Form.Item
              label="Campus"
              name="campusId"
              wrapperCol={{ lg: 16, xl: 18 }}
              labelCol={{ lg: 8, xl: 6 }}
              rules={[{ required: true }]}
            >
              <Select disabled>
                <Option value={campusId}>{campusName}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={7}>
            <Form.Item
              label="Unit"
              name="unitId"
              wrapperCol={{ lg: 16, xl: 18 }}
              labelCol={{ lg: 8, xl: 6 }}
              rules={[{ required: true }]}
            >
              <Select disabled>
                {props.units.map((unit: any) => {
                  return <Option value={unit.id}>{unit.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={7}>
            <Form.Item
              label="Room No"
              name="room"
              requiredMark={true}
              wrapperCol={{ lg: 16, xl: 18 }}
              labelCol={{ lg: 8, xl: 6 }}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          {props.errorMessage ? (
            <Alert
              className={`fadeIn ${classes.alertContainer}`}
              message={props.errorMessage}
              type="error"
              closable
              afterClose={props.clearErrorMessage}
            />
          ) : null}
        </Row>
        <Row justify="center">
          <Col span={12} className={classes.submitBtn}>
            <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: 15 }}>
              <Spin spinning={loader}>
                <Button htmlType="submit">Submit</Button>
              </Spin>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <PatientDetailsModal
        visible={!!showPatients.itemId}
        toggleModal={() => setShowPatients(false)}
        cart={props.cartItems} //just to remove items
        itemId={showPatients.itemId}
        mode={showPatients.mode}
      />
      <DelayModal
        key="info-modal"
        handleCancel={() => {
          setShowInfo("");
        }}
        handleOk={() => {
          setShowInfo("");
        }}
        isModalVisible={!!showInfo}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text={showInfo}
        okayText="Okay"
        hideCancel={true}
      />
    </div>
  );
};

export default RequestDetails;
