export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAIL = "FETCH_NOTIFICATION_FAIL";
export const LOAD_MORE_NOTIFICATIONS = "LOAD_MORE_NOTIFICATIONS";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const UPDATE_COUNT = "UPDATE_COUNT";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const START_READ_ALL_NOTIFICATION = "START_READ_ALL_NOTIFICATION";
export const READ_ALL_NOTIFICATION_SUCCESS = "READ_ALL_NOTIFICATION_SUCCESS";
export const READ_ALL_NOTIFICATION_FAIL = "READ_ALL_NOTIFICATION_FAIL";
export const FETCH_NOTIFICATION_START = "FETCH_NOTIFICATION_START";
