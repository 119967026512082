/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import MyRequest from "./my-request";
import { useHistory } from "react-router-dom";
import classes from "./my-requests.module.scss";
import {
  DEFAULT_PAGE,
  SUB_ROOM4,
  UNSUB_ROOM4,
} from "../../../common/shared-constants";
import { Alert, Button, Card, Modal, notification, Pagination } from "antd";
import { IRootState } from "../../../common/redux/reducers";
import {
  fetchMyRequestsList,
  fetchItems,
  editRequest,
  cancelRequest,
  updateRequest,
  reorderRequest,
  clearMyRequestsData,
} from "./action";
import { connect } from "react-redux";
import { clearMessage } from "../../../common/actions/common-actions";
import { CLEAR_MESSAGES, CLEAR_REQUESTS_FETCH_ERROR_MSG } from "./action-types";
import { CloseOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";
import RequestDetailsView from "./modals/request-details-view";
import {
  fetchCampuses,
  fetchUnits,
  fetchReqSlas,
} from "../../shared/reducers/actions";
import {
  getMySocket,
  subscribeToRoom,
  unSubscribeToRoom,
} from "../../../common/utils/socket";
import DelayModal from "../../../common/components/DelayModal/DelayModal";

const MyRequests = ({
  fetchMyRequestsList,
  orders,
  fetchErrorMessage,
  account,
  totalCount,
  clearMessage,
  fetchCampuses,
  fetchUnits,
  fetchReqSlas,
  allCampuses,
  units,
  slas,
  allItems,
  fetchItems,
  editRequest,
  successMessage,
  errorMessage,
  cancelRequest,
  updateRequest,
  reorderRequest,
  clearMyRequestsData,
  myRequestListLoading,
}: any) => {
  const history = useHistory();
  const [tableOpt, setOperation] = useState({
    limit: 5,
    page: DEFAULT_PAGE,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [socketResponse, setSocketResponse] = useState<any>();
  const [newOrderSocketResponse, setNewOrderSocketResponse] = useState<any>();
  const updatedOrderListener = (res: any) => {
    setSocketResponse(res);
  };

  const newOrderListener = (res: any) => {
    setNewOrderSocketResponse(res);
    notification.success({
      message: `New Order ${res} Created`,
      placement: "bottomRight",
    });
  };
  useEffect(() => {
    if (account) {
      fetchMyRequestsList(account?.id, tableOpt.limit, tableOpt.page);
    }
  }, [newOrderSocketResponse]);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const initSocket = async () => {
    const connect = await subscribeToRoom(SUB_ROOM4);
    if (connect) {
      const socket = getMySocket();
      socket.on(`new-order-${account.id}`, newOrderListener);
      socket.on(`my-order-${account.id}`, updatedOrderListener);
    }
  };

  useEffect(() => {
    initSocket();
    return () => {
      clearMyRequestsData();
      const socket = getMySocket();
      socket.off(`new-order-${account.id}`, newOrderListener);
      socket.off(`my-order-${account.id}`, updatedOrderListener);
      unSubscribeToRoom(UNSUB_ROOM4);
      clearMessages();
    };
  }, []);
  useEffect(() => {
    if (account) {
      fetchMyRequestsList(account?.id, tableOpt.limit, tableOpt.page);
    }
  }, [account]);

  const clearMessages = () => {
    clearMessage(CLEAR_MESSAGES);
  };

  useEffect(() => {
    if (socketResponse) {
      updateRequest(socketResponse);
      if (selectedRequest) {
        if (selectedRequest.id === socketResponse.id && !warningOpen) {
          warningModal();
        }
      }
      setSocketResponse(undefined);
    }
  }, [socketResponse]);
  const [showWarningModal, setShowWarningModal] = useState<any>();
  const warningModal = () => {
    setWarningOpen(true);
  };

  const goBack = () => {
    history.push("/request");
  };

  const clearFetchError = () => {
    clearMessage(CLEAR_REQUESTS_FETCH_ERROR_MSG);
  };

  const handlePageChange = (current: any, pageSize: any) => {
    setOperation({
      ...tableOpt,
      limit: pageSize,
      page: current,
    });
    fetchMyRequestsList(account.id, pageSize, current);
  };
  const onRequestClick = (data: any) => {
    fetchCampuses();
    fetchUnits(data.campusId);
    fetchReqSlas(data.requestType.id, data.campusId);
    setSelectedRequest(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRequest(null);
    clearMessages();
  };

  return (
    <div className={classes.requestsListContainer}>
      <p className={classes.pageTitle}>My Requests</p>
      <div className={classes.requestOuter}>
        <div className={classes.gobackBtn}>
          <button onClick={goBack}></button>
        </div>
        {fetchErrorMessage ? (
          <Alert
            className={`fadeIn ${classes.alertContainer}`}
            message={fetchErrorMessage}
            type="error"
            closable
            afterClose={clearFetchError}
          />
        ) : null}
        <div className={classes.requestList}>
          {myRequestListLoading
            ? [...Array(5)].map((index: any) => (
                <Card loading={true} className={classes.skeletonList} />
              ))
            : orders.map((request: any, index: any) => {
                return <MyRequest onClick={onRequestClick} data={request} />;
              })}
        </div>
        <Pagination
          onChange={handlePageChange}
          current={tableOpt.page}
          pageSize={tableOpt.limit}
          showSizeChanger={false}
          total={totalCount}
          className={classes.myRequestPagination}
        />
      </div>
      {isModalVisible && (
        <Modal
          title={
            <div className="commonModalHeader">
              <div className="title">
                <p> Request Details</p>
              </div>
              <div className="close">
                <Button className="closeBtn" onClick={handleCancel}>
                  <CloseOutlined className="close-icon" />
                </Button>
              </div>
            </div>
          }
          visible={isModalVisible}
          width={"100%"}
          maskClosable={false}
          onCancel={handleCancel}
          footer={null}
          closable={false}
          centered={true}
          className="myRequestViewModal"
        >
          <RequestDetailsView
            allCampuses={allCampuses}
            units={units}
            slas={slas}
            request={selectedRequest}
            fetchUnits={fetchUnits}
            fetchReqSlas={fetchReqSlas}
            fetchItems={fetchItems}
            allItems={allItems}
            editRequest={editRequest}
            setSelectedRequest={setSelectedRequest}
            successMsg={successMessage}
            errorMsg={errorMessage}
            clearMessages={clearMessages}
            cancelRequest={cancelRequest}
            reorderRequest={reorderRequest}
            closeModal={handleCancel}
          />
        </Modal>
      )}
      <DelayModal
        key="info-modal"
        handleCancel={() => {
          setShowWarningModal(undefined);
        }}
        handleOk={() => {
          setWarningOpen(false);
          handleCancel();
          setShowWarningModal(undefined);
        }}
        isModalVisible={!!showWarningModal}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text="Current Order you are viewing is Updated. Please close and reopen the
        popup."
        okayText="Okay"
        hideCancel={true}
      />
    </div>
  );
};

const mapStateToProps = ({
  myRequests,
  authentication,
  requestData,
}: IRootState) => ({
  orders: myRequests.orders,
  totalCount: myRequests.totalCount,
  fetchErrorMessage: myRequests.fetchErrorMessage,
  successMessage: myRequests.successMessage,
  errorMessage: myRequests.errorMessage,
  account: authentication.account,
  allCampuses: requestData.campuses,
  units: requestData.units,
  slas: requestData.slas,
  allItems: myRequests.items,
  myRequestListLoading: myRequests.ordersLoading,
});

const mapDispatchToProps = {
  fetchMyRequestsList,
  fetchItems,
  clearMessage,
  fetchCampuses,
  fetchUnits,
  fetchReqSlas,
  editRequest,
  cancelRequest,
  updateRequest,
  reorderRequest,
  clearMyRequestsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRequests);
