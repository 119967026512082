/* eslint-disable react-hooks/exhaustive-deps*/

import {
  Col,
  Row,
  Select,
  Tooltip,
  Input,
  Button,
  Form,
  message,
  Modal,
  Spin,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { adminRootInstance } from "../../../../../../common/config/axios-config";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  AVAILABLE_ASSET,
  REPAIR_ASSET,
  RETIRED_ASSET,
} from "../../../../../../common/shared-constants";
import SingleLineSkeleton from "../../../../../../common/utils/SingleLineSkeleton";
import classes from "./EditModal.module.scss";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";

export default function EditModal(props: any) {
  const {
    controlNumber,
    equipmentName,
    status,
    serialnumber,
    lastLocation,
    lastCleanedBy,
    orderNumber = "",
    specialty,
    lastUpdated,
    repairedAt,
    rental,
    notes,
    closeModal,
    id,
    onEdit,
    onDelete,
    visible,
    deleted,
    deletedBy,
  } = props;
  const initialLoader = { edit: false, delete: false };
  const deleteResp: any = useRef({});
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [updatedData, setUpdatedData] = useState<any>({ loading: true });
  const [loaders, setLoaders] = useState<any>(initialLoader);
  useEffect(() => {
    setSelectedStatus(status);
    form.resetFields();
    id &&
      (async () => {
        await adminRootInstance
          .get(`aim/${id}`)
          .then((res: any) => {
            setUpdatedData({ ...res.data.data, loading: false });
          })
          .catch((error) => {
            setUpdatedData({
              error: error.response?.data?.message,
              loading: false,
            });
          });
      })();
    return () => {
      setUpdatedData({ loading: true });
      setLoaders(initialLoader);
    };
  }, [id]);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    setSelectedStatus(status);
  }, [visible]);
  const itemCategoryString =
    updatedData?.itemCategory?.length &&
    updatedData.itemCategory?.reduce(
      (prev: string, curr: string) => `${prev}, ${curr}`
    );
  const config = [
    {
      label: "Equipment Type",
      value: equipmentName || "-",
      tooltip: equipmentName,
      parentWidth: 8,
    },
    {
      label: "Status",
      value: deleted ? RETIRED_ASSET : status || "-",
      tooltip: status,
      parentWidth: 8,
    },
    {
      label: "Control number",
      value: controlNumber || "-",
      tooltip: controlNumber,
      parentWidth: 8,
    },
    {
      label: "Serial number",
      value: serialnumber || "-",
      tooltip: serialnumber,
      parentWidth: 8,
    },
    {
      label: "Last location",
      value: lastLocation || "-",
      tooltip: lastLocation,
      parentWidth: 8,
    },
    {
      label: "Last Room/BS",
      value: updatedData.lastRoomName || "-",
      tooltip: updatedData.lastRoomName,
      parentWidth: 8,
    },
    {
      label: "Last cleaned by",
      value: updatedData.loading ? (
        <SingleLineSkeleton width={150} />
      ) : (
        updatedData.lastCleanedBy || lastCleanedBy || "-"
      ),
      tooltip: updatedData?.lastCleanedBy || lastCleanedBy || undefined,
      parentWidth: 8,
    },
    {
      label: "Last order",
      value: updatedData.loading ? (
        <SingleLineSkeleton width={150} />
      ) : (
        updatedData.orderNumber || orderNumber || "-"
      ),
      tooltip: updatedData?.orderNumber || orderNumber || undefined,
      parentWidth: 8,
    },
    {
      label: "Speciality",
      value: specialty ? "Yes" : "No",
      tooltip: specialty ? "Yes" : "No",
      parentWidth: 8,
    },
    {
      label: "Rental",
      value: specialty ? (rental ? "Yes" : "No") : "-",
      tooltip: specialty ? (rental ? "Yes" : "No") : "Not Applicable",
      parentWidth: 8,
    },
    deleted && {
      label: "Retired on",
      value: lastUpdated || "-",
      tooltip: lastUpdated || "Not Applicable",
      parentWidth: 8,
    },
    deleted && {
      label: "Retired by",
      value: deletedBy || "-",
      tooltip: deletedBy,
      parentWidth: 8,
    },
    status === REPAIR_ASSET && {
      label: "Repair Started on",
      value: repairedAt || "-",
      tooltip: repairedAt || "Not Applicable",
      parentWidth: 8,
    },
    {
      label: "Item category",
      value: updatedData.loading ? (
        <SingleLineSkeleton width={350} />
      ) : (
        itemCategoryString || "-"
      ),
      tooltip: itemCategoryString || undefined,
      parentWidth: 24,
    },
    deleted && notes && status === REPAIR_ASSET
      ? {
          label: "Repair Description",
          value: notes || "-",
          tooltip: "Repair Notes" || undefined,
          parentWidth: 24,
        }
      : null,
  ];
  const dataBody = (
    <Row align="middle" className={classes.contentBody}>
      {config.map((configObj: any) =>
        configObj ? (
          <Col span={configObj.parentWidth} className={`${classes.dataField} `}>
            <Row justify="start" align="bottom">
              <Col span={24}>
                <Row align="bottom" style={{ width: "100%" }}>
                  <label>{configObj.label}:</label>
                  <Tooltip
                    title={configObj.tooltip}
                    className={classes.ellipsis}
                    overlayInnerStyle={{ width: "max-content" }}
                    arrowPointAtCenter
                    destroyTooltipOnHide
                  >
                    <span
                      style={{
                        paddingLeft: "5px",
                        maxWidth: `${(configObj.parentWidth / 8) * 200}px`,
                      }}
                    >
                      {configObj.value}
                    </span>
                  </Tooltip>
                </Row>
              </Col>
            </Row>
          </Col>
        ) : (
          <React.Fragment />
        )
      )}
    </Row>
  );
  return (
    <Modal
      visible={visible}
      onCancel={() => closeModal()}
      maskClosable={false}
      footer={null}
      width={1000}
      closable={!(loaders.edit || loaders.delete)}
      confirmLoading={true}
    >
      <Col span={24} key={id}>
        <Row justify="center" className={classes.title}>
          Asset Details
        </Row>
        {updatedData.loading ? <Spin>{dataBody}</Spin> : dataBody}
        {!deleted && (
          <Row justify="center">
            <Col span={24}>
              <Row className={classes.contentBody} align="middle">
                <Col span={8} className={`${classes.dataField}`}>
                  <Tooltip title="">
                    <label>Change Status:</label>{" "}
                    <Select
                      className={classes.statusSelect}
                      allowClear
                      placeholder={status}
                      value={selectedStatus}
                      onClear={() => setSelectedStatus(status)}
                      onChange={(val: any) => val && setSelectedStatus(val)}
                      size="middle"
                    >
                      {status === REPAIR_ASSET ? (
                        <Select.Option
                          value={AVAILABLE_ASSET}
                          style={{ fontSize: "13px" }}
                        >
                          {AVAILABLE_ASSET}
                        </Select.Option>
                      ) : (
                        <Select.Option
                          value={REPAIR_ASSET}
                          style={{ fontSize: "13px" }}
                        >
                          {REPAIR_ASSET}
                        </Select.Option>
                      )}
                    </Select>
                  </Tooltip>
                </Col>
                {notes && selectedStatus === REPAIR_ASSET && (
                  <Col span={16} className={classes.dataField}>
                    <label>Repair Description:</label>
                    <span style={{ marginLeft: "5px" }}>{notes}</span>
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={24} className={classes.repairDesc}>
              <Form
                form={form}
                onFinish={async (val: any) => {
                  setLoaders((loadObj: any) => ({ ...loadObj, edit: true }));
                  const resp = await onEdit({ ...val, status: status });
                  setLoaders((loadObj: any) => ({ ...loadObj, edit: false }));
                  if (resp.success) {
                    message.success("Status updated successfully!");
                    closeModal();
                  } else
                    message.error(resp?.message || "Something went wrong!");
                }}
              >
                {selectedStatus === REPAIR_ASSET && status !== REPAIR_ASSET && (
                  <Form.Item
                    name="notes"
                    style={{ width: "100%" }}
                    initialValue={""}
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (!value || value.trim().length < 1)
                            return Promise.reject("Enter a valid reason!");
                          else if (value.trim().length > 200)
                            return Promise.reject(
                              `Reason should be less than or equal to ${200} character long!`
                            );
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.TextArea
                      className={classes.notes}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder="Enter Repair Description"
                      onKeyDown={(event: any) => {
                        if (
                          (event.keyCode === 10 || event.keyCode === 13) &&
                          event.ctrlKey
                        )
                          form.submit();
                      }}
                    />
                  </Form.Item>
                )}

                <Row justify="center" className={classes.submitButton}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="nextBtn"
                    loading={loaders.edit}
                    disabled={!selectedStatus || status === selectedStatus}
                  >
                    <SaveOutlined />
                    Save & Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="button"
                    className="nextBtn "
                    style={{ marginLeft: "50px" }}
                    loading={loaders.delete}
                    onClick={async () => {
                      Modal.confirm({
                        maskClosable: false,
                        title: "Confirm Retirement",
                        icon: (
                          <ExclamationCircleOutlined
                            style={{ color: "rgba(0, 0, 0, 0.85)" }}
                          />
                        ),

                        content: "Are you sure you want to retire this asset?",
                        okText: "I'm sure",
                        cancelText: "No",
                        className: "sharpDelayModal",
                        afterClose: () => {
                          if (deleteResp.current?.success) {
                            closeModal();
                            deleteResp.current = {};
                          }
                        },
                        onOk: async () => {
                          setLoaders((loadObj: any) => ({
                            ...loadObj,
                            delete: true,
                          }));
                          const resp = await onDelete({ assetId: id });
                          deleteResp.current = resp;
                          setLoaders((loadObj: any) => ({
                            ...loadObj,
                            delete: false,
                          }));
                          if (resp.success) {
                            message.success("Asset Retired successfully!");
                            closeModal();
                          } else {
                            message.error(resp.message);
                          }

                          return resp;
                        },
                        onCancel() {
                          deleteResp.current = {};
                        },
                        okButtonProps: {
                          className: "nextBtn",
                        },
                        cancelButtonProps: {
                          className: "backBtn",
                        },
                      });
                    }}
                  >
                    <DeleteOutlined />
                    Retire Asset
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
      </Col>
    </Modal>
  );
}
