import { FormInstance, Form, Select, Empty, Button, DatePicker } from "antd";
import moment from "moment";
import { useState } from "react";
import ErrorBoundaryComp from "../../../../../../common/components/ErrorBoundaryComp/ErrorBoundaryComp";
import { ADMIN_DATE_FORMAT } from "../../../../../../common/shared-constants";
import classes from "./TableComp.module.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface TableFiltersInterface {
  form: FormInstance;
  onFilterSubmit: (filterData: any) => void;
  onFilterReset: () => void;
  filterConfig: any;
  initialValues: any;
  defaultValues: any;
}
const TableFilters = ({
  form,
  onFilterSubmit,
  onFilterReset,
  filterConfig,
  initialValues,
  defaultValues,
}: TableFiltersInterface) => {
  //Since the call back updates the form --> useState will rerender whenever that callback runs & updates - i.e. on every select change
  const [, setDisabled] = useState<any>();

  const getElement = ({
    type,
    itemName,
    loading,
    fieldClassName,
    fieldPlaceholder,
    notFoundDesc,
    fieldOnChange,
    fieldDisabled,
    data,
    initialValues,
  }: any) => {
    switch (type) {
      case "daterange":
        return (
          <RangePicker
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            disabledDate={(current: any) =>
              current && current > moment().endOf("day")
            }
            format={ADMIN_DATE_FORMAT}
          />
        );

      default:
        if (!data?.some(({ id }: any) => id === initialValues[itemName])) {
          //rest the filter value for that particular itemName
          form.setFieldsValue({ [itemName]: defaultValues[itemName] });
        }
        return (
          <Select
            key={itemName}
            loading={!!loading}
            className={fieldClassName ? fieldClassName : classes.campusSelect}
            showSearch
            placeholder={fieldPlaceholder}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={notFoundDesc}
                style={{ margin: "10px 0px" }}
              />
            }
            onChange={(val: any, option: any) => {
              fieldOnChange(val, option);
              setDisabled((prev: any) => ({
                ...prev,
                [itemName]: fieldDisabled(form),
              }));
            }}
            filterOption={(input: any, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={fieldDisabled(form)}
          >
            {data?.map(({ name, id }: any, index: number) => (
              <Option value={id} key={`${id}-${index}`}>
                {name}
              </Option>
            ))}
          </Select>
        );
    }
  };
  return (
    <ErrorBoundaryComp>
      <Form
        form={form}
        name="basic"
        onFinish={onFilterSubmit}
        initialValues={initialValues}
        className={classes.dashboardFilter}
      >
        {filterConfig?.map(
          ({
            itemName,
            itemLabel,
            itemProps: itemprops,
            fieldClassName,
            fieldPlaceholder,
            notFoundDesc,
            fieldOnChange,
            fieldDisabled,
            data,
            loading,
            type,
          }: any) => (
            <Form.Item
              key={itemName}
              wrapperCol={{ lg: 16 }}
              labelCol={{ lg: 8 }}
              name={itemName}
              label={itemLabel}
              labelAlign="left"
              {...{ itemprops }}
            >
              {getElement({
                type,
                itemName,
                loading,
                fieldClassName,
                fieldPlaceholder,
                notFoundDesc,
                fieldOnChange,
                fieldDisabled,
                data,
                initialValues,
              })}
            </Form.Item>
          )
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "10px 0",
          }}
        >
          <Button onClick={onFilterReset} type="primary">
            Reset Filter
          </Button>
          <Button htmlType="submit" type="primary">
            Apply Filter
          </Button>
        </div>
      </Form>
    </ErrorBoundaryComp>
  );
};

export default TableFilters;
