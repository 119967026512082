import { Button } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { Helmet } from "react-helmet";
import classes from "./reports.module.scss";
import { useHistory } from "react-router";

const Reports = () => {
  const history = useHistory();
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Reports | Admin Portal</title>
      </Helmet>
      <Title
        style={{ paddingTop: "15px", textAlign: "left" }}
        className={classes.titleText}
        level={5}
      >
        Reports
      </Title>
      <div className={classes.reportsContainer}>
        <div className={classes.reportsBtnGrid}>
          <Button
            onClick={() => history.push("/admin/reports/sla-reports")}
            type="primary"
            className={classes.reportBtn}
          >
            SLA Based
          </Button>
          <Button
            onClick={() => history.push("/admin/reports/status-reports")}
            type="primary"
            className={classes.reportBtn}
          >
            Status Based
          </Button>
          <Button
            onClick={() => history.push("/admin/reports/items-reports")}
            type="primary"
            className={classes.reportBtn}
          >
            Items Based
          </Button>
          <Button
            onClick={() => history.push("/admin/reports/productivity-reports")}
            type="primary"
            className={classes.reportBtn}
          >
            Productivity
          </Button>
          <Button
            type="primary"
            className={classes.reportBtn}
            onClick={() =>
              history.push("/admin/reports/speciality-items-reports")
            }
          >
            Speciality Item
          </Button>
          <Button
            type="primary"
            className={classes.reportBtn}
            onClick={() => history.push("/admin/reports/overall-reports")}
          >
            Overall Report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Reports;
