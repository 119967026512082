import moment from "moment";
import React, { useEffect, useState } from "react";

function timeAgo(time: any) {
  return moment(time).fromNow(true);
}

export default function CustomTimeAgo(props: any) {
  const { date } = props;
  const [fromNow, setFromNow] = useState<any>(timeAgo(date));
  useEffect(() => {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: (number) => number + "s ago",
        ss: "%ds ago",
        m: "1m ago",
        mm: "%dm ago",
        h: "1h ago",
        hh: "%dh ago",
        d: "1d ago",
        dd: "%dd ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago",
        w: "a week ago",
        ww: "%d weeks ago",
      },
    });
    setFromNow(timeAgo(date));
    const interval = setInterval(() => {
      setFromNow(timeAgo(date));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  return (
    <div title={date} style={{ fontSize: "12px" }}>
      {fromNow}
    </div>
  );
}
