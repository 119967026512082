import React from "react";
import { connect } from "react-redux";
import { fetchProductivityReport } from "../../actions";
import { IRootState } from "../../../../../common/redux/reducers";
import ReportsTable from "../commons/report-table";
export const productivitySummaryColumns = [
  {
    title: "User Name",
    dataIndex: "userName",
    fixed: "left",
    align: "center",
    render: (record: any) => record || "-",
    width: "10%",
  },
  {
    title: "Average Queue Duration (min)",
    dataIndex: "avgQueueDuration",
    align: "center",
    render: (record: any) => (record !== null ? record : "-"),
    width: "15%",
  },
  {
    title: "Average Accept Duration (min)",
    dataIndex: "avgAcceptDuration",
    align: "center",
    render: (record: any) => (record !== null ? record : "-"),
    width: "15%",
  },
  {
    title: "Average Start Duration (min)",
    dataIndex: "avgStartDuration",
    align: "center",
    render: (record: any) => (record !== null ? record : "-"),
    width: "15%",
  },
  {
    title: "Average Deliver Duration (min)",
    dataIndex: "avgDeliverDuration",
    align: "center",
    render: (record: any) => (record !== null ? record : "-"),
    width: "15%",
  },
  {
    title: "Average Delay Duration (min)",
    dataIndex: "avgDelayDuration",
    align: "center",
    render: (record: any) => (record !== null ? record : "-"),
    width: "15%",
  },
];
const ReportSummaryTable = ({
  fetchProductivityReport,
  reportData,
  reportLoading,
  filterData,
}: any) => {
  return (
    <ReportsTable
      loading={reportLoading}
      rowData={reportData?.data}
      columnData={productivitySummaryColumns}
      totalCount={reportData?.totalCount}
      fetchData={(pagination: any) => {
        fetchProductivityReport({
          ...pagination,
          ...filterData,
        });
      }}
    />
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  allSla: reportsState.allSlas,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  reportData: reportsState.reportData,
  reportLoading: reportsState.loading,
  filterData: reportsState.filterData,
});
const mapDispatchToProps = {
  fetchProductivityReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportSummaryTable);
