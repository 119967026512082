export const phoneNumberValidator = (rule: object, val: String) => {
  const value = val.trim();
  if (value && value.length) {
    if (value.length < 10) {
      return Promise.reject("Should be minimum of 10 characters.");
    } else if (value.length > 20) {
      return Promise.reject("Should be maximum of 20 characters.");
    } else if (
      !value.match(
        /^[+]?[(]?[0-9]{1,4}[)]?([-\s.]?[0-9]{3})+[-\s.]?[0-9]{4,10}$/
      )
    ) {
      return Promise.reject("Please enter a valid phone number.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter your phone number!.");
  }
};

export const contactNameValidator = (rule: object, val: String) => {
  const value = val?.trim();
  if (value && value.length) {
    if (value.length < 1) {
      return Promise.reject("Name should be minimum of 1 character.");
    } else if (value.length > 30) {
      return Promise.reject("Name should be maximum of 30 characters.");
    } else if (value.match(/^.*\s{2,}.*$/)) {
      return Promise.reject("Maximum one space is allowed.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter your name!");
  }
};

export const shortDescriptionValidator = (rule: object, value: String) => {
  if (value?.trim() && value?.trim().length) {
    if (value.trim().length > 200) {
      return Promise.reject("Info should be maximum of 200 characters.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
};

export const locationRoomNameValidator = (rule: object, value: String) => {
  if (value && value.length) {
    if (!value.match(/^[a-zA-Z"'0-9]+([a-zA-Z0-9_ $'"@/&.,:)(%#-])*$/)) {
      return Promise.reject("Please enter a valid room.");
    } else if (value.length < 1) {
      return Promise.reject("Room should be minimum of 1 character.");
    } else if (value.length > 15) {
      return Promise.reject("Room should be maximum of 15 characters.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter the room name.");
  }
};

export const unitNameValidator = (rule: any, value: String) => {
  if (value && value.length) {
    if (value.length < 1) {
      return Promise.reject("Unit name should be minimum of 1 character.");
    } else if (value.length > 30) {
      return Promise.reject("Unit name should be maximum of 30 characters.");
    } else if (value.match(/^.*\s{2,}.*$/)) {
      return Promise.reject("Maximum one space is allowed.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter unit name.");
  }
};

export const buildingNameValidator = (rule: any, value: String) => {
  if (value && value.length) {
    if (value.length < 1) {
      return Promise.reject(
        "Building name/number should be minimum of 1 character."
      );
    } else if (value.length > 30) {
      return Promise.reject(
        "Building name/number should be maximum of 30 characters."
      );
    } else if (value.match(/^.*\s{2,}.*$/)) {
      return Promise.reject("Maximum one space is allowed.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter Building name/number.");
  }
};

export const floorNameValidator = (rule: any, value: String) => {
  if (value && value.length) {
    if (value.length < 1) {
      return Promise.reject(
        "Floor name/number should be minimum of 1 character."
      );
    } else if (value.length > 30) {
      return Promise.reject(
        "Floor name/number should be maximum of 30 characters."
      );
    } else if (value.match(/^.*\s{2,}.*$/)) {
      return Promise.reject("Maximum one space is allowed.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter Floor name/number.");
  }
};

export const emailValidator = (rule: any, value: string) => {
  if (value && value.length) {
    if (
      !value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/) ||
      !value.match(/^[a-z]/)
    ) {
      return Promise.reject("Please enter a valid email.");
    } else if (
      value.split("@")[0].includes("..") ||
      value.split("@")[0].includes("--")
    ) {
      return Promise.reject("Please enter a valid email.");
    } else if (
      value.split("@")[0].endsWith(".") ||
      value.split("@")[0].endsWith("-")
    ) {
      return Promise.reject("Please enter a valid email.");
    } else if (value.trim().length < 8) {
      return Promise.reject("Email should be minimum of 8 characters.");
    } else if (value.trim().length > 50) {
      return Promise.reject("Email should be maximum of 50 characters.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
};

export const firstNameValidator = (rule: any, val: string) => {
  const value = val?.trim();
  if (value && value?.length) {
    if (value.length < 1) {
      return Promise.reject("First Name should be minimum of 1 character.");
    } else if (value.length > 30) {
      return Promise.reject("First Name should be maximum of 30 characters.");
    } else if (value.match(/^.*\s{2,}.*$/)) {
      return Promise.reject("Maximum one space is allowed between words.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter First Name!");
  }
};

export const lastNameValidator = (rule: any, val: string) => {
  const value = val?.trim();
  if (value && value.length) {
    if (value.length < 1) {
      return Promise.reject("Last Name should be minimum of 1 character.");
    } else if (value.length > 30) {
      return Promise.reject("Last Name should be maximum of 30 characters.");
    } else if (value.match(/^.*\s{2,}.*$/)) {
      return Promise.reject("Maximum one space is allowed between words.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter Last Name!");
  }
};

//Campus CRUD Validators
export const campusNameValidator = (rule: any, value: string) => {
  if (value && value.length) {
    if (value.length < 1) {
      return Promise.reject("Campus Name should be minimum of 1 character.");
    } else if (value.length > 30) {
      return Promise.reject("Campus Name should be maximum of 30 characters.");
    } else if (value.match(/^.*\s{2,}.*$/)) {
      return Promise.reject("Maximum one space is allowed.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter Campus Name.");
  }
};

export const campusLocationNameValidator = (rule: any, value: string) => {
  if (value && value.length) {
    if (value.trim().length < 1) {
      return Promise.reject("Location should be minimum of 1 character.");
    } else if (value.trim().length > 100) {
      return Promise.reject("Location should be maximum of 100 characters.");
    } else if (value.match(/^.*\s{2,}.*$/)) {
      return Promise.reject("Maximum one space is allowed.");
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.reject("Please enter the Location.");
  }
};
