/* eslint-disable react-hooks/exhaustive-deps*/
import { Helmet } from "react-helmet";
import {
  ALL_CAMPUS_ID,
  DECON_ASSET,
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  RETIRED_ASSET,
  SOILED_ASSET,
} from "../../../../common/shared-constants";
import { ExclamationCircleOutlined, BarcodeOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { IRootState } from "../../../../common/redux/reducers";
import { useEffect, useState } from "react";
import { clearMessage } from "../../../../common/actions/common-actions";
import { fetchAllCampuses } from "../../campus/actions";
import TableComp from "../AIMDashboard/components/TableComp/TableComp";
import { clearAll, fetchDeconData, setFilterData } from "./actions";
import { CLEAR_ALL_MESSAGES } from "./action-types";
import useScanner from "../hooks/useScanner";
import { message, Modal, notification } from "antd";
import { adminRootInstance } from "../../../../common/config/axios-config";
import classes from "./AssetDecon.module.scss";
import { validateIncorrectValue } from "../utils/utils";
import { dateRenderer, nullRenderer } from "../AIMReports/utils/Renderer";

const localFilterDataKey = "aimAssetDeconFilter";
function Decon({
  fetchDeconData,
  assets,
  assetsLoading,
  errorMessage,
  clearMessage,
  setFilterData = () => {},
  filterData,
  fetchAllCampuses,
  allCampuses,
  campusLoading,
  clearAll,
}: any) {
  const barcodeRead = useScanner();
  const [loaders, setLoaders] = useState({ okayButton: false });
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    const timeout = errorMsg && setTimeout(() => setErrorMsg(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [errorMsg]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const flow = async ({ controlNumber, override = false }: any) => {
    return await adminRootInstance
      .put(`aim/decon/${controlNumber}`, {
        override,
      })
      .then((res: any) => {
        return res.data;
      })
      .catch((err: any) => {
        let statusCode = err.response.status;
        if ([406].includes(statusCode)) {
          return { ...err.response.data, proceed: true };
        }
        setErrorMsg(err.response?.data?.message || "Something went wrong!");
      });
  };
  const afterScan = async (scannedCode: any) => {
    setOperation({
      ...tableOpt,
      search: scannedCode,
      page: DEFAULT_PAGE,
    });
    document.getElementById("hiddenInput")?.focus();
    if (scannedCode) {
      notification.info({
        message: (
          <span className={classes.notification}>Data read: {scannedCode}</span>
        ),
        placement: "bottomRight",
        duration: 2,
        icon: <BarcodeOutlined />,
        style: { width: "max-content" },
        onClose: () => document.getElementById("hiddenInput")?.focus(),
      });

      //First api without override is hit
      const response: any = await flow({ controlNumber: scannedCode });
      if (!response) {
        return onSearch("");
      }
      if (response.status === "success") {
        //if successfull - show message
        setErrorMsg("");
        message.success(
          `Asset moved to ${response.data.status} status sucessfully.`
        );
        setFilterData(filterInitialValues);
        localStorage.setItem(
          localFilterDataKey,
          JSON.stringify({ ...filterInitialValues })
        );
        onSearch(scannedCode);
      } else if (response.proceed && response.status === "error") {
        //if error
        if (!response.currentStatus) {
          return setErrorMsg(response.message);
        }
        //if already in soiled status
        if (response.currentStatus === SOILED_ASSET) {
          setFilterData(filterInitialValues);
          localStorage.setItem(
            localFilterDataKey,
            JSON.stringify({ ...filterInitialValues })
          );
          onSearch(scannedCode);
          setErrorMsg(`Asset already moved to ${SOILED_ASSET} status!`);
          return;
        }

        //If status is other than SOILED or DECON
        if (!modalConfirm) {
          //to prevent simultaneous popup of modal
          setModalConfirm(true);
          Modal.confirm({
            title: `Move asset to ${DECON_ASSET} status`,
            icon: (
              <ExclamationCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.85)" }}
              />
            ),
            className: "sharpDelayModal",
            width: "max-content",
            content: (
              <span>
                <b>{scannedCode}</b> is in <b>{response.currentStatus}</b>{" "}
                status. <br />
                Do you want to move it to <b>{DECON_ASSET}</b> status?
              </span>
            ),
            okText: "Move it",
            cancelText: "Don't move",
            okButtonProps: {
              loading: loaders.okayButton,
              className: "nextBtn",
              onFocus: (e: any) => e.target.blur(),
            },
            cancelButtonProps: {
              className: "backBtn",
              onFocus: (e: any) => e.target.blur(),
            },
            afterClose: () => {
              setModalConfirm(false);
              document.getElementById("hiddenInput")?.focus();
            },
            onOk: async () => {
              setLoaders((loaders: any) => ({
                ...loaders,
                okayButton: true,
              }));
              const response: any = await flow({
                controlNumber: scannedCode,
                override: true,
              });
              if (response.status === "success") {
                setErrorMsg("");
                setFilterData(filterInitialValues);
                localStorage.setItem(
                  localFilterDataKey,
                  JSON.stringify({ ...filterInitialValues })
                );
                onSearch(scannedCode);
                message.success("Asset moved successfully!!");
              } else setErrorMsg(response.message);
              setLoaders((loaders: any) => ({
                ...loaders,
                okayButton: false,
              }));
            },
            onCancel: () => {
              onSearch("");
              setErrorMsg("");
            },
          });
        }
      }
    }
  };

  const manualScanInput = (e: any) => {
    if (
      (e.keyCode === 10 || e.keyCode === 13 || e.key === "Enter") &&
      (e.ctrlKey || e.metaKey)
    ) {
      afterScan(search);
    }
  };
  useEffect(() => {
    !modalConfirm && afterScan(barcodeRead);
  }, [barcodeRead]);

  //config for Filter options
  const campusFilterKey = "campus";
  const filterConfig = [
    {
      itemLabel: "Campus",
      itemName: campusFilterKey,
      itemProps: {},
      fieldPlaceholder: "Select Campus",
      notFoundDesc: "No Campuses",
      loading: campusLoading,
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: allCampuses,
    },
    {
      itemLabel: "Check-in",
      itemName: "checkin",
      type: "daterange",
      itemProps: {},
      fieldPlaceholder: "Select Date",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
    },
    {
      itemLabel: "Decon Start",
      itemName: "decon",
      type: "daterange",
      itemProps: {},
      fieldPlaceholder: "Select Date",
      fieldDisabled: () => {},
      fieldOnChange: () => {},
    },
  ];
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: ["30", "50", "100", "120"],
    showSizeChanger: true,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const { search, limit, page } = tableOpt;
  let filterInitialValues = {
    campus: ALL_CAMPUS_ID,
    checkin: [],
    decon: [],
  };
  useEffect(() => {
    const localStore = localStorage.getItem(localFilterDataKey);
    let filterValues = localStore
      ? JSON.parse(localStore)
      : filterInitialValues;
    (async () => {
      //check if applied campus filter is a valid campus
      const campuses = await fetchAllCampuses();
      const validCampus = validateIncorrectValue({
        allData: campuses,
        key: campusFilterKey,
        currentFilter: filterValues,
      });
      let rectifiedFilter = {
        ...filterValues,
      };
      if (!validCampus) {
        !validCampus && (rectifiedFilter[campusFilterKey] = ALL_CAMPUS_ID);
        localStorage.setItem(
          localFilterDataKey,
          JSON.stringify(rectifiedFilter)
        );
      }
      setFilterData(rectifiedFilter);
      fetchDeconData({ ...tableOpt, filter: rectifiedFilter });
    })();
    return () => {
      clearAll();
    };
  }, []);
  //Table Columns
  const commonObj = {
    align: "center",
    ellipsis: true,
  };
  const columns: any = [
    {
      ...commonObj,
      title: "Equipment Name",
      dataIndex: "equipmentName",
      key: "equipmentName",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Control number",
      dataIndex: "controlNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Serial number",
      dataIndex: "serialNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Status",
      dataIndex: "status",
      width: 40,
      render: (value: any, record: any) =>
        record.deleted ? RETIRED_ASSET : value || "-", //Check if deleted flag is there & show status
    },
    {
      ...commonObj,
      title: "Check In",
      dataIndex: "checkInTime",
      width: 40,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Check In by",
      dataIndex: "checkInBy",
      width: 60,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Decon Start",
      dataIndex: "deconStart",
      width: 40,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Decon Start by",
      dataIndex: "deconStartedBy",
      width: 50,
      render: nullRenderer,
    },
  ];
  const onFilterSubmit = (filterValues: any) => {
    setErrorMsg("");
    setFilterData(filterValues);
    setOperation({
      ...tableOpt,
      page: DEFAULT_PAGE,
    });
    fetchDeconData({
      filter: filterValues,
      search,
      limit,
      page: DEFAULT_PAGE,
    });
    setFilterData(filterValues);
    localStorage.setItem(
      localFilterDataKey,
      JSON.stringify({ ...filterValues })
    );
  };
  const onSearch = (searchTerm: string) => {
    // setErrorMsg("");
    fetchDeconData({
      filter: filterData,
      search: searchTerm.trim(),
      limit,
      page,
    });
    setOperation({
      ...tableOpt,
      search: searchTerm,
      page: DEFAULT_PAGE,
    });
  };
  const onFilterReset = () => {
    setErrorMsg("");
    setOperation({
      ...tableOpt,
      page: DEFAULT_PAGE,
    });
    fetchDeconData({
      filter: filterInitialValues,
      search,
      limit,
      page: DEFAULT_PAGE,
    });
    localStorage.removeItem(localFilterDataKey);
    setFilterData(filterInitialValues);
  };
  return (
    <>
      {/* Hidden input field for barcode focussing purpose */}
      <input
        id="hiddenInput"
        autoFocus
        autoComplete="false"
        className={classes.hiddenInput}
      />
      <Helmet>
        <title>Asset Decon | AIM</title>
      </Helmet>
      <div className="deconTable">
        <TableComp
          title="Asset Decon"
          data={assets.assets}
          dataPagination={assets.pagination}
          onSearch={onSearch}
          onFilterSubmit={onFilterSubmit}
          onFilterReset={onFilterReset}
          columns={columns}
          errorMessage={errorMessage || errorMsg}
          loading={assetsLoading}
          clearMessages={() => {
            clearMessage(CLEAR_ALL_MESSAGES);
            setErrorMsg("");
            document.getElementById("hiddenInput")?.focus();
          }}
          filterData={filterData}
          filterConfig={filterConfig}
          tableConfig={tableOpt}
          changeTableConfig={(tableConf: any) => {
            setOperation(tableConf);
            fetchDeconData({ filter: filterData, search, ...tableConf });
            document.getElementById("hiddenInput")?.focus();
          }}
          filterInitialValues={filterInitialValues}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          allCampuses={allCampuses}
          scannedAsset={barcodeRead}
          onSearchKeyPress={manualScanInput} //afterScan(barcodeRead);
        />
      </div>
    </>
  );
}

const mapStateToProps = ({
  aimAssetDeconState,
  campusManagement,
  itemManagement,
}: IRootState) => ({
  assets: aimAssetDeconState.assets,
  assetsLoading: aimAssetDeconState.assetsLoading,
  errorMessage: aimAssetDeconState.errorMessage,
  filterData: aimAssetDeconState.filterData,
  allCampuses: campusManagement.allCampuses,
  campusLoading: campusManagement.loading,
});
const mapDispatchToProps = {
  clearMessage,
  setFilterData,
  fetchAllCampuses,
  fetchDeconData,
  clearAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(Decon);
