import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../../common/redux/reducers";
import ReportsTable from "../../../reports/report-types/commons/report-table";
import { fetchDeconReport } from "../actions";
import { dateRenderer, nullRenderer } from "../utils/Renderer";

const commonObj = {
  align: "center",
  ellipsis: true,
};
export const decontablecolumns: any = [
  {
    ...commonObj,
    title: "Equipment Name",
    dataIndex: "equipmentName",
    key: "equipmentName",
    width: 65,
    render: (value: any, record: any, index: any, isCsv: boolean) =>
      nullRenderer(value, record, index, isCsv || false, true),
  },
  {
    ...commonObj,
    title: "Control number",
    dataIndex: "controlNumber",
    width: 65,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Serial number",
    dataIndex: "serialnumber",
    width: 65,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Specialty",
    dataIndex: "specialty",
    width: 40,
    render: (isSpecialty: any) => (isSpecialty ? "Yes" : "No"),
  },
  {
    ...commonObj,
    title: "Rental",
    dataIndex: "rental",
    width: 40,
    render: (isRental: any) => (isRental ? "Yes" : "No"),
  },
  {
    ...commonObj,
    title: "Last Location",
    dataIndex: "lastLocation",
    width: 60,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Last Order",
    dataIndex: "lastOrderNumber",
    width: 60,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Delivered by",
    dataIndex: "deliveredBy",
    width: 60,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Check in",
    dataIndex: "checkIn",
    width: 60,
    render: dateRenderer,
  },
  {
    ...commonObj,
    title: "Check in by",
    dataIndex: "soiledBy",
    width: 70,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Pickup ready",
    dataIndex: "pickupReady",
    width: 60,
    render: dateRenderer,
  },
  {
    ...commonObj,
    title: "Pickup ready by",
    dataIndex: "pickupReadyBy",
    width: 60,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Clean start",
    dataIndex: "cleanStart",
    width: 60,
    render: dateRenderer,
  },
  {
    ...commonObj,
    title: "Clean start by",
    dataIndex: "lastCleanedBy",
    width: 70,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Clean end",
    dataIndex: "cleanEnd",
    width: 60,
    render: dateRenderer,
  },
  {
    ...commonObj,
    title: "Clean end by",
    dataIndex: "deconEndedBy",
    width: 60,
    render: nullRenderer,
  },
  {
    ...commonObj,
    title: "Checkout",
    dataIndex: "checkOut",
    width: 60,
    render: dateRenderer,
  },
  {
    ...commonObj,
    title: "Repaired on",
    dataIndex: "repairedAt",
    width: 60,
    render: dateRenderer,
  },
  {
    ...commonObj,
    title: "Made available",
    dataIndex: "availableAt",
    width: 60,
    render: dateRenderer,
  },
  {
    ...commonObj,
    title: "Notes",
    dataIndex: "notes",
    width: 200,
    ellipsis: false,
    render: nullRenderer,
  },
];
const CheckinTableView = ({
  reportData,
  reportLoading,
  filterData,
  fetchDeconReport,
}: any) => {
  const columns: any = decontablecolumns;

  return (
    <ReportsTable
      {...(!reportData?.data?.length && { scrollY: 0 })} //since the scroll props is messing with the nodata inside table
      scrollX={3400}
      loading={reportLoading}
      rowData={reportData?.data}
      columnData={columns}
      totalCount={reportData?.totalCount}
      fetchData={(pagination: any) => {
        fetchDeconReport({
          ...pagination,
          ...filterData,
        });
      }}
    />
  );
};
const mapStateToProps = ({ aimReportState }: IRootState) => ({
  reportLoading: aimReportState.loading,
  successMsg: aimReportState.successMessage,
  reportData: aimReportState.reportData,
  filterData: aimReportState.filterData,
});
const mapDispatchToProps = {
  fetchDeconReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckinTableView);
