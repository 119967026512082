import {
  EQUIPMENT_CATALOGUE_ID,
  SHARPS_CATALOGUE_ID,
} from "../shared-constants";

export function getCatalogueIdByRequestType(reqTypeId: any) {
  if (reqTypeId) {
    if (reqTypeId === 1 || reqTypeId === 2) {
      return EQUIPMENT_CATALOGUE_ID;
    } else if (reqTypeId === 3) {
      return SHARPS_CATALOGUE_ID;
    }
  } else {
    return 0;
  }
}
