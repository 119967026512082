/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  Alert,
  Spin,
  Checkbox,
  Switch,
  Select,
} from "antd";
import classes from "./sla-modal.module.scss";
import "../../../shared/scss/shared-modal.scss";
import { CloseOutlined } from "@ant-design/icons";
import { EDIT } from "../../../../common/shared-constants";
import { trimValues } from "../../../../common/utils/trimUtil";
import ColorPicker from "./colorpicker";
import { selectAllHandler } from "../../reports/report-types/commons/utils";
const { Option } = Select;
const formItemWidthProp = {
  wrapperCol: { span: 14 },
  labelCol: { span: 10 },
};
const SLAModal = ({
  sla,
  mode,
  visible,
  handleCancel,
  handleSubmit,
  errorMsg,
  clearMessages,
  loading,
  requests,
  allCampuses,
}: any) => {
  const [submitted, setSubmitted] = useState(false);
  const [disclaimer, setDisclaimer] = useState<boolean>(
    sla?.disclaimerStatus ? sla?.disclaimerStatus : false
  );

  useEffect(() => {
    clearMessages();
  }, []);
  const [form] = Form.useForm();

  const handleDisclaimer = (value: any) => {
    setDisclaimer(value);
    if (!disclaimer) {
      form.setFieldsValue({ disclaimerInfo: "" });
    }
  };

  const onFormSubmit = async (values: any) => {
    if (submitted) {
      return;
    }
    setSubmitted(true);
    let formValues;
    formValues =
      values.disclaimerStatus === false
        ? { ...values, disclaimerInfo: "" }
        : { ...values };
    const trimedValues = trimValues(formValues);
    await handleSubmit(trimedValues);
    setSubmitted(false);
  };

  const multiLineLabel = (text: string) => (
    <span
      style={{
        whiteSpace: "normal",
      }}
    >
      {text}
    </span>
  );
  return (
    <React.Fragment>
      <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> {mode === EDIT ? "Edit SLA" : "Create SLA"}</p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={visible}
        width={800}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        {errorMsg ? (
          <Alert
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
            className={`fadeIn ${classes.errorContainer}`}
          />
        ) : null}
        <Form
          layout="horizontal"
          initialValues={sla}
          form={form}
          onFinish={onFormSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Form.Item name="id" hidden />
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="name"
                label={multiLineLabel("SLA Name")}
                {...formItemWidthProp}
                labelAlign="left"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (value && value.length) {
                        if (
                          !value.match(
                            /^[a-zA-Z0-9]+([a-zA-Z0-9_ $@/&.,:)(%#-])*$/
                          )
                        ) {
                          return Promise.reject("Please enter a valid Name.");
                        } else if (value.length < 1) {
                          return Promise.reject(
                            "SLA Name should be minimum of 1 character."
                          );
                        } else if (value.length > 30) {
                          return Promise.reject(
                            "SLA Name should be maximum of 30 characters."
                          );
                        } else if (value.match(/^.*\s{2,}.*$/)) {
                          return Promise.reject(
                            "Maximum one space is allowed."
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Please enter SLA Name!");
                      }
                    },
                    required: true,
                  }),
                ]}
              >
                <Input
                  autoFocus
                  placeholder="Enter here"
                  className="inputStyle"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="campuses"
                label={multiLineLabel("Mapped Campus")}
                {...formItemWidthProp}
                labelAlign="left"
                rules={[
                  { required: true, message: "Please select the Campus!" },
                ]}
              >
                <Select
                  className={classes.campusSelect}
                  placeholder="Select campus"
                  mode="multiple"
                  showArrow
                  showSearch
                  onChange={(value: any) =>
                    selectAllHandler(form, value, "campuses", 1)
                  }
                  optionFilterProp="children"
                  filterSort={(optionA: any, optionB: any) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {allCampuses.map((campus: any) => (
                    <Option value={campus.id} title="">
                      {campus.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="timeLimit"
                style={{ height: "fit-content" }}
                dependencies={["threshold"]}
                label={multiLineLabel("Timelimit (in Mins)")}
                rules={[
                  () => ({
                    required: true,
                    validator: (rule: any, val: string) => {
                      let value = parseInt(val);
                      if (value) {
                        if (form.getFieldValue("threshold") >= value) {
                          return Promise.reject(
                            "Should be greater than Threshold"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Please Enter Timelimit");
                      }
                    },
                  }),
                ]}
                {...formItemWidthProp}
                labelAlign="left"
              >
                <Input
                  placeholder="Enter here"
                  className="inputStyle"
                  type="number"
                  min="1"
                />
              </Form.Item>
              <Form.Item
                name="threshold"
                dependencies={["timeLimit"]}
                style={{ height: "fit-content" }}
                label={multiLineLabel("Threshold (in Mins)")}
                rules={[
                  () => ({
                    required: true,
                    validator: (rule: any, val: string) => {
                      let value = parseInt(val);
                      if (value) {
                        if (form.getFieldValue("timeLimit") <= value) {
                          return Promise.reject(
                            "Should be lesser than Timelimit"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject("Please Enter Threshold");
                      }
                    },
                  }),
                ]}
                {...formItemWidthProp}
                labelAlign="left"
              >
                <Input
                  placeholder="Enter here"
                  className="inputStyle"
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="requests"
                label="Requests"
                rules={[
                  () => ({
                    required: true,
                    message: "Please select the request type",
                  }),
                ]}
                {...formItemWidthProp}
                labelAlign="left"
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  {requests.map((request: any) => (
                    <div>
                      <Checkbox value={request.id}>{request.name}</Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="color"
                label="Color"
                rules={[
                  () => ({
                    required: true,
                    message: "Please select the color",
                  }),
                ]}
                {...formItemWidthProp}
                labelAlign="left"
                valuePropName="color"
              >
                <ColorPicker />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="disclaimerStatus"
                label="Disclaimer"
                valuePropName="checked"
                initialValue={false}
                {...formItemWidthProp}
                labelAlign="left"
                className={classes.nonRequired}
              >
                <Switch onChange={handleDisclaimer} />
              </Form.Item>
            </Col>
          </Row>
          {disclaimer && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="fadeIn">
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="disclaimerInfo"
                  label="Disclaimer description"
                  rules={[
                    {
                      validator(rule, val) {
                        let value = val.trim();
                        if (value.trim() && value.trim().length) {
                          if (value.trim().length < 1) {
                            return Promise.reject(
                              "Description should be minimum of 1 character."
                            );
                          } else if (value.trim().length > 200) {
                            return Promise.reject(
                              "Description should be maximum of 200 characters."
                            );
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.reject(
                            "Please enter the Description!"
                          );
                        }
                      },
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter here"
                    defaultValue=""
                    className="inputStyle"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <div className="modalBtn">
            <Form.Item style={{ marginRight: "3%" }}>
              <Button
                className="cancelBtn"
                htmlType="reset"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Spin spinning={loading}>
                <Button className="submitBtn" htmlType="submit">
                  Submit
                </Button>
              </Spin>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default SLAModal;
