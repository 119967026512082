/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  Alert,
  Spin,
  Switch,
  Upload,
  Radio,
  Select,
  Divider,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "./item-modal.module.scss";
import "../../../shared/scss/shared-modal.scss";
import { CloseOutlined } from "@ant-design/icons";
import {
  ALL_CAMPUS_ID,
  ALL_UNIT_ID,
  CREATE,
  EDIT,
  SHARPS_CATALOGUE_ID,
} from "../../../../common/shared-constants";
import { trimValues } from "../../../../common/utils/trimUtil";
import { adminRootInstance } from "../../../../common/config/axios-config";
import { compressImage } from "../../../../common/utils/image-compressor";
const { Option } = Select;
const ItemModal = ({
  item,
  mode,
  visible,
  handleCancel,
  handleSubmit,
  errorMsg,
  clearMessages,
  loading,
  categories,
  allUnits,
  itemSize,
  catalogues,
  setLoading,
  allCampuses,
}: any) => {
  const [submitted, setSubmitted] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [form] = Form.useForm();
  const [rentalDisabled, setRentalDisabled] = useState(true);
  const [inventoryDisabled, setInventoryDisabled] = useState(false);
  useEffect(() => {
    clearMessages();
    if (item?.image) {
      setFileList([{ url: item.image }]);
    }
    if (mode === EDIT) {
      setInventoryDisabled(parseInt(item.catalogueId) === SHARPS_CATALOGUE_ID);
      if (item.campusIds.includes(ALL_CAMPUS_ID)) {
        setUnits(allUnits);
      } else {
        const filteredUnit: any = [];
        item.campusIds.forEach((campusId: any) => {
          const filterData = allUnits?.filter((unit: any) => {
            if (unit.id === ALL_UNIT_ID) {
              const found = filteredUnit.findIndex(
                (unit: any) => unit.id === ALL_UNIT_ID
              );
              if (found === -1) {
                return true;
              }
            } else if (unit.campusId === campusId) {
              return true;
            }
            return false;
          });
          filteredUnit.push(...filterData);
        });
        setUnits(filteredUnit);
      }
    }
  }, []);
  const [specialityDisabled, setSpecialityDisabled] = useState(
    item?.catalogueId === SHARPS_CATALOGUE_ID
  );
  const [imageErrorMsg, setImageErrorMsg] = useState<any>("");
  const [uploadAPIErrorMessage, setUploadAPIErrorMessage] = useState<any>();
  const [previewData, setPreviewData] = useState<any>({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });

  const initialFormValues = {
    name: "",
    image: "",
    campusIds: [],
    unitIds: [],
    categoryIds: [],
    catalogueId: null,
    sizeId: null,
    specialtyItem: false,
    delay: false,
    delayDescription: "",
    assetTracking: true,
    rental: false,
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreviewCancel = () => setPreviewData({ previewVisible: false });
  const [isDelayDescription, setIsDelayDescription]: any = useState(
    item?.notify
  );

  const handleDelay = (checked: any) => {};

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewData({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  const onFormSubmit = async (values: any) => {
    if (submitted || imageErrorMsg.length) {
      return;
    }
    setSubmitted(true);
    let trimedValues = trimValues(values);
    if (fileList[0]?.originFileObj) {
      setLoading(true);
      const res: any = await imageUpload();
      if (res.status === "success") {
        trimedValues = { ...trimedValues, image: res.image };
      } else {
        setLoading(false);
        setSubmitted(false);
        return;
      }
    } else {
      delete trimedValues.image;
    }
    if (values.catalogueId === SHARPS_CATALOGUE_ID) {
      trimedValues = { ...trimedValues, specialtyItem: false };
    }
    await handleSubmit(trimedValues);
    setSubmitted(false);
  };

  const { previewVisible, previewImage } = previewData;

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleUpload = ({ file, fileList }: any) => {
    setFileList([...fileList]);
    if (fileList.length === 0) {
      setImageErrorMsg("Please Upload the Image!");
    }
  };

  const handleBeforeUpload = (file: any) => {
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      setImageErrorMsg("Unsupported format, Please choose jpeg/png");
      return Upload.LIST_IGNORE;
    } else if (file.size > 10485760) {
      setImageErrorMsg("Unsupported Size, Please choose file below 10MB");
      return Upload.LIST_IGNORE;
    } else {
      setImageErrorMsg("");
      return true;
    }
  };

  const imageUpload = async () => {
    let data = new FormData();
    let compressedFile = await compressImage(fileList[0]?.originFileObj);
    data.append("image", compressedFile);
    return adminRootInstance
      .post("image", data, { params: { mode: "item" } })
      .then((res) => {
        return { status: "success", image: res.data.data };
      })
      .catch((err) => {
        setUploadAPIErrorMessage(err.ressponse?.data?.message);
        return { status: "error" };
      });
  };

  const onUnitChange = (ids: any) => {
    if (ids.includes(1)) {
      form.setFieldsValue({ unitIds: [1] });
    } else {
      form.setFieldsValue({ unitIds: ids });
    }
  };

  const [units, setUnits] = useState<any>([]);
  const getRetainedUnits = (selectedCampuses: Array<number>) => {
    const exisitingUnit = form.getFieldsValue().unitIds;
    const exisitingUnitData = exisitingUnit.reduce(
      (acc: Array<any>, unitId: number) => {
        const result = allUnits.filter((unit: any) => unitId === unit.id);
        return result ? acc.concat(result) : acc;
      },
      []
    );
    const retainableUnits = selectedCampuses.reduce(
      (acc: any, campusId: number) => {
        let idsList: any = [];
        for (let index = 0; index < exisitingUnitData.length; index++) {
          let unitData = exisitingUnitData[index];
          unitData.campusId === campusId && idsList.push(unitData.id);
        }
        return acc.concat(idsList);
      },
      []
    );
    return retainableUnits;
  };
  const onCampusChange = (ids: any) => {
    if (ids.includes(ALL_CAMPUS_ID)) {
      form.setFieldsValue({
        campusIds: [ALL_CAMPUS_ID],
        unitIds: getRetainedUnits([ALL_CAMPUS_ID]),
      });
      setUnits(allUnits);
    } else {
      const filteredUnit: any = [];
      ids.forEach((campusId: any) => {
        const filterData = allUnits?.filter((unit: any) => {
          if (unit.id === ALL_UNIT_ID) {
            const found = filteredUnit.findIndex(
              (unit: any) => unit.id === ALL_UNIT_ID
            );
            if (found === -1) {
              return true;
            }
          } else if (unit.campusId === campusId) {
            return true;
          }
          return false;
        });
        filteredUnit.push(...filterData);
      });
      form.setFieldsValue({ campusIds: ids, unitIds: getRetainedUnits(ids) });
      setUnits(filteredUnit);
    }
  };

  const onCatalogueChange = (id: any) => {
    const formValues = form.getFieldsValue();
    if (id === SHARPS_CATALOGUE_ID) {
      setSpecialityDisabled(true);
      setInventoryDisabled(true);
      setRentalDisabled(true);
      form.setFieldsValue({
        catalogueId: id,
        specialtyItem: false,
        categoryIds: [],
        assetTracking: false,
        rental: false,
      });
    } else {
      setInventoryDisabled(false);
      setSpecialityDisabled(false);
      form.setFieldsValue({
        catalogueId: id,
        categoryIds:
          mode === CREATE ? formValues.categoryIds : item?.categoryIds || [],
      });
    }
  };

  // Code that handles custom Dropdown to add new Item sizes & select them
  const [itemSizeList, setItemSizeList] = useState<any>(itemSize);
  const [newSize, setNewSize] = useState<string>("");
  const [validationMsg, setValidationMsg] = useState("");
  const onAddSize = () => {
    let existingSize = itemSizeList.find(
      (itemSize: any) => itemSize.name === newSize
    );
    if (!existingSize) {
      if (newSize) {
        setItemSizeList(
          itemSizeList.concat({
            name: newSize,
          })
        );
        setNewSize("");
        form.setFieldsValue({
          ...form.getFieldsValue(),
          sizeId: newSize,
        });
      }
    } else {
      message.info("Selected the existing size");
      setNewSize("");
      form.setFieldsValue({
        ...form.getFieldsValue(),
        sizeId: existingSize.id || existingSize.name,
      });
    }
    document.getElementById("sizeIdSelect")?.blur();
  };
  const sizeEnterValidator = (value: any) => {
    const x = value;
    if (x.trim() === null || x.trim() === "" || x === "") {
      setValidationMsg("Empty name is not accepted");
      return false;
    } else {
      setValidationMsg("");
      return true;
    }
  };
  const sizeChangeValidator = (value: any) => {
    setValidationMsg("");
    if (value && value.length) {
      if (!value.trim().match(/^[a-zA-Z0-9]+([a-zA-Z0-9_ $@/&.,:)(%#-])*$/)) {
        setValidationMsg("Please enter a valid size.");
        return false;
      } else if (value.length < 1) {
        setValidationMsg("Item size should be minimum of 1 character.");
        return false;
      } else if (value.length > 20) {
        setValidationMsg("Item size should be maximum of 20 characters.");
        return false;
      } else {
        return true;
      }
    } else {
      setValidationMsg("");
      return true;
    }
  };
  const dropDownComponent = (menu: any) => {
    return (
      <div className={classes.itemSizeDropdown}>
        {menu}
        <Divider className={classes.divider} />
        <div className={classes.newItemSize}>
          <Input
            id="addSizeInput"
            className={classes.addSizeInput}
            autoComplete={"off"}
            value={newSize}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                e.stopPropagation();
                if (
                  sizeEnterValidator(newSize) &&
                  sizeChangeValidator(newSize)
                ) {
                  onAddSize();
                  document.getElementById("addSizeInput")?.blur();
                  document.getElementById("sizeIdSelect")?.blur();
                } else {
                  setNewSize("");
                }
              }
            }}
            onChange={(e: any) => {
              setNewSize(e.target.value);
              sizeChangeValidator(e.target.value);
            }}
          />
          <button
            type="button"
            className={`linkLikeText ${classes.addSizeButton}`}
            onClick={() => {
              if (sizeEnterValidator(newSize) && sizeChangeValidator(newSize)) {
                onAddSize();
              }
            }}
            disabled={validationMsg ? true : false}
          >
            <PlusOutlined /> Add Size
          </button>
        </div>
        {validationMsg && (
          <div
            style={{ margin: "0 10px" }}
            className="ant-form-item-explain ant-form-item-explain-error"
          >
            <div role="alert">{validationMsg}</div>
          </div>
        )}
      </div>
    );
  };
  //============================================
  return (
    <React.Fragment>
      <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> {mode === EDIT ? "Edit Item" : "Create Item"} </p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={visible}
        width={900}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        {errorMsg ? (
          <Alert
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
            className={`fadeIn ${classes.errorContainer}`}
          />
        ) : null}
        {uploadAPIErrorMessage ? (
          <Alert
            message={uploadAPIErrorMessage}
            type="error"
            closable
            afterClose={() => setUploadAPIErrorMessage("")}
            className={`fadeIn ${classes.errorContainer}`}
          />
        ) : null}
        <Form
          form={form}
          layout="horizontal"
          initialValues={
            item
              ? { ...item, ...(!item.campusIds.length && { unitIds: [] }) }
              : initialFormValues
          }
          onFinishFailed={() => {
            if (fileList.length === 0)
              setImageErrorMsg("Please Upload the Image!");
          }}
          onFinish={onFormSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Form.Item name="id" hidden />
            <Col className="gutter-row" span={12}>
              <Row style={{ height: "100%" }}>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Item Name"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (value && value.length) {
                            if (
                              !value.match(
                                /^[a-zA-Z0-9]+([a-zA-Z0-9_ $@/&.,:)(%#-])*$/
                              )
                            ) {
                              return Promise.reject(
                                "Please enter a valid Name."
                              );
                            } else if (value.length < 1) {
                              return Promise.reject(
                                "Item Name should be minimum of 1 character."
                              );
                            } else if (value.length > 30) {
                              return Promise.reject(
                                "Item Name should be maximum of 30 characters."
                              );
                            } else if (value.match(/^.*\s{2,}.*$/)) {
                              return Promise.reject(
                                "Maximum one space is allowed."
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.reject("Please enter Item Name!");
                          }
                        },
                        required: true,
                      }),
                    ]}
                  >
                    <Input
                      autoFocus={mode !== EDIT}
                      placeholder="Enter here"
                      className="inputStyle"
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  // style={{ display: "flex", alignItems: "self-end" }}
                >
                  <Form.Item
                    name="campusIds"
                    label="Mapped Campus"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[
                      () => ({
                        required: true,
                        message: "Please select the Campus!",
                      }),
                    ]}
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      showSearch
                      optionFilterProp="children"
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={(id) => onCampusChange(id)}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {allCampuses?.map((campus: any) => (
                        <Option value={campus.id}>{campus.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="image"
                label="Upload Image"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                validateStatus={imageErrorMsg?.length ? "error" : undefined}
                help={imageErrorMsg}
                className={`${classes.uploadImgErr} uploadImgErr`}
                rules={[
                  () => ({
                    required: false,
                    message: "Please Upload Image!",
                  }),
                ]}
              >
                <ImgCrop
                  minZoom={0.1}
                  cropperProps={{
                    restrictPosition: false,
                    objectFit: "contain",
                  }}
                >
                  <Upload
                    customRequest={dummyRequest}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    onPreview={handlePreview}
                    multiple={false}
                    maxCount={1}
                    fileList={fileList}
                    beforeUpload={handleBeforeUpload}
                    onChange={handleUpload}
                    accept=".jpg,.png,.jpeg"
                  >
                    {fileList.length === 0 ? uploadButton : null}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handlePreviewCancel}
                className="itemImagePreview"
                width={"max-content"}
              >
                <img src={previewImage} alt="" />
              </Modal>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}></Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="unitIds"
                label="Mapped Unit"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                rules={[
                  () => ({
                    required: true,
                    message: "Please select the Unit!",
                  }),
                ]}
              >
                <Select
                  mode="multiple"
                  showArrow
                  showSearch
                  optionFilterProp="children"
                  filterSort={(optionA: any, optionB: any) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(id) => onUnitChange(id)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {units?.map((unit: any) => (
                    <Option value={unit?.id}>{unit?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="catalogueId"
                label="Catalogue"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                rules={[
                  () => ({
                    required: true,
                    message: "Please select the Catalogue!",
                  }),
                ]}
              >
                <Select
                  className={classes.selectClass}
                  showSearch
                  disabled={mode === EDIT}
                  optionFilterProp="children"
                  filterSort={(optionA: any, optionB: any) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(id) => onCatalogueChange(id)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {catalogues?.map((catalogue: any) => (
                    <Option key={catalogue.id} value={catalogue.id}>
                      {catalogue.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="categoryIds"
                label="Mapped Category"
                className={specialityDisabled ? classes.nonRequired : ""}
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                rules={[
                  () => ({
                    required: !specialityDisabled,
                    message: "Please select the Category!",
                  }),
                ]}
              >
                <Select
                  mode="multiple"
                  showArrow
                  showSearch
                  disabled={specialityDisabled}
                  optionFilterProp="children"
                  filterSort={(optionA: any, optionB: any) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {categories?.map((category: any) => (
                    <Option value={category.id}>{category.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="sizeId"
                label="Item Size"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 8 }}
                labelAlign="left"
                rules={[
                  () => ({
                    required: true,
                    message: "Please select the Item Size!",
                  }),
                ]}
              >
                <Select
                  id="sizeIdSelect"
                  className={classes.selectClass}
                  showSearch
                  optionFilterProp="children"
                  filterSort={(optionA: any, optionB: any) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  dropdownRender={dropDownComponent}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {itemSizeList?.map((item: any) => (
                    <Option
                      key={item.id || item.name}
                      value={item.id || item.name}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {mode === CREATE && (
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="specialtyItem"
                  label="Specialty Item "
                  className={classes.nonRequired}
                  wrapperCol={{ span: 16 }}
                  labelCol={{ span: 10 }}
                  labelAlign="left"
                >
                  <Radio.Group
                    disabled={specialityDisabled}
                    onChange={(e: any) => {
                      setRentalDisabled(!e.target.value);
                      //if speciality is false --> set rental to false too
                      !e.target.value &&
                        form.setFieldsValue({
                          rental: false,
                        });
                    }}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
            {mode === CREATE && (
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="rental"
                  label="Rental"
                  initialValue={false}
                  wrapperCol={{ span: 16 }}
                  className={classes.nonRequired}
                  labelCol={{ span: 8 }}
                  labelAlign="left"
                >
                  <Radio.Group disabled={rentalDisabled}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}

            <Col span={12}>
              <Form.Item
                name="assetTracking"
                label="Add to Asset Inventory"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 10 }}
                className={classes.nonRequired}
                labelAlign="left"
              >
                <Radio.Group disabled={inventoryDisabled}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                name="delay"
                label="Delay"
                valuePropName="checked"
                wrapperCol={{ span: 16 }}
                className={classes.nonRequired}
                labelCol={{ span: 8 }}
                labelAlign="left"
              >
                <Switch
                  defaultChecked={false}
                  className={`delaySwitch`}
                  onChange={handleDelay}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                name="notify"
                label="Notification"
                valuePropName="checked"
                wrapperCol={{ span: 16 }}
                className={classes.nonRequired}
                labelCol={{ span: 13 }}
                labelAlign="left"
              >
                <Switch
                  defaultChecked={false}
                  onChange={(checked: any) => {
                    setIsDelayDescription(checked);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {isDelayDescription && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="fadeIn">
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="delayDescription"
                  label="Description"
                  wrapperCol={{ span: 20 }}
                  labelCol={{ span: 4 }}
                  labelAlign="left"
                  rules={[
                    {
                      validator(rule, val) {
                        let value = val.trim();
                        if (value && value.length) {
                          if (value.length < 1) {
                            return Promise.reject(
                              "Description should be minimum of 1 character."
                            );
                          } else if (value.length > 200) {
                            return Promise.reject(
                              "Description should be maximum of 200 characters."
                            );
                          } else if (value.match(/^.*\s{2,}.*$/)) {
                            return Promise.reject(
                              "Maximum one space is allowed."
                            );
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.reject(
                            "Please enter the Description!"
                          );
                        }
                      },
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <div className="modalBtn">
            <Form.Item style={{ marginRight: "3%" }}>
              <Button
                className="cancelBtn"
                htmlType="reset"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Spin spinning={loading}>
                <Button className="submitBtn" htmlType="submit">
                  Submit
                </Button>
              </Spin>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default ItemModal;
