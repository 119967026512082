/* eslint-disable react-hooks/exhaustive-deps*/
import { Modal, Form, Input, Button, Row, Col, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  PATIENTMODAL_SINGLE_NEW,
  PATIENTMODAL_SINGLE_REMOVE,
  PATIENTMODAL_VIEW,
} from "../../shared-constants";
import {
  firstNameValidator,
  lastNameValidator,
} from "../../utils/formValidators";
import classes from "./patient-details-modal.module.scss";
const commonColumnProps = {
  align: "center",
  ellipsis: {
    showTitle: false,
  },
  render: (name: string) => <Tooltip title={name}>{name}</Tooltip>,
};
interface PatientDetailsModalInterface {
  visible: boolean;
  toggleModal: Function;
  cart: any;
  setCart?: Function;
  selectedIds?: any;
  setSelectedIDs?: Function;
  itemId: number;
  newItem?: any;
  mode?:
    | typeof PATIENTMODAL_SINGLE_NEW
    | typeof PATIENTMODAL_SINGLE_REMOVE
    | "MULTI_NEW"
    | "MULTI_REMOVE"
    | typeof PATIENTMODAL_VIEW;
}
const PatientDetailsModal = ({
  visible,
  toggleModal,
  cart, //to remove the particular item form cart & to modify quanitity
  setCart = () => {}, //to remove the particular item form cart & to modify quanitity
  selectedIds = [], //Specifically for add item modal - to manage selection - if quantity below 1
  setSelectedIDs = () => {}, //Specifically for add item modal - to manage selection - if quantity below 1
  itemId,
  newItem,
  mode = PATIENTMODAL_SINGLE_NEW,
}: PatientDetailsModalInterface) => {
  const itemData = cart.find((item: any) => item.item.id === itemId);
  const [itemDetail, setItemDetail] = useState(
    newItem ? { item: { ...newItem }, quantity: 0 } : itemData
  );
  const [formSubmit, setFormSubmit] = useState<any>();
  useEffect(() => {
    setItemDetail(newItem ? { item: { ...newItem }, quantity: 0 } : itemData);
  }, [itemId]);

  useEffect(() => {
    if (formSubmit) {
      switch (mode) {
        case PATIENTMODAL_SINGLE_NEW:
          setCart([
            ...cart.map((item: any) => {
              if (item.item.id === itemDetail.item.id) return itemDetail;
              return item;
            }),
            ...(!cart.find((item: any) => item.item.id === itemDetail.item.id)
              ? [itemDetail]
              : []),
          ]);
          !selectedIds.includes(itemDetail.item.id) &&
            setSelectedIDs([...selectedIds, itemDetail.item.id]);
          break;
        case PATIENTMODAL_SINGLE_REMOVE:
          setCart(
            cart.map((item: any) => {
              if (item.item.id === itemDetail.item.id)
                return { ...itemDetail, quantity: itemDetail.quantity - 1 };
              return item;
            })
          );
          break;
        default:
          break;
      }
    }
    setFormSubmit(false);
  }, [formSubmit]);
  const getUI = (item: any, modalMode: any) => {
    switch (modalMode) {
      case PATIENTMODAL_SINGLE_NEW:
        return AddDetailsBlock(item, setItemDetail, toggleModal, setFormSubmit);
      case PATIENTMODAL_SINGLE_REMOVE:
        return (
          <RemoveMultipleDetails
            {...{ item, setItemDetail, toggleModal, setFormSubmit }}
          />
        );
      case PATIENTMODAL_VIEW:
        return PatientDetailsView(item?.patients, item?.item.name);
      default:
        break;
    }
  };
  return (
    <Modal
      title={`${
        mode === PATIENTMODAL_SINGLE_NEW || mode === "MULTI_NEW"
          ? "Add"
          : mode === PATIENTMODAL_VIEW
          ? "View"
          : "Remove"
      } Patient Details`}
      visible={visible}
      onCancel={() => {
        toggleModal();
      }}
      maskClosable={false}
      centered={true}
      footer={false}
      className={`sharpItemModal ${classes.patientDetailsModal}`}
    >
      {getUI(itemDetail, mode)}
    </Modal>
  );
};

export default PatientDetailsModal;

const AddDetailsBlock = (
  itemDetail: any,
  setItemDetails: Function,
  toggleModal: Function,
  setFormSubmit: Function
) => {
  if (!itemDetail) return;
  const { item: { name: itemName = "" } = {} } = itemDetail;
  return (
    <Form
      onFinish={(val: any) => {
        setItemDetails({
          ...itemDetail,
          quantity: itemDetail.quantity + 1,
          patients: [
            ...(itemDetail?.patients ? itemDetail.patients : []),
            { ...val },
          ],
        });
        setFormSubmit(true);
        toggleModal();
      }}
    >
      <Row>
        <Col>
          <p>
            Item name:&nbsp;<b>{itemName}</b>
          </p>
        </Col>
        <Col span={24}>
          <Form.Item
            name="firstName"
            rules={[
              () => ({
                validator: firstNameValidator,
                required: true,
              }),
            ]}
            label="First Name"
          >
            <Input
              className={classes.searchInput}
              onChange={() => {}}
              autoFocus
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              () => ({
                validator: lastNameValidator,
                required: true,
              }),
            ]}
            label="Last Name"
          >
            <Input className={classes.searchInput} onChange={() => {}} />
          </Form.Item>
        </Col>
      </Row>
      <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
        <Button type="primary" htmlType="submit" className="nextBtn">
          Submit
        </Button>
      </Col>
    </Form>
  );
};

interface RemoveMultipleDetailsInterface {
  item: any;
  setItemDetail: Function;
  toggleModal: Function;
  setFormSubmit: Function;
}
const RemoveMultipleDetails = (props: RemoveMultipleDetailsInterface) => {
  const {
    item: itemDetails,
    setItemDetail,
    toggleModal,
    setFormSubmit,
  } = props;
  const [selected, setSelected] = useState<any>([0]);
  let tableData = itemDetails?.patients?.map((patient: any, index: number) => ({
    ...patient,
    key: index,
  }));
  const columns: any = [
    {
      title: "First Name",
      dataIndex: "firstName",
      ...commonColumnProps,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      ...commonColumnProps,
    },
  ];
  const rowSelection = {
    type: "radio" as any,
    preserveSelectedRowKeys: true,
    selectedRowKeys: selected,
    onChange: (selectedRowIds: any, selectedRows: any) => {
      setSelected(selectedRowIds);
    },
  };
  const onFormSubmit = (val: any) => {
    if (tableData) {
      let modifiedPatientList = itemDetails.patients.filter(
        (item: any, index: number) => index !== selected[0]
      );
      setItemDetail({
        ...itemDetails,
        patients: modifiedPatientList,
      });
      setFormSubmit(true);
    }
    toggleModal();
  };
  return (
    <Row>
      Item Name: <b>&nbsp;{itemDetails?.item?.name}</b>
      <Form onFinish={onFormSubmit}>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Table
            rowSelection={{
              ...rowSelection,
            }}
            rowClassName={() => `${classes.cursorPointer} fadeIn`}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setSelected([rowIndex]);
                },
              };
            }}
            dataSource={tableData}
            className="sharpItemList"
            pagination={false}
            scroll={{
              y: "50vh",
            }}
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button type="primary" htmlType="submit" className="nextBtn">
            Remove
          </Button>
        </Col>
      </Form>
    </Row>
  );
};
const PatientDetailsView = (patients: any, itemName: string) => {
  const columns: any = [
    {
      title: "First Name",
      dataIndex: "firstName",
      ...commonColumnProps,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      ...commonColumnProps,
    },
  ];
  return (
    <Row>
      <div className={classes.itemName}>
        Item Name: &nbsp;<b>{itemName}</b>
      </div>
      <Table
        columns={columns}
        dataSource={patients || []}
        className="sharpItemList"
        pagination={false}
        scroll={{
          y: "50vh",
        }}
      />
    </Row>
  );
};
