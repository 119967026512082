import React from "react";
import { Form, Button, Select, DatePicker, Alert } from "antd";
import classes from "../AIMReports.module.scss";
import { capitalize } from "lodash";

const AIMReportFilter = (props: any) => {
  const { form, onFinish, initialValues, errorMessage, dataLoading, config } =
    props;
  return (
    <div className={classes.reportsTypeContainer}>
      <div className={classes.reportsTypeForm}>
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          initialValues={initialValues}
          onFinish={onFinish}
          labelAlign="left"
        >
          {config.map((items: any) => getComponent(items))}

          {errorMessage && (
            <div>
              <Alert message={errorMessage} type="error" closable />
            </div>
          )}
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "50px 0 0",
            }}
          >
            <Button
              type="primary"
              className="nextBtn"
              htmlType="submit"
              loading={dataLoading}
            >
              Generate Report
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const getComponent = (props: any) => {
  const dateFormat = "MM-DD-YYYY";
  const {
    name,
    onChange,
    // value,
    label,
    placeholder,
    type,
    multiple = false,
    dropDownList = [],
    disabledDate,
  } = props;
  switch (type) {
    case "date":
      return (
        <Form.Item
          key={type}
          label={label}
          name={name}
          rules={[
            {
              required: true,
              message: "Please select Date!",
            },
          ]}
        >
          <DatePicker
            getPopupContainer={(trigger): any => trigger.parentNode}
            allowClear={true}
            style={{ width: "100%" }}
            value={props.fromDate}
            format={dateFormat}
            placeholder="Select Date (e.g. 12-27-2020)"
            disabledDate={disabledDate}
            onChange={props.onChange}
          />
        </Form.Item>
      );
    default:
      return (
        <Form.Item
          key={type}
          label={label}
          name={name}
          rules={[{ required: true, message: `Please select ${label}!` }]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            placeholder={placeholder}
            optionFilterProp="children"
            mode={multiple ? "multiple" : undefined}
            onChange={onChange}
            showArrow
          >
            {dropDownList.map(({ id: listId, name: listName }: any) => {
              const idVal = listId;
              const nameVal =
                name === "status" ? capitalize(listName) : listName;
              return (
                <Select.Option key={idVal} value={idVal}>
                  {nameVal}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      );
  }
};

export default AIMReportFilter;
