import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import reducer, { IRootState } from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const defaultMiddlewares = [thunkMiddleware];

const composedMiddlewares = (middlewares: any) =>
  compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState?: IRootState, middlewares = []) =>
  createStore(
    reducer,
    initialState,
    process.env.NODE_ENV === "development"		//devtools visible only for development environment
      ? composeWithDevTools(composedMiddlewares(middlewares))
      : composedMiddlewares(middlewares)
  );

export default initialize;
