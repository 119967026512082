import React, { useState } from "react";
import { Form } from "antd";
import { connect } from "react-redux";
import { selectAllHandler } from "./utils";
import { IRootState } from "../../../../../common/redux/reducers";
import { setReportFilterData } from "../../actions";
import { FormViewComp } from "./common-filter-form";
import { SHARPS_REQUEST_TYPE_ID } from "../../../../../common/shared-constants";

const ReportsFilterForm = (props: any) => {
  const {
    submitHandler,
    reportsState: {
      filterData = {},
      allItems = [],
      allOrderTypes = [],
      allUnits = [],
    } = {},
    setReportFilterData,
    reportType,
    dataLoading,
    errorMessage,
  } = props;
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState<any>(filterData || {});

  const initialValues = filterData;

  const onFinish = (values: any) => {
    setReportFilterData(form.getFieldsValue());
    submitHandler(values);
  };

  const compProps = {
    form,
    onFinish,
    initialValues,
    errorMessage,
    dataLoading,
    config: [
      {
        name: "fromDate",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, fromDate: val });
        },
        value: selectedValues?.fromDate,
        selectedValues,
      },
      {
        name: "toDate",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, toDate: val });
        },
        value: selectedValues?.toDate,
        selectedValues,
      },
      ...(reportType !== "overall"
        ? [
            {
              name: "specialityItem",
              dropDownList: [{ id: "all", name: "All" }, ...allItems],
              onChange: (value: any) =>
                selectAllHandler(form, value, "specialityItem", "all"),
              radioValue: "detailed",
              multiSelect: true,
              excludeAllOption: true,
            },
            {
              name: "orderType",
              dropDownList: allOrderTypes.filter(
                (type: any) => type.id !== SHARPS_REQUEST_TYPE_ID
              ),
              onChange: (value: any) =>
                selectAllHandler(form, value, "orderType", "all"),
              radioValue: "detailed",
            },
            {
              name: "units",
              dropDownList: allUnits,
              onChange: (value: any) =>
                selectAllHandler(form, value, "units", "all"),
              radioValue: "detailed",
            },
          ]
        : []),
    ],
  };
  return <FormViewComp {...compProps} />;
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  reportsState,
  successMsg: reportsState.successMessage,
  errorMessage: reportsState.errorMessage,
});
const mapDispatchToProps = { setReportFilterData };
export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilterForm);
