/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Input, Select, Form } from "antd";
import classes from "./location-selection.module.scss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { trimValues } from "../../../common/utils/trimUtil";
import { locationRoomNameValidator } from "../../../common/utils/formValidators";

const LocationSelection = ({
  campusId,
  units,
  fetchUnits,
  onNext,
  locationValues,
  backHome,
  cartItems,
  removeItems,
}: any) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [selectedUnitId, setSelectedUnitId] = useState(locationValues?.unitId);
  const onFinish = (values: any) => {
    const trimmedValues = trimValues(values);
    onNext(trimmedValues);
  };
  useEffect(() => {
    if (campusId) {
      form.setFieldsValue({ campusId: campusId });
      fetchUnits(campusId);
    }
  }, [campusId]);

  const onUnitChange = (unitId: any) => {
    if (cartItems.length !== 0) {
      const unitSpecificItem = cartItems.filter((cartItem: any) => {
        return cartItem.item.unitIds.includes(1)
          ? false
          : cartItem.item.unitIds.includes(unitId)
          ? false
          : true;
      });
      if (unitSpecificItem.length > 0) {
        Modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          className: "unitSpecificModal",
          content:
            "Your Cart has some items that are specific to previous unit. It will be removed by the current selection of unit. Do you want to continue?",
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            form.setFieldsValue({ unitId });
            setSelectedUnitId(unitId);
            removeItems(
              unitSpecificItem.map((cartItem: any) => cartItem.item.id)
            );
          },
          onCancel: () => {
            form.setFieldsValue({ unitId: selectedUnitId });
          },
        });
      } else {
        setSelectedUnitId(unitId);
        form.setFieldsValue({ unitId });
      }
    } else {
      setSelectedUnitId(unitId);
      form.setFieldsValue({ unitId });
    }
  };

  return (
    <div className={classes.locationContainer}>
      <Row justify="center" align="middle" className={classes.height100}>
        <Col lg={14} xl={10} className={classes.formContainer}>
          <Form
            form={form}
            labelAlign="left"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            onFinish={onFinish}
            initialValues={locationValues}
          >
            <div className="container">
              <div className="component-container">
                <Form.Item
                  hidden={true}
                  name={"campusId"}
                  label={"Campus"}
                  required={true}
                  rules={[
                    { required: true, message: "Please select the Campus!" },
                  ]}
                />
                <Form.Item
                  name={"unitId"}
                  label={"Unit"}
                  required={true}
                  rules={[
                    { required: true, message: "Please select the Unit!" },
                  ]}
                >
                  <Select
                    className={classes.unitSelect}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onUnitChange}
                  >
                    {units.map((unit: any) => {
                      return <Option value={unit.id}>{unit.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Room Or Bedspace"
                  name="room"
                  required={true}
                  rules={[
                    {
                      required: true,
                      validator: locationRoomNameValidator,
                    },
                  ]}
                >
                  <Input name="room" />
                </Form.Item>
                <Row className={classes.bottomBtn} justify="space-around">
                  <Button onClick={backHome}>Back</Button>
                  <Button htmlType="submit" type="primary">
                    Next
                  </Button>
                </Row>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default LocationSelection;
