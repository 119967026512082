import { Skeleton } from "antd";

interface SingleLineSkeletonInterface {
  width: number;
}
export default function SingleLineSkeleton({
  width,
}: SingleLineSkeletonInterface) {
  return (
    <Skeleton
      title={{
        width,
        style: { margin: 0 },
      }}
      paragraph={{
        rows: 0,
        width: 0,
        style: { display: "none" },
      }}
      active
    />
  );
}
