/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spin, Alert, Typography, Row, Input } from "antd";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;

const DeleteModal = ({
  loading,
  entity,
  visible,
  handleCancel,
  handleSubmit,
  title,
  label,
  errorMsg,
  clearMessages,
  isPassRequired,
}: any) => {
  //state to change the view of modal to get password confirmation
  const [getPassword, setGetPassword] = useState(false);
  useEffect(() => {
    clearMessages();
  }, []);
  return (
    <Modal
      title={
        <div className="commonModalHeader">
          <div className="title">
            <p> {title}</p>
          </div>
          <div className="close">
            <Button className="closeBtn" onClick={handleCancel}>
              <CloseOutlined className="close-icon" />
            </Button>
          </div>
        </div>
      }
      visible={visible}
      width={400}
      onCancel={handleCancel}
      maskClosable={false}
      footer={null}
      closable={false}
    >
      {errorMsg ? (
        <Alert
          message={errorMsg}
          type="error"
          closable
          afterClose={clearMessages}
          className="fadeIn"
          style={{ paddingBottom: "10px", marginBottom: "10px" }}
        />
      ) : null}
      {getPassword ? (
        <Row align={"middle"} justify="center">
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={entity}
            style={{ width: "100%" }}
            requiredMark={false}
          >
            <Form.Item
              name="password"
              label="To continue, enter your password"
              style={{ marginBottom: "0px" }}
              rules={[
                {
                  required: true,
                  message: `Please enter your password!`,
                },
              ]}
            >
              <Input.Password autoFocus style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="id" hidden />
            <div className="modalBtn">
              <Form.Item style={{ marginRight: "3%" }}>
                <Button
                  className="cancelBtn"
                  htmlType="reset"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Spin spinning={loading}>
                  <Button className="submitBtn" htmlType="submit">
                    Delete
                  </Button>
                </Spin>
              </Form.Item>
            </div>
          </Form>
        </Row>
      ) : (
        <>
          <Row align={"middle"} justify="center">
            <InfoCircleOutlined style={{ fontSize: "25px" }} />
            <Text style={{ paddingLeft: "10px" }}>
              Are you sure you want to delete this {label}?
            </Text>
          </Row>
          <Form
            layout="vertical"
            {...{
              onFinish: !isPassRequired
                ? handleSubmit
                : () => {
                    setGetPassword(true);
                  },
            }}
            initialValues={entity}
          >
            <Form.Item name="id" hidden />
            <div className="modalBtn">
              <Form.Item style={{ marginRight: "3%" }}>
                <Button
                  className="cancelBtn"
                  htmlType="reset"
                  onClick={handleCancel}
                >
                  {isPassRequired ? "Not sure" : "Cancel"}
                </Button>
              </Form.Item>
              <Form.Item>
                <Spin spinning={loading}>
                  <Button className="submitBtn" htmlType="submit">
                    {isPassRequired ? "I'm sure" : "Delete"}
                  </Button>
                </Spin>
              </Form.Item>
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};
export default DeleteModal;
