/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { Button, Alert, Typography } from "antd";
import { FormOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import ListComponent from "../../shared/components/list-component/list-component";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import {
  fetchCampuses,
  createCampus,
  updateCampus,
  deleteCampus,
} from "./actions";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  VIEW_TEXT,
  EDIT_TEXT,
  DELETE_TEXT,
  CREATE,
  VIEW,
  EDIT,
  DELETE,
  CAMPUS_PAGE,
  BUTTON_GREEN_HEX,
} from "../../../common/shared-constants";
import CampusModal from "./campus-modals/campus-modal";
import CampusViewModal from "./campus-modals/campus-view-modal";
import DeleteModal from "../../shared/components/delete-modal/delete-modal";
import { AbilityContext } from "../../shared/ability/can";
import { useAbility } from "@casl/react";
import { Can } from "../../shared/ability/can";
import { Helmet } from "react-helmet";
import classes from "./campus.module.scss";
import { CLEAR_CAMPUS_FETCH_ERROR_MSG, CLEAR_CAMPUS_MSG } from "./action-types";
import { clearMessage } from "../../../common/actions/common-actions";
const { Title } = Typography;

const CampusPage = ({
  campuses,
  campusesLoading,
  totalCount,
  errorMsg,
  fetchCampuses,
  successMsg,
  fetchErrorMsg,
  clearMessage,
  createCampus,
  updateCampus,
  deleteCampus,
}: any) => {
  const ability = useAbility(AbilityContext);
  const [loading, setLoading] = useState(false);
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: [30, 50, 100, 120],
    showSizeChanger: true,
  });

  const { search, page, limit, showSizeChanger, pageSizeOptions } = tableOpt;

  const [modalOp, setModalOp] = useState({
    visible: false,
    mode: CREATE,
    campus: null,
    title: "Delete Campus",
    label: "campus",
  });

  useEffect(() => {
    modalOp.visible && clearMessage(CLEAR_CAMPUS_MSG);
  }, [modalOp.mode, modalOp.visible]);

  useEffect(() => {
    fetchCampuses({ search: search, limit, page: DEFAULT_PAGE });
    return () => {
      clearMessages();
      clearFetchError();
    };
  }, []);

  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  const clearMessages = () => {
    clearMessage(CLEAR_CAMPUS_MSG);
  };
  const clearFetchError = () => {
    clearMessage(CLEAR_CAMPUS_FETCH_ERROR_MSG);
  };

  const handleCancel = () => {
    setModalOp({
      ...modalOp,
      visible: false,
    });
  };

  const onView = (campus: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: VIEW,
      campus,
    });
  };
  const onDelete = (campus: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: DELETE,
      campus,
    });
  };
  const onEdit = (campus: any) => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: EDIT,
      campus,
    });
  };

  const onSearch = ({ target }: any) => {
    setOperation({
      ...tableOpt,
      search: target.value,
      page: DEFAULT_PAGE,
    });
    fetchCampuses({ search: target.value, limit, page: DEFAULT_PAGE });
  };

  const handleTableChange = (pagination: any) => {
    setOperation({
      ...tableOpt,
      page: pagination.current,
      limit: pagination.pageSize,
    });
    fetchCampuses({
      search,
      limit: pagination.pageSize,
      page: pagination.current,
    });
  };

  const onAdd = () => {
    setModalOp({
      ...modalOp,
      visible: true,
      mode: CREATE,
      campus: null,
    });
  };

  const handleSubmit = async (campus: any) => {
    setLoading(true);
    let result: any;
    if (modalOp.mode === CREATE) {
      result = await createCampus(campus);
    } else if (modalOp.mode === EDIT) {
      result = await updateCampus(campus);
    } else {
      result = await deleteCampus(campus);
    }
    if (result.success) {
      fetchCampuses({ search, limit, page: page });
      setModalOp({
        ...modalOp,
        visible: false,
      });
    }
    setLoading(false);
  };

  const columns: any = [
    {
      align: "center",
      title: "Name",
      dataIndex: "name",
      width: "18%",
      key: "name",
      render: (name: String) => name,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "18%",
      render: (location: String) => location,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Show Banner",
      dataIndex: "bannerStatus",
      key: "bannerStatus",
      width: "5%",
      render: (bannerStatus: boolean) => (bannerStatus ? "ON" : "OFF"),
      ellipsis: false,
    },
    {
      align: "center",
      title: "Actions",
      key: "action",
      width: "10%",
      ellipsis: true,
      render: (record: any) => (
        <div className="actionIcons">
          <Button
            size="small"
            className="icon-btn"
            title={VIEW_TEXT}
            onClick={() => {
              onView(record);
            }}
          >
            <EyeOutlined
              style={{ color: BUTTON_GREEN_HEX }}
              className="edit-icon"
            />
          </Button>
          {ability.can("edit", "Campus") && (
            <Button
              size="small"
              className="icon-btn"
              title={EDIT_TEXT}
              onClick={() => {
                onEdit(record);
              }}
            >
              <FormOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="edit-icon"
              />
            </Button>
          )}
          <Can I="delete" a="Campus">
            <Button
              size="small"
              className="icon-btn"
              title={DELETE_TEXT}
              onClick={() => {
                onDelete(record);
              }}
            >
              <DeleteOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="delete-icon"
              />
            </Button>
          </Can>
        </div>
      ),
      responsive: ["lg", "md"],
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Campuses | Admin Portal</title>
      </Helmet>
      <Title className={classes.titleText} level={5}>
        Campuses
      </Title>
      {successMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={successMsg}
          type="success"
          closable
          afterClose={clearMessages}
        />
      ) : null}
      {fetchErrorMsg ? (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={fetchErrorMsg}
          type="error"
          closable
          afterClose={clearFetchError}
        />
      ) : null}
      <ListComponent
        type={CAMPUS_PAGE}
        loading={campusesLoading}
        dataSource={campuses}
        columns={columns}
        handleTableChange={handleTableChange}
        onSearch={onSearch}
        onAdd={onAdd}
        pagination={{
          current: page,
          pageSize: limit,
          total: totalCount,
          showSizeChanger,
          pageSizeOptions,
        }}
        addButtonLabel={"Add New Campus"}
        searchLabel={"Search by Name"}
      />
      {modalOp.mode === VIEW ? (
        <CampusViewModal
          handelClose={handleCancel}
          visible={modalOp.visible}
          campus={modalOp.campus}
        />
      ) : null}
      {(modalOp.mode === EDIT || modalOp.mode === CREATE) && modalOp.visible ? (
        <CampusModal
          visible={modalOp.visible}
          campus={modalOp.campus}
          mode={modalOp.mode}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          loading={loading}
        />
      ) : null}
      {modalOp.mode === DELETE && modalOp.visible ? (
        <DeleteModal
          loading={loading}
          entity={modalOp.campus}
          visible={modalOp.visible}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          title={modalOp.title}
          label={modalOp.label}
          errorMsg={errorMsg}
          clearMessages={clearMessages}
          isPassRequired={true}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ campusManagement }: IRootState) => ({
  errorMsg: campusManagement.errorMessage,
  successMsg: campusManagement.successMessage,
  campusesLoading: campusManagement.loading,
  campuses: campusManagement.campuses,
  totalCount: campusManagement.totalCount,
  fetchErrorMsg: campusManagement.fetchErrorMessage,
});
const mapDispatchToProps = {
  fetchCampuses,
  createCampus,
  updateCampus,
  deleteCampus,
  clearMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(CampusPage);
