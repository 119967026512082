import { Tooltip } from "antd";
import { capitalize } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { IRootState } from "../../../../../../common/redux/reducers";
import {
  ADMIN_DATE_FORMAT,
  RETIRED_ASSET,
  RETIRED_ASSET_KEY,
} from "../../../../../../common/shared-constants";

const breadCrumbConfig: any = {
  campusId: {
    allKey: 1,
    allKeyString: "All Campus",
    defaultString: "",
    name: "Campus",
  },
  status: {
    allKey: "ALL",
    allKeyString: "All Status",
    defaultString: "",
    name: "Status",
  },
  location: {
    allKey: 1,
    allKeyString: "All Locations",
    defaultString: "",
    name: "Location",
  },
  speciality: {
    allKey: "All",
    allKeyString: "Both",
    defaultString: "",
    name: "Speciality",
    true: "Speciality",
    false: "Non-Speciality",
  },
  rental: {
    allKey: null,
    allKeyString: "Both",
    defaultString: "",
    name: "Rental",
    true: "Rental",
    false: "Non-Rental",
  },
  campus: {
    allKey: 1,
    allKeyString: "All Campus",
    defaultString: "",
    name: "Campus",
  },

  item: {
    allKey: "all",
    allKeyString: "All Equipments",
    defaultString: "",
    name: "Equipment Name",
  },
  checkin: {
    allKey: 1,
    type: "daterange",
    allKeyString: "All Date",
    defaultString: "",
    name: "Checkin Date",
  },
  decon: {
    allKey: 1,
    type: "daterange",
    allKeyString: "All Date",
    defaultString: "",
    name: "Decon Start Date",
  },
};

const getNameFromId = (id: string | number, data: any) => {
  if (data.length === 0) return id;
  return data.find((curr: any) => curr.id === Number(id))?.name || "";
};
interface FiltersBreadCrumbInterface {
  filterData: any;
  allCampuses?: any;
  allUnits?: any;
}
const FiltersBreadCrumb = (props: FiltersBreadCrumbInterface | any) => {
  const { filterData, allCampuses, allUnits } = props;
  const allItems = useSelector((s: IRootState) => s.itemManagement.allItems);
  const compareDataMap: any = {
    campusId: allCampuses,
    campus: allCampuses,
    location: allUnits,
    item: allItems,
  };
  const UIComp: any = () =>
    Object.keys(filterData).map((filterKey: string, index: number) => {
      const elementConfig = breadCrumbConfig[filterKey];
      const filterValue = filterData[filterKey];
      if (elementConfig.type === "daterange") {
        const dateString =
          filterValue?.reduce((acc: string, curr: any) => {
            return `${acc} ${acc ? "to" : ""} ${moment(curr).format(
              ADMIN_DATE_FORMAT
            )}`;
          }, "") || "All Records";
        return (
          <Tooltip title={elementConfig.name}>
            {dateString}
            {dateString && index !== Object.keys(filterData).length - 1 && (
              <span style={{ margin: "0px 5px" }}>&gt;</span>
            )}
          </Tooltip>
        );
      }
      const IdString = elementConfig[filterValue]
        ? elementConfig[filterValue]
        : filterValue;
      const isStatus = filterKey === "status";
      const isRetired = isStatus && IdString === RETIRED_ASSET_KEY;
      const { allKey, allKeyString, defaultString, name } = elementConfig;
      const displayString = `${
        filterValue === allKey
          ? isStatus
            ? capitalize(allKeyString) //capitalize only for Status - since all are uppercase
            : allKeyString
          : getNameFromId(
              capitalize(isRetired ? RETIRED_ASSET : IdString),
              compareDataMap[filterKey] || []
            )
      }`;
      return filterValue ? (
        <span key={name}>
          <Tooltip title={name}>{displayString}</Tooltip>
          {displayString && index + 1 !== Object.keys(filterData).length ? (
            <span style={{ margin: "0px 5px" }}>&gt;</span>
          ) : (
            ""
          )}
        </span>
      ) : (
        defaultString
      );
    });

  return <UIComp />;
};

export default FiltersBreadCrumb;
