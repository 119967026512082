/* eslint-disable react-hooks/exhaustive-deps*/
import { Button, message } from "antd";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { overalltablecolumns } from "../AIMOverallReport/AIMTableView";
import { checkintablecolumns } from "../CheckinReport/CheckinTableView";
import { decontablecolumns } from "../DeconReport/DeconTableView";
import { rentaltablecolumns } from "../RentalReport/RentalTableView";

interface DownloadCSVInterface {
  filterData: any;
  reportType: "all" | "rental" | "checkin" | "decon";
  reportData?: any;
}

export default function AIMDownloadCSV(props: DownloadCSVInterface) {
  const { filterData: reportsFilter, reportType } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLinkData, setCsvLinkData] = useState<any>();
  const csvLink: any = useRef();
  const getReportsData = async () => {
    //hit csv api using th filter data, if successful - set the csv file config to it - else clear it
    setLoading(true);
    let rawData: any = await ReportsAPICall(reportType, reportsFilter);
    if (rawData?.success) {
      setLoading(false);

      const preProcessor = (configFile: any) => {
        const masterConfigObj: any = {};
        const labelObj = configFile.map((configObj: any) => {
          masterConfigObj[configObj.dataIndex] = configObj;
          return { label: configObj.title, key: configObj.dataIndex };
        });
        const dataObj = rawData.data.assets.map((reportObj: any) => {
          let tempObj: any = {};
          Object.keys(reportObj).forEach((key, index) => {
            tempObj[key] =
              masterConfigObj[key]?.render(
                reportObj[key],
                reportObj,
                index,
                true
              ) || masterConfigObj[key];
          });
          return tempObj;
        });
        setCsvLinkData({
          labelData: labelObj,
          csvData: dataObj,
          fileName: `${reportType}-overall.csv`,
        });
      };
      switch (reportType) {
        case "all":
          preProcessor(overalltablecolumns);
          break;
        case "checkin":
          preProcessor(checkintablecolumns);
          break;
        case "rental":
          preProcessor(rentaltablecolumns);
          break;
        case "decon":
          preProcessor(decontablecolumns);
          break;
        default:
          break;
      }
      csvLink.current.link.click();
    } else {
      setLoading(false);
      message.error(rawData.errorMessage);
    }
  };
  return (
    <>
      <Button type="primary" onClick={() => getReportsData()} loading={loading}>
        Download CSV
      </Button>
      {csvLinkData && (
        <CSVLink
          data={csvLinkData.csvData}
          headers={csvLinkData.labelData}
          filename={csvLinkData.fileName}
          ref={csvLink}
        />
      )}
    </>
  );
}

const ReportsAPICall = async (type: string, reportsFilter: any) => {
  const {
    //AIM report
    checkin,
    checkout,
    itemType,
    status,
    speciality,
    campusId,
    //Check-in report
    from,
    to,
  } = reportsFilter;
  return await adminRootInstance
    .get(`aim/reports/${type}`, {
      params: {
        //AIM Overall report
        ...(checkin ? { from: checkin.format("YYYY-MM-DD") } : {}),
        ...(checkout ? { to: checkout.format("YYYY-MM-DD") } : {}),
        ...(itemType ? { itemId: itemType } : {}),
        ...(status ? { status: status } : {}),
        ...(speciality && speciality !== "all"
          ? { speciality: speciality }
          : {}),
        ...(campusId ? { campusId: campusId } : {}),
        //Check in report
        ...(from ? { from: from.format("YYYY-MM-DD") } : {}),
        ...(to ? { to: to.format("YYYY-MM-DD") } : {}),
      },
    })
    .then((res: any) => {
      if (res?.data) return { ...res.data, success: true };
    })
    .catch((err) => {
      return { success: false, errorMessage: err?.response?.data?.message };
    });
};
