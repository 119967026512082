import {
  CLEAR_SHARP_ERROR_MSG,
  FETCH_SHARPITEMS_FAIL,
  FETCH_SHARPITEMS_SUCCESS,
  SHARP_ORDER_PLACED_FAILED,
} from "./action-types";
import { SET_SUCCESS_MSG } from "../home/action-types";
import { requestorRootInstance } from "../../../common/config/axios-config";
import { ORDER_PLACED_SUCCESSFULLY } from "../../../common/shared-constants";

export const fetchSharpItems =
  (unitId: any, campusId: any) => async (dispatch: any) => {
    await requestorRootInstance
      .get(`item/list?catalogue=2&unit=${unitId}&campus=${campusId}`)
      .then((res: any) => {
        dispatch({
          type: FETCH_SHARPITEMS_SUCCESS,
          payload: {
            sharpItems: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SHARPITEMS_FAIL,
          payload: {
            errorMessage: err.response?.data?.message,
          },
        });
      });
  };

export const placeSharpOrder = (data: any) => (dispatch: any) => {
  return requestorRootInstance
    .post(`order`, data)
    .then((res: any) => {
      dispatch({
        type: SET_SUCCESS_MSG,
        payload: {
          successMessage: ORDER_PLACED_SUCCESSFULLY,
        },
      });
      return { status: "success" };
    })
    .catch((err) => {
      dispatch({
        type: SHARP_ORDER_PLACED_FAILED,
        payload: {
          errorMessage: err.response?.data?.message,
        },
      });
      return { status: "fail" };
    });
};

export const clearErrorMessage = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_SHARP_ERROR_MSG,
  });
};
