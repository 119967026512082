/* eslint-disable react-hooks/exhaustive-deps*/
import { Row, Card, Alert, Tooltip, Typography } from "antd";
import classes from "./home.module.scss";
import Truck from "../../../images/truck.png";
import Pointer from "../../../images/pointer.png";
import Click from "../../../images/click.png";
import { useHistory } from "react-router-dom";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { setSuccessMessage } from "./action";
import { useEffect } from "react";
import BackgroundCalls from "../../shared/background-calls";

const HomePage = ({
  successMessage,
  setSuccessMessage,
  currentCampus = {},
}: any) => {
  const history = useHistory();
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
      }, 15000);
    }
    return () => {
      setSuccessMessage("");
    };
  }, []);

  return (
    <Row
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      className={classes.homeContainer}
    >
      {currentCampus.bannerStatus && currentCampus.bannerMessage && (
        <span className={classes.bannerText}>
          <div className={classes.titleText}>Advisory Notice:</div>
          {currentCampus.bannerMessage}
        </span>
      )}
      {successMessage && (
        <Alert
          message={successMessage}
          type="success"
          showIcon
          closable
          className={`fadeIn ${classes.homeAlertVisible}`}
        />
      )}
      <div
        className={classes.cardContainer}
        onClick={() => history.push("/request/equipment-delivery")}
      >
        <Tooltip title="Equipment Delivery">
          <Card
            bordered={false}
            className={classes.truckCard}
            bodyStyle={{ padding: "0px" }}
          >
            <img alt="" src={Truck} className={classes.truck}></img>
            <Typography className={classes.truckText}>
              Equipment Delivery
            </Typography>
          </Card>
        </Tooltip>
      </div>
      <div
        className={classes.cardContainer}
        onClick={() => history.push("/request/equipment-pickup")}
      >
        <Tooltip title="Equipment Pickup">
          <Card
            bordered={false}
            className={classes.pointerCard}
            bodyStyle={{ padding: "0px" }}
          >
            <img alt="" src={Pointer} className={classes.pointer}></img>
            <Typography className={classes.pointerText}>
              Equipment Pickup
            </Typography>
          </Card>
        </Tooltip>
      </div>
      <div
        className={classes.cardContainer}
        onClick={() => history.push("/request/sharps")}
      >
        <Tooltip title="Sharps Request">
          <Card
            bordered={false}
            className={classes.clickCard}
            bodyStyle={{ padding: "0px" }}
          >
            <img alt="" src={Click} className={classes.click}></img>
            <Typography className={classes.clickText}>
              Sharps Request
            </Typography>
          </Card>
        </Tooltip>
      </div>
      <BackgroundCalls />
    </Row>
  );
};

const mapStateToProps = ({ homeReducer }: IRootState) => ({
  successMessage: homeReducer.successMessage,
  currentCampus: homeReducer.currentCampus,
});

const mapDispatchToProps = {
  setSuccessMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
