import {
  CLEAR_NOTES,
  FETCH_NOTES_FAIL,
  FETCH_NOTES_START,
  FETCH_NOTES_SUCCESS,
  UPDATE_NOTES,
} from "../action-types";

const initialState = {
  notesDetails: null as any,
  fetchErrorMessage: null as null | string,
  loading: false,
  errorMessage: null as null | string,
};

export type NotesDetails = Readonly<typeof initialState>;

const notesDetails = (state = initialState, action: any): NotesDetails => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_NOTES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        notesDetails: payload.request,
        fetchErrorMessage: null,
        loading: false,
      };
    case UPDATE_NOTES:
      return {
        ...state,
        notesDetails: [...state.notesDetails, payload],
        fetchErrorMessage: null,
        loading: false,
      };
    case FETCH_NOTES_FAIL:
      return {
        ...state,
        notesDetails: null,
        loading: false,
        fetchErrorMessage: payload.fetchErrorMessage,
      };
    case CLEAR_NOTES: //this will be called when the parent requestView component unmounts
      return initialState;
    default:
      return state;
  }
};

export default notesDetails;
