import { UPDATE_COUNT } from "../../shared/layout/notification/action-types";
import {
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAIL,
  CLEAR_ORDERS_FETCH_ERROR_MSG,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAIL,
  UPDATE_ORDER,
  FETCH_DASHBOARD_ALL_CAMPUSES_SUCCESS,
  FETCH_REQUEST_ALL_CAMPUSES_FAIL,
  INCREMENT_STATUS_COUNT,
  FETCH_DASHBOARD_ALL_BUILDINGS_SUCCESS,
  FETCH_DASHBOARD_ALL_BUILDINGS_FAIL,
  FETCH_DASHBOARD_ALL_CAMPUSES_START,
} from "./action-types";
const initialState = {
  orders: [] as any,
  fetchErrorMessage: null as null | string,
  loading: false,
  allCampusLoading: false,
  totalCount: 0,
  summary: {} as any,
  errorMessage: null as null | string,
  campuses: [] as any,
  buildings: [] as any,
};

export type DashboardManagementState = Readonly<typeof initialState>;

const dashboardState = (
  state = initialState,
  action: any
): DashboardManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_START:
      return {
        ...state,
        orders: [],
        totalCount: 0,
        loading: true,
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        fetchErrorMessage: null,
        orders: payload.orders,
        totalCount: payload.totalCount,
        loading: false,
      };
    case FETCH_ORDERS_FAIL:
      return {
        ...state,
        orders: [],
        totalCount: 0,
        loading: false,
        fetchErrorMessage: payload.fetchErrorMessage,
      };
    case CLEAR_ORDERS_FETCH_ERROR_MSG:
      return {
        ...state,
        fetchErrorMessage: null,
      };
    case FETCH_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: payload.summary,
        errorMessage: null,
      };
    case FETCH_SUMMARY_FAIL:
      return {
        ...state,
        summary: {},
        errorMessage: payload.errorMessage,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: payload.orders,
      };
    case UPDATE_COUNT:
      return {
        ...state,
        summary: {
          ...state.summary,
          [payload.oldStatus]: (state.summary[payload.oldStatus] || 0) - 1,
          [payload.newStatus]: (state.summary[payload.newStatus] || 0) + 1,
        },
      };
    case INCREMENT_STATUS_COUNT:
      return {
        ...state,
        summary: {
          ...state.summary,
          [payload.status]: (state.summary[payload.status] || 0) + 1,
        },
      };
    case FETCH_DASHBOARD_ALL_CAMPUSES_START:
      return {
        ...state,
        allCampusLoading: true,
      };
    case FETCH_DASHBOARD_ALL_CAMPUSES_SUCCESS:
      return {
        ...state,
        allCampusLoading: false,
        errorMessage: null,
        campuses: payload.campuses,
      };
    case FETCH_REQUEST_ALL_CAMPUSES_FAIL:
      return {
        ...state,
        allCampusLoading: false,
        errorMessage: payload.errorMessage,
        campuses: [],
      };
    case FETCH_DASHBOARD_ALL_BUILDINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        buildings: payload.buildings,
      };
    case FETCH_DASHBOARD_ALL_BUILDINGS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        buildings: [],
      };
    default:
      return state;
  }
};

export default dashboardState;
