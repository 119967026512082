import React from 'react';
import { Layout } from 'antd';
import Header from './header';
import Footer from './footer';
import classes from './request-portal-layout.module.scss';
const { Content } = Layout;

const RequestPortalLayout = (props: any) => {

    return (
        <React.Fragment>
            <Layout className={classes.layoutContainer} >
                <Header />
                <Content className={classes.contentContainer}>
                    {props.children}
                </Content>
                <Footer />
            </Layout>
        </React.Fragment >
    );
};

export default RequestPortalLayout;