import {
  CLEAR_PICKUP_ERROR_MSG,
  FETCH_PICKUPITEMS_FAIL,
  FETCH_PICKUPITEMS_SUCCESS,
  PICKUP_ORDER_PLACED_FAILED,
} from "./action-types";

const initialState = {
  pickupItems: [] as any,
  errorMessage: null as null | string,
  successMessage: null as null | string,
};

export type PickUpManagementState = Readonly<typeof initialState>;

const pickUpManagement = (
  state = initialState,
  action: any
): PickUpManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PICKUPITEMS_SUCCESS:
      return {
        ...state,
        pickupItems: payload.pickUpItems,
      };
    case FETCH_PICKUPITEMS_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        pickupItems: [],
      };
    case PICKUP_ORDER_PLACED_FAILED:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case CLEAR_PICKUP_ERROR_MSG:
      return {
        ...state,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default pickUpManagement;
