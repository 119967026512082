import { adminRootInstance } from "../../../../../common/config/axios-config";
import {
  CLEAR_ACTIVITIES,
  FETCH_ACTIVITIES_FAIL,
  FETCH_ACTIVITIES_START,
  FETCH_ACTIVITIES_SUCCESS,
} from "../action-types";

export const fetchActivitiesDetails =
  ({ reqId }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: FETCH_ACTIVITIES_START,
    });
    await adminRootInstance
      .get("activities/" + reqId)
      .then((res: any) => {
        dispatch({
          type: FETCH_ACTIVITIES_SUCCESS,
          payload: {
            request: res.data.data.reverse(),
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ACTIVITIES_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const clearActivities = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_ACTIVITIES,
  });
};
