import {
  FETCH_CATEGORIESITEMS_START,
  FETCH_CATEGORIESITEMS_SUCCESS,
  FETCH_CATEGORIESITEMS_FAIL,
  CLEAR_DELIVERY_ERROR_MSG,
  FETCH_SEARCHEDITEMS_SUCCESS,
  FETCH_SEARCHEDITEMS_FAIL,
  DELIVERY_ORDER_PLACED_FAILED,
} from "./action-types";

const initialState = {
  loading: false,
  categoriesWithItems: [] as any,
  errorMessage: null as null | string,
  successMessage: null as null | string,
  searchItems: [] as any,
};

export type DeliveryManagementState = Readonly<typeof initialState>;

const deliveryManagement = (
  state = initialState,
  action: any
): DeliveryManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CATEGORIESITEMS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATEGORIESITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        categoriesWithItems: payload.items,
      };
    case FETCH_CATEGORIESITEMS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        categoriesWithItems: [],
      };
    case CLEAR_DELIVERY_ERROR_MSG:
      return {
        ...state,
        errorMessage: null,
      };
    case DELIVERY_ORDER_PLACED_FAILED:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case FETCH_SEARCHEDITEMS_SUCCESS:
      return {
        ...state,
        searchItems: payload.searchItems,
      };
    case FETCH_SEARCHEDITEMS_FAIL:
      return {
        ...state,
        searchItems: [],
        errorMessage: payload.errorMessage,
      };
    default:
      return state;
  }
};

export default deliveryManagement;
