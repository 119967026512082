import { Alert, message, Row, Spin, Button, Modal, Select } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { IRootState } from "../../../../../common/redux/reducers";
//this component is dependent on styling of it's parent i.e. request-view
import classes from "../request-view.module.scss";
const fetchActiveUsers = async (setResp: any) => {
  setResp({ loading: true });
  return await adminRootInstance
    .get("active-users/")
    .then((res: any) => {
      setResp({ ...res.data, success: true });
      return res;
    })
    .catch((err) => {
      setResp({ ...err, success: false });
      return err;
    });
};

const ReassignUserModal = (props: any) => {
  const { showModal, toggleModal, reqId, assignedUsr } = props;
  const [resp, setResp] = useState<any>();
  const [newAssignee, setNewAsignee] = useState<any>();
  const [reAssignUsrRes, setReAssignUsrRes] = useState<any>();
  useEffect(() => {
    showModal && fetchActiveUsers(setResp);
    return () => {
      setResp({});
      setReAssignUsrRes({});
      !showModal && setNewAsignee(undefined);
    };
  }, [showModal]);
  const reAssignSubmitHandler = async ({ value: newUserId }: any) => {
    setReAssignUsrRes({ loading: true });
    return await adminRootInstance
      .put(`order/${reqId}?status=1&userId=${newUserId}`)
      .then((resp: any) => {
        message.success(resp.data.message);
        setReAssignUsrRes({ success: true, ...resp.data });
        toggleModal();
      })
      .catch((err: any) => {
        setReAssignUsrRes({ success: false, ...err });
      });
  };

  return (
    <Modal
      title={<div className={classes.modalHeader}>Assign user</div>}
      centered
      visible={showModal}
      onOk={toggleModal}
      onCancel={toggleModal}
      style={{ minWidth: "300px" }}
      footer={null}
      maskClosable={false}
    >
      <Row
        className={classes.flexMiddle}
        style={{ width: "100%", textAlign: "center", paddingBottom: "10px" }}
      >
        {resp?.message && !resp.success ? (
          <Alert
            message={resp?.message}
            style={{ width: "100%" }}
            closable
            type="error"
          />
        ) : null}
        {reAssignUsrRes?.message && !reAssignUsrRes.success ? (
          <Alert
            message={reAssignUsrRes?.message}
            closable
            type="error"
            style={{ width: "100%" }}
          />
        ) : null}
      </Row>
      <Row
        className={classes.flexMiddle}
        style={{ width: "100%", textAlign: "center" }}
      >
        <span style={{ marginRight: "10px" }}>Assign to</span>{" "}
        <Select
          placeholder="Select user"
          style={{ width: "200px" }}
          value={newAssignee?.value}
          onChange={(val, e) => {
            setNewAsignee(e);
          }}
        >
          {resp?.loading ? (
            <Select.Option
              value="loading"
              style={{
                textAlign: "center",
              }}
            >
              <Spin />
            </Select.Option>
          ) : (
            resp?.data
              ?.filter((activeUsr: any) =>
                assignedUsr ? activeUsr.name !== assignedUsr : true
              )
              .map(({ id, name }: any) => {
                return (
                  <Select.Option value={id} key={name}>
                    {name}
                  </Select.Option>
                );
              })
          )}
        </Select>
        <Row className={classes.modalFooter}>
          <Button
            type="primary"
            loading={reAssignUsrRes?.loading}
            className={classes.fwdButton}
            disabled={!newAssignee}
            title={!newAssignee ? "Select A User" : ""}
            onClick={() => {
              reAssignSubmitHandler(newAssignee);
            }}
          >
            Assign
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};
const mapStateToProps = ({ requestState }: IRootState) => ({
  dataLoading: requestState.loading,
  requestDetails: requestState.selectedRequest,
  errorMsg: requestState.errorMessage,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ReassignUserModal);
