import {
  SET_SUCCESS_MSG,
  FETCH_CAMPUS_BY_ID_SUCCESS,
  FETCH_CAMPUS_BY_ID_FAIL,
  CLEAR_CAMPUS_BY_ID,
  SET_CAMPUS_BY_ID_LOADING,
} from "./action-types";

interface campus {
  id?: number;
  name?: string;
  location?: string;
  bannerStatus?: boolean;
  bannerMessage?: string;
}

const initialState = {
  successMessage: null as null | string,
  loading: true,
  currentCampus: {} as campus,
  errorMessage: null as null | string,
};

export type HomeReducerState = Readonly<typeof initialState>;

const homeReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CAMPUS_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_SUCCESS_MSG:
      return {
        ...state,
        successMessage: payload.successMessage,
      };
    case FETCH_CAMPUS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentCampus: payload.campus,
      };
    case FETCH_CAMPUS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        currentCampus: {},
      };
    case CLEAR_CAMPUS_BY_ID:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default homeReducer;
