/* eslint-disable react-hooks/exhaustive-deps*/
import { Row, Card, Col, Form } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import classes from "../AIMReports.module.scss";
import { connect } from "react-redux";
import {
  fetchRentalReport,
  resetReportState,
  setReportFilterData,
} from "../actions";
import { IRootState } from "../../../../../common/redux/reducers";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import AIMDownloadCSV from "../utils/AIMDownloadCSV";
import AIMReportFilter from "../components/AIMReportFilter";
import { fetchAllCampuses } from "../../../campus/actions";
import {
  ASSET_STATUS_MAP,
  DECON_ASSET,
  SOILED_ASSET,
} from "../../../../../common/shared-constants";
import { fetchAllItems } from "../../../items/actions";
import moment, { Moment } from "moment";
import RentalTableView from "./RentalTableView";
import { capitalize } from "lodash";
const RentalReport = ({
  fetchRentalReport,
  resetReportState,
  reportData,
  filterData,
  setReportFilterData,
  errorMessage,
  dataLoading,
  fetchAllCampuses,
  allCampuses,
  fetchAllItems,
  allItems,
}: any) => {
  const [reportView, setReportView] = useState("form");

  const onViewChange = (view: any) => {
    setReportView(view);
  };
  useEffect(() => {
    fetchAllItems(true);
    fetchAllCampuses(false);
    return () => {
      resetReportState();
    };
  }, []);
  const submitHandler = async (params: any) => {
    const resp = await fetchRentalReport({
      limit: 30,
      page: 1,
      ...params,
    });
    if (resp.success) {
      onViewChange("report");
    }
  };
  //for report filter form
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState<any>(filterData || {});

  const initialValues = filterData;

  const onFinish = (values: any) => {
    setReportFilterData(form.getFieldsValue());
    submitHandler(values);
  };
  const compProps = {
    form,
    onFinish,
    initialValues,
    errorMessage,
    dataLoading,
    config: [
      {
        name: "from",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, from: val });
        },
        value: selectedValues?.from,
        label: "From",
        type: "date",
        disabledDate: (current: Moment) => {
          if (!selectedValues) return moment().isBefore(current, "day");
          const filterDate = selectedValues.to?.toDate();
          if (filterDate) {
            return moment(current?.toDate()).isAfter(filterDate, "day");
          }
          return current && current >= moment().endOf("day");
        },
      },
      {
        name: "to",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, to: val });
        },
        value: selectedValues?.to,
        label: "To",
        type: "date",
        disabledDate: (current: Moment) => {
          if (!selectedValues) return moment().isBefore(current, "day");
          const filterDate = selectedValues.from?.toDate();
          if (filterDate) {
            return (
              moment(current?.toDate()).isBefore(filterDate, "day") ||
              (current && current >= moment().endOf("day"))
            );
          }
          return current && current >= moment().endOf("day");
        },
      },
      {
        name: "itemType",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, itemType: val });
        },
        value: selectedValues?.itemType,
        label: "Item Type",
        dropDownList: [{ id: "all", name: "All" }].concat(allItems),
        placeholder: "Select Item",
      },
      {
        name: "status",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, status: val });
        },
        value: selectedValues?.itemType,
        label: "Status",
        dropDownList: ASSET_STATUS_MAP.filter(
          ({ name: statusName }: any) =>
            statusName !== SOILED_ASSET && statusName !== DECON_ASSET
        ).map((curr: any) => {
          return {
            id: curr.name,
            name: capitalize(curr.label || curr.name),
          };
        }),
        placeholder: "Select Status",
        multiple: false,
      },
      {
        name: "campusId",
        onChange: (val: any) => {
          setSelectedValues({ ...selectedValues, campusId: val });
        },
        value: selectedValues?.itemType,
        label: "Campus",
        dropDownList: allCampuses,
        placeholder: "Select campus",
      },
    ],
  };

  //
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Reports | Admin Portal</title>
      </Helmet>
      <Title
        style={{ paddingTop: "15px", textAlign: "left" }}
        className={classes.titleText}
        level={5}
      >
        Rental Report
      </Title>
      <Card
        className={`${classes.reportsContainer} ${classes.alignCenter}`}
        bodyStyle={{ width: "100%" }}
      >
        <Col>
          <Row>
            <Col span={20}>
              <Row className={classes.gobackBtnIndvl}>
                {reportView === "form" && (
                  <Link to="/admin/aim/reports" title="Go back to reports">
                    <button></button>
                  </Link>
                )}
                {reportView !== "form" && (
                  <button onClick={() => setReportView("form")}></button>
                )}
              </Row>
            </Col>
            {reportView !== "form" && reportData?.data?.length ? (
              <Col span={4} className={classes.downloadCSVButton}>
                <Row>
                  <AIMDownloadCSV
                    filterData={filterData}
                    reportType="rental"
                    reportData={reportData.data}
                  />
                </Row>
              </Col>
            ) : (
              <div />
            )}
          </Row>
          {reportView === "form" ? (
            <AIMReportFilter {...compProps} />
          ) : (
            <RentalTableView />
          )}
        </Col>
      </Card>
    </div>
  );
};
const mapStateToProps = ({
  aimReportState,
  campusManagement,
  itemManagement,
}: IRootState) => ({
  allItems: itemManagement.allItems,
  allCampuses: campusManagement.allCampuses,
  dataLoading: aimReportState.loading,
  successMsg: aimReportState.successMessage,
  filterData: aimReportState.filterData,
  reportData: aimReportState.reportData,
  errorMessage:
    aimReportState.errorMessage ||
    campusManagement.errorMessage ||
    itemManagement.errorMessage,
});
const mapDispatchToProps = {
  fetchRentalReport,
  resetReportState,
  setReportFilterData,
  fetchAllCampuses,
  fetchAllItems,
};
export default connect(mapStateToProps, mapDispatchToProps)(RentalReport);
