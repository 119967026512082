import { adminRootInstance } from "../../../../common/config/axios-config";
import {
  CLEAR_REQUEST,
  FETCH_PRIORITY_SUCCESS,
  FETCH_REQUEST_FAIL,
  FETCH_REQUEST_START,
  FETCH_REQUEST_SUCCESS,
  FETCH_UNIT_SUCCESS,
  EDIT_REQUEST_SUCCESS,
  EDIT_REQUEST_FAIL,
  EDIT_REQUEST_START,
  START_UNIT_FETCH,
  START_PRIORITY_FETCH,
} from "./action-types";

export const fetchRequestDetails =
  ({ reqId }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: FETCH_REQUEST_START,
    });
    await adminRootInstance
      .get("order/" + reqId)
      .then((res: any) => {
        dispatch({
          type: FETCH_REQUEST_SUCCESS,
          payload: {
            request: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_REQUEST_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };
export const fetchUnitsOnCampus =
  ({ campusId }: any = {}) =>
  async (dispatch: any) => {
    dispatch({ type: START_UNIT_FETCH });
    await adminRootInstance
      .get("units/" + campusId)
      .then((res: any) => {
        dispatch({
          type: FETCH_UNIT_SUCCESS,
          payload: {
            units: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_REQUEST_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const fetchPriorities =
  ({ reqTypeId, campusId }: any = {}) =>
  async (dispatch: any) => {
    dispatch({ type: START_PRIORITY_FETCH });
    await adminRootInstance
      .get(`sla/request-type/${reqTypeId}/${campusId}`)
      .then((res: any) => {
        dispatch({
          type: FETCH_PRIORITY_SUCCESS,
          payload: {
            slas: res.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_REQUEST_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const updateRequestDetails =
  ({ reqId, updatedData }: any) =>
  async (dispatch: any) => {
    dispatch({ type: EDIT_REQUEST_START });
    return adminRootInstance //change it back to adminRootInstance once api is available
      .put("order-edit/" + reqId, updatedData)
      .then((res: any) => {
        dispatch({
          type: EDIT_REQUEST_SUCCESS,
          payload: {
            updatedRequest: res.data.data,
            successMessage: "Updated Successfully",
          },
        });
        return { success: true };
      })
      .catch((err) => {
        dispatch({
          type: EDIT_REQUEST_FAIL,
          payload: { errorMessage: err.response?.data?.message },
        });
        return { success: false };
      });
  };

export const clearRequestDetails = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_REQUEST,
  });
};
