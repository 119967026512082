import { AbilityBuilder, Ability } from "@casl/ability";
import {
  Admin,
  ADMIN_DASHBOARD,
  AIM_DATA_IMPORTS,
  CAMPUS_PAGE,
  DATA_IMPORTS,
  ITEM_PAGE,
  Lead,
  SLA_PAGE,
  Supervisor,
  Techuser,
  UNIT_PAGE,
  USER_PAGE,
} from "../../../common/shared-constants";
const ability = new Ability([]);
export default ability;

export function updateAbility(ability: any, user: any) {
  const { can, rules } = new AbilityBuilder(Ability);

  if (user.role === Admin) {
    can("create", USER_PAGE);
    can("edit", USER_PAGE);
    can("delete", USER_PAGE);
    can("create", SLA_PAGE);
    can("edit", SLA_PAGE);
    can("delete", SLA_PAGE);
    can("create", CAMPUS_PAGE);
    can("edit", CAMPUS_PAGE);
    can("delete", CAMPUS_PAGE);
    can("create", UNIT_PAGE);
    can("edit", UNIT_PAGE);
    can("delete", UNIT_PAGE);
    can("create", ITEM_PAGE);
    can("edit", ITEM_PAGE);
    can("delete", ITEM_PAGE);
    can("view", DATA_IMPORTS);
    can("view", AIM_DATA_IMPORTS);
    can("view", ADMIN_DASHBOARD);
  } else if (user.role === Lead || user.role === Supervisor) {
    can("view", ADMIN_DASHBOARD);
    can("view", AIM_DATA_IMPORTS);
  } else if (user.role === Techuser) {
    can("view", AIM_DATA_IMPORTS);
  }

  ability.update(rules);
}
