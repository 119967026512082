import { SpecialityBadge } from "../../../../../common/components/specialityBadge/typeBadges";
export const nullRenderer = (
  value: any, // value to be rendered
  record: any, //record is the row data
  index: any, // index is the row index
  isCsv: boolean, //to determine if we are rendering for csv or not
  showFlag?: any //to know if we need to show the deleted flag in the equipment table cell
) => {
  if (isCsv)
    return `${showFlag && record.isDeletedItem ? "(D)" : ""} ${value || "-"} `;
  return (
    <span title={value}>
      {showFlag && record.isDeletedItem ? (
        <SpecialityBadge itemName={value} minimal type="deleted" />
      ) : (
        value || "-"
      )}
    </span>
  );
};
export const dateRenderer = (
  DateString: any,
  record: any,
  index?: number,
  isCsv?: boolean
) => {
  if (!DateString) return "-";
  if (isCsv) return DateString;
  const [date, time] = DateString.split(", ");
  return (
    <span title={DateString}>
      {date}
      <br />
      {time}
    </span>
  );
};
