/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react";
import { Dropdown, Layout, Menu, Typography } from "antd";
import classes from "./header.module.scss";
import logo from "../../../images/Logo.png";
import userIcon from "../../../images/user-icon.png";
import logoutIcon from "../../../images/logout.png";
import dropImg from "../../../images/Login/ellipsis.png";
import myOrder from "../../../images/Login/myorder.png";
import { IRootState } from "../../../common/redux/reducers";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { exitWithoutLogin, logoutUser } from "../../modules/login/actions";
import { useState } from "react";
import { useEffect } from "react";
import CampusSelectorModal from "../components/campus-select-modal/campus-select-modal";
import { fetchCurrentCampusData } from "../../modules/home/action";

const { Header } = Layout;
const { Title } = Typography;

const HeaderComp = ({
  account,
  logoutUser,
  exitWithoutLogin,
  anonymous,
  activeDirectory,
  currentCampus,
  fetchCurrentCampusData,
}: any) => {
  const [campusModal, setCampusModal] = useState(false);
  useEffect(() => {
    const localCurrentCampus: any = localStorage.getItem("currentCampusId");
    if (localCurrentCampus) {
      fetchCurrentCampusData({ id: localCurrentCampus });
    }
  }, []);

  const history = useHistory();
  const logout = () => {
    if (anonymous && activeDirectory === true) {
      exitWithoutLogin();
    } else {
      logoutUser();
      if (!activeDirectory) {
        history.push("/request");
      }
    }
  };
  const menu = (
    <Menu>
      <Menu.Item
        key="2"
        onClick={(e) => {
          history.push("/request/my-requests");
        }}
      >
        <div className="menuList">
          <img src={myOrder} alt="my-order" />
          <span>My Requests</span>
        </div>
      </Menu.Item>
      <Menu.Item key="1" onClick={logout}>
        <div className="menuList">
          <img src={logoutIcon} alt="logOut" />
          <span>Log Out</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="commonHeader">
      <div className="logo">
        <Link to="/request">
          <img alt="" src={logo} />
        </Link>
        <span className={classes.selectedCampus}>
          <b>Selected Campus: </b>
          {currentCampus?.id ? (
            <b>
              <u>{currentCampus?.name}</u>
            </b>
          ) : (
            "No Campus Selected"
          )}{" "}
          (
          <span
            title="Click to change campus"
            className={classes.switch}
            onClick={() => setCampusModal(true)}
          >
            switch
          </span>
          )
        </span>
      </div>
      <div className="title">
        <Title level={4}>Home</Title>
      </div>
      <div className="iconsContainer">
        {account && (
          <div>
            <img alt="" src={userIcon} className="userIcon" />
            <span className="usernameContainer">
              {account.name || account.username}
            </span>
          </div>
        )}
        {activeDirectory && anonymous ? (
          <div onClick={logout} className="logoutContainer">
            <img alt="" src={logoutIcon} className="logoutIcon" />
          </div>
        ) : null}
        {account && !anonymous && (
          <Dropdown
            className="loginMenu"
            overlayClassName="loginDropdownMenu"
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            overlay={menu}
            trigger={["click"]}
          >
            <span
              className="ant-dropdown-link"
              style={{ cursor: "pointer" }}
              onClick={(e) => e.preventDefault()}
            >
              <img src={dropImg} alt="" />
            </span>
          </Dropdown>
        )}
      </div>
      <CampusSelectorModal
        showModal={campusModal}
        closeModal={() => setCampusModal(false)}
      />
    </Header>
  );
};

const mapStateToProps = ({
  authentication: { account, anonymous, activeDirectory },
  requestData: { campuses: allCampuses },
  homeReducer: { currentCampus },
}: IRootState) => ({
  account,
  anonymous,
  activeDirectory,
  currentCampus,
  allCampuses,
});

export default connect(mapStateToProps, {
  logoutUser,
  exitWithoutLogin,
  fetchCurrentCampusData,
})(HeaderComp);
