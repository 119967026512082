/* eslint-disable react-hooks/exhaustive-deps*/
import { Button, message } from "antd";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { getCSVFileConfig } from "./utils";

interface DownloadCSVInterface {
  filterData: any;
  reportType:
    | "sla"
    | "status"
    | "item"
    | "productivity"
    | "spl-item"
    | "overall";
  reportData?: any;
}

export default function DownloadCSV(props: DownloadCSVInterface) {
  const { filterData: reportsFilter, reportType } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLinkData, setCsvLinkData] = useState<any>();
  const csvLink: any = useRef();
  const getReportsData = async () => {
    //hit csv api using th filter data, if successful - set the csv file config to it - else clear it
    setLoading(true);
    let rawData: any = await ReportsAPICall(reportType, reportsFilter);
    if (rawData?.success) {
      let typeOfReport =
        reportType === "spl-item" || reportType === "overall"
          ? "detailed"
          : reportsFilter.reportType;
      setLoading(false);
      typeOfReport &&
        setCsvLinkData({
          ...getCSVFileConfig(
            rawData.reportData,
            reportType === "spl-item" ? "speciality" : reportType,
            typeOfReport
          ),
          fileName: `${reportType}-${
            reportsFilter?.reportType || "detailed"
          }.csv`,
        });
      csvLink.current.link.click();
    } else {
      setLoading(false);
      message.error(rawData.errorMessage);
    }
  };
  return (
    <>
      <Button type="primary" onClick={() => getReportsData()} loading={loading}>
        Download CSV
      </Button>
      {csvLinkData && (
        <CSVLink
          data={csvLinkData.csvData}
          headers={csvLinkData.labelData}
          filename={csvLinkData.fileName}
          ref={csvLink}
        />
      )}
    </>
  );
}

const ReportsAPICall = async (type: string, reportsFilter: any) => {
  const {
    reportType,
    orderType,
    units,
    fromDate,
    toDate,
    sla,
    status,
    items,
    users,
    specialityItem,
  } = reportsFilter;

  return await adminRootInstance
    .get(`reports/${type}`, {
      params: {
        type: reportType,
        from: fromDate.format("YYYY-MM-DD"),
        to: toDate.format("YYYY-MM-DD"),
        ...(orderType?.length ? { request: orderType.join(",") } : {}),
        ...(units?.length ? { unit: reportsFilter.units.join(",") } : {}),
        ...(sla
          ? { sla: reportType === "detailed" ? sla : sla.join(",") }
          : {}),
        ...(status
          ? { status: reportType === "detailed" ? status : status.join(",") }
          : {}),
        ...(items
          ? { items: reportType === "detailed" ? items : items.join(",") }
          : {}),
        ...(users
          ? { users: reportType === "detailed" ? users : users.join(",") }
          : {}),
        ...(specialityItem?.length
          ? { splItems: specialityItem.join(",") }
          : {}),
      },
    })
    .then((res: any) => {
      if (res?.data)
        return {
          success: true,
          reportData: res.data.reports,
        };
    })
    .catch((err) => {
      return { success: false, errorMessage: err?.response?.data?.message };
    });
};
