/* eslint-disable react-hooks/exhaustive-deps*/
import { Button, FormInstance } from "antd";
import { Helmet } from "react-helmet";
import TableComp from "./components/TableComp/TableComp";
import { EyeOutlined } from "@ant-design/icons";
import {
  ALL_CAMPUS_ID,
  ALL_UNIT_ID,
  ASSET_STATUS_MAP,
  BUTTON_GREEN_HEX,
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  RETIRED_ASSET,
} from "../../../../common/shared-constants";
import { connect } from "react-redux";
import { IRootState } from "../../../../common/redux/reducers";
import {
  clearState,
  fetchAssetsData,
  fetchSummaryData,
  setFilterData,
  updateAssetStatus,
} from "./actions";
import { useEffect, useState } from "react";
import { clearMessage } from "../../../../common/actions/common-actions";
import { CLEAR_ALL_MESSAGES } from "./action-types";
import { fetchAllCampuses } from "../../campus/actions";
import { fetchAllUnits } from "../../unit/actions";
import { adminRootInstance } from "../../../../common/config/axios-config";
import { capitalize } from "lodash";
import { validateIncorrectValue } from "../utils/utils";
import { dateRenderer, nullRenderer } from "../AIMReports/utils/Renderer";
const summaryConfig = [
  { label: "Available", datakey: "available" },
  { label: "In Use", datakey: "inuse" },
  { label: "Soiled", datakey: "soiled" },
  { label: "Decon", datakey: "decon" },
  { label: "Pick up ready", datakey: "pickup ready" },
  { label: "Repair", datakey: "repair" },
  { label: "Retired", datakey: "deleted" },
];

const localFilterDataKey = "aimDashboardFilter";
function AIMDashboard({
  fetchSummaryData,
  summary,
  fetchAssetsData,
  assets,
  assetsLoading,
  errorMessage,
  clearMessage,
  setFilterData,
  filterData,
  fetchAllCampuses,
  fetchAllUnits,
  allCampuses,
  campusLoading,
  allUnits,
  unitsLoading,
  updateAssetStatus,
  clearState,
}: any) {
  const filterConfig = [
    {
      itemLabel: "Campus",
      itemName: "campusId",
      itemProps: {},
      fieldPlaceholder: "Select Campus",
      notFoundDesc: "No Campuses",
      loading: campusLoading,
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: allCampuses,
    },
    {
      itemLabel: "Status",
      itemName: "status",
      itemProps: {},
      fieldPlaceholder: "Select Status",
      notFoundDesc: "No statuses",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: ASSET_STATUS_MAP.map((curr: any) => {
        return {
          id: curr.name,
          name: capitalize(curr.label || curr.name),
        };
      }),
    },
    {
      itemLabel: "Last Location",
      itemName: "location",
      itemProps: {},
      fieldPlaceholder: "Select Location",
      notFoundDesc: "No Locations",
      loading: unitsLoading,
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: allUnits,
    },
    {
      itemLabel: "Speciality",
      itemName: "speciality",
      itemProps: {},
      fieldPlaceholder: "",
      notFoundDesc: "",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: [
        { name: "Both", id: "both" },
        { name: "Yes", id: "true" },
        { name: "No", id: "false" },
      ],
    },
    {
      itemLabel: "Rental",
      itemName: "rental",
      itemProps: {},
      fieldPlaceholder: "",
      notFoundDesc: "",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: (form: FormInstance) => {
        if (form.getFieldValue("speciality") === "false") {
          form.setFieldsValue({ rental: "false" });
        }
        return form.getFieldValue("speciality") === "false";
      },
      fieldOnChange: () => {},
      data: [
        { name: "Both", id: "both" },
        { name: "Yes", id: "true" },
        { name: "No", id: "false" },
      ],
    },
  ];
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: ["30", "50", "100", "120"],
    showSizeChanger: true,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const { search, limit } = tableOpt;

  let filterInitialValues = {
    campusId: ALL_CAMPUS_ID,
    status: "ALL",
    location: ALL_UNIT_ID,
    speciality: "both",
    rental: "both",
  };
  const [editProps, setEditProps] = useState<any>();
  useEffect(() => {
    setEditProps(assets.assets);
  }, [assets]);
  useEffect(() => {
    const localStore = localStorage.getItem(localFilterDataKey);
    let filterValues;
    try {
      filterValues = localStore ? JSON.parse(localStore) : filterInitialValues;
    } catch {
      filterValues = filterInitialValues;
    }
    (async () => {
      const campuses = await fetchAllCampuses();
      const units = await fetchAllUnits();
      const validCampus = validateIncorrectValue({
        allData: campuses,
        key: "campusId",
        currentFilter: filterValues,
      });
      const validLocation = validateIncorrectValue({
        allData: units,
        key: "location",
        currentFilter: filterValues,
      });
      let rectifiedFilter = {
        ...filterValues,
      };
      if (!validCampus || !validLocation) {
        !validCampus && (rectifiedFilter.campusId = ALL_CAMPUS_ID);
        !validLocation && (rectifiedFilter.location = ALL_UNIT_ID);
        localStorage.setItem(
          localFilterDataKey,
          JSON.stringify(rectifiedFilter)
        );
      }
      setFilterData(rectifiedFilter);
      fetchSummaryData();
      fetchAssetsData({ ...tableOpt, filter: rectifiedFilter });
    })();
    return () => {
      clearState();
    };
  }, []);
  //Manage operation after data upload
  const [dataUploadResp, setDataUploadResp] = useState<any>();
  useEffect(() => {
    if (dataUploadResp?.success) {
      fetchAssetsData({
        filter: filterData,
        search,
        limit,
        page: DEFAULT_PAGE,
      });
      fetchSummaryData();
    }
  }, [dataUploadResp]);

  //Table Columns
  const commonObj = {
    align: "center",
    ellipsis: true,
  };
  const columns: any = [
    {
      ...commonObj,
      title: "Equipment Name",
      dataIndex: "equipmentName",
      key: "equipmentName",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Control number",
      dataIndex: "controlNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Serial number",
      dataIndex: "serialnumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Specialty",
      dataIndex: "specialty",
      width: 40,
      render: (isSpecialty: any) => (isSpecialty ? "Yes" : "No"),
    },
    {
      ...commonObj,
      title: "Rental",
      dataIndex: "rental",
      width: 40,
      render: (isRental: any) => (isRental ? "Yes" : "No"),
    },
    {
      ...commonObj,
      title: "Last Location",
      dataIndex: "lastLocation",
      width: 60,
      ellipsis: false,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Status",
      dataIndex: "status",
      width: 60,
      render: (value: any, record: any) =>
        record.deleted ? RETIRED_ASSET : value || "-", //Check if deleted flag is there & show status
    },
    {
      ...commonObj,
      title: "Checkout",
      dataIndex: "checkOut",
      width: 60,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Pickup ready",
      dataIndex: "pickupReady",
      width: 60,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Check In",
      dataIndex: "checkIn",
      width: 60,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Clean Start",
      dataIndex: "cleanStart",
      width: 60,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Clean End",
      dataIndex: "cleanEnd",
      width: 60,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Actions",
      dataIndex: "action",
      width: 32,
      render: (value: any, record: any) => {
        return (
          <div className="actionIcons" style={{ width: "100%" }}>
            <Button
              size="small"
              className="icon-btn"
              title={"View"}
              onClick={() => {
                setEditProps(record);
                setShowEditModal((prev: boolean) => !prev);
              }}
            >
              <EyeOutlined
                style={{ color: BUTTON_GREEN_HEX }}
                className="edit-icon"
              />
            </Button>
          </div>
        );
      },
      responsive: ["lg", "md"],
    },
  ];
  const onFilterSubmit = (filterValues: any) => {
    setFilterData(filterValues);
    setOperation({
      ...tableOpt,
      page: DEFAULT_PAGE,
    });
    fetchAssetsData({
      filter: filterValues,
      search,
      limit,
      page: DEFAULT_PAGE,
    });
    setFilterData(filterValues);
    localStorage.setItem(
      localFilterDataKey,
      JSON.stringify({ ...filterValues })
    );
  };
  const onSearch = (searchTerm: string) => {
    fetchAssetsData({
      filter: filterData,
      search: searchTerm.trim(),
      limit,
      page: DEFAULT_PAGE,
    });
    setOperation({
      ...tableOpt,
      search: searchTerm,
      page: DEFAULT_PAGE,
    });
  };
  const onFilterReset = () => {
    setOperation({
      ...tableOpt,
      page: DEFAULT_PAGE,
    });
    fetchAssetsData({
      filter: filterInitialValues,
      search,
      limit,
      page: DEFAULT_PAGE,
    });
    localStorage.removeItem(localFilterDataKey);
    setFilterData(filterInitialValues);
  };
  const onAssetEdit = async (values: any) => {
    const resp = await updateAssetStatus({
      notes: values?.notes || "",
      assetId: editProps.id,
      status: values.status,
      fetchApiData: { filter: filterData, ...tableOpt },
    });
    return resp;
  };

  const onDeleteAsset = async (values: any) => {
    return await adminRootInstance
      .delete(`/aim/${values.assetId}`)
      .then(async (res: any) => {
        fetchAssetsData({
          filter: filterData,
          search,
          limit,
          page: DEFAULT_PAGE,
        });
        fetchSummaryData();
        return { success: true };
      })
      .catch((error: any) => {
        return {
          success: false,
          error: error.response?.data?.message || "Something went wrong!",
        };
      });
  };
  return (
    <>
      <Helmet>
        <title>Dashboard | AIM</title>
      </Helmet>
      <TableComp
        title="Dashboard"
        data={assets.assets}
        dataPagination={assets.pagination}
        onSearch={onSearch}
        onFilterSubmit={onFilterSubmit}
        onFilterReset={onFilterReset}
        columns={columns}
        summaryData={summary}
        summaryConfig={summaryConfig} //provide summary Data & summaryConfig if you want to show summary
        errorMessage={errorMessage}
        loading={assetsLoading}
        clearMessages={() => clearMessage(CLEAR_ALL_MESSAGES)}
        filterData={filterData}
        filterConfig={filterConfig}
        tableConfig={tableOpt}
        changeTableConfig={(tableConf: any) => {
          setOperation(tableConf);
          fetchAssetsData({ filter: filterData, search, ...tableConf });
        }}
        filterInitialValues={filterInitialValues}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        onImportResp={(respObj: any) => {
          setDataUploadResp(respObj);
        }}
        allCampuses={allCampuses}
        allUnits={allUnits}
        editProps={editProps}
        onEdit={onAssetEdit}
        onDelete={onDeleteAsset}
      />
    </>
  );
}

const mapStateToProps = ({
  aimDashboardState,
  campusManagement,
  unitManagement,
}: IRootState) => ({
  summary: aimDashboardState.summary,
  summaryLoading: aimDashboardState.summaryLoading,
  assets: aimDashboardState.assets,
  assetsLoading: aimDashboardState.assetsLoading,
  errorMessage: aimDashboardState.errorMessage,
  filterData: aimDashboardState.filterData,
  allCampuses: campusManagement.allCampuses,
  campusLoading: campusManagement.loading,
  allUnits: unitManagement.allUnits,
  unitsLoading: unitManagement.loading,
});
const mapDispatchToProps = {
  fetchSummaryData,
  fetchAssetsData,
  clearMessage,
  setFilterData,
  fetchAllCampuses,
  fetchAllUnits,
  updateAssetStatus,
  clearState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIMDashboard);
