export const FETCH_SLAS_SUCCESS = "FETCH_SLAS_SUCCESS";
export const FETCH_SLAS_FAIL = "FETCH_SLAS_FAIL";
export const CREATE_SLA_SUCCESS = "CREATE_SLA_SUCCESS";
export const CREATE_SLA_FAIL = "CREATE_SLA_FAIL";
export const UPDATE_SLA_SUCCESS = "UPDATE_SLA_SUCCESS";
export const UPDATE_SLA_FAIL = "UPDATE_SLA_FAIL";
export const DELETE_SLA_FAIL = "DELETE_SLA_FAIL";
export const DELETE_SLA_SUCCESS = "DELETE_SLA_SUCCESS";
export const CLEAR_SLA_MSG = "CLEAR_SLA_MSG";
export const CLEAR_SLA_FETCH_ERROR_MSG = "CLEAR_SLA_FETCH_ERROR_MSG";
export const FETCH_SLAS_START = "FETCH_SLAS_START";
export const FETCH_REQUEST_TYPES = "FETCH_REQUEST_TYPES";
export const FETCH_REQUEST_TYPES_FAIL = "FETCH_REQUEST_TYPES_FAIL";
