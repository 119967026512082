import React from "react";
import { InputNumber, Tooltip } from "antd";
import classes from "./ItemCard.module.scss";
import {
  DELIVERY_REQUEST_TYPE_ID,
  PATIENTMODAL_VIEW,
  PICKUP_REQUEST_TYPE_ID,
  SHARPS_REQUEST_TYPE_ID,
  SPECIALTY_HOVER_TITLE,
  SPECIALTY_INPUT_NUMBER_DISABLED_TITLE,
  VIEW_PATIENTS_TEXT,
} from "../../../../common/shared-constants";
import { allowOnlyNumbers } from "../../../../common/utils/trimUtil";
interface ItemCardInterface {
  itemId: number;
  name: string;
  quantity: number;
  image: string;
  editable: boolean;
  index?: number;
  propsToEdit?: any;
  delay: boolean;
  delayDescription?: string;
  reqTypeId: number;
  specialityItem: boolean;
  setDetails?: Function;
  viewPatientProps?: any;
  itemObj: any;
  disabled?: boolean;
}
const ItemCard = (props: ItemCardInterface) => {
  const {
    itemId,
    name,
    quantity,
    image,
    editable,
    index,
    propsToEdit = {},
    delay,
    delayDescription = "Delay",
    reqTypeId,
    specialityItem = false,
    setDetails = () => {},
    viewPatientProps = {},
    itemObj = {},
    disabled = false,
  } = props;
  const { size: { name: itemSize = "" } = {} } = itemObj;
  const showDelayFlag = delay && reqTypeId !== PICKUP_REQUEST_TYPE_ID;
  const showSpecialtyFlag =
    specialityItem && reqTypeId === DELIVERY_REQUEST_TYPE_ID;
  if (editable) {
    const { quantityEdit, onItemRemove } = propsToEdit;
    return (
      <li
        key={index}
        className={`${classes.cartCardList} ${
          disabled && classes.disabledCard
        }`}
      >
        <div className={classes.accordianImg}>
          <div
            className={`${classes.itemImage} ${
              disabled && classes.disableEvents
            }`}
          >
            <img src={image} alt="item-img" key={itemId} />
            <div
              className={`imageOverlay_100 decrement`}
              onClick={() => {
                quantity === 1
                  ? onItemRemove(itemId)
                  : quantityEdit(itemId, "DECREASE");
              }}
            >
              -
            </div>
            <div
              className={`imageOverlay_100 increment`}
              onClick={() => {
                quantityEdit(itemId, "INCREASE");
              }}
            >
              +
            </div>
            {showDelayFlag && (
              <div className={classes.delay} title={delayDescription}>
                *
              </div>
            )}
            {showSpecialtyFlag && (
              <div className={classes.specialty} title={SPECIALTY_HOVER_TITLE}>
                *
              </div>
            )}
          </div>
        </div>
        <div className={classes.quantity}>
          <Tooltip title={name}>
            <div className={classes.truncateText}>{name}</div>
          </Tooltip>
          {itemSize && reqTypeId === SHARPS_REQUEST_TYPE_ID && (
            <div className={classes.itemSize}>
              <Tooltip title={itemSize}>
                <span>
                  <b>Size:</b>
                  &nbsp;{itemSize}
                </span>
              </Tooltip>
            </div>
          )}
          <div>Quantity</div>
          <div className={classes.quantityButtonWrapper}>
            <button
              onClick={(e) => {
                e.preventDefault();
                quantity === 1
                  ? onItemRemove(itemId)
                  : quantityEdit(itemId, "DECREASE");
              }}
              className={classes.minus}
              disabled={disabled}
            ></button>
            <InputNumber
              type="name"
              value={quantity}
              onChange={(quant) => {
                quantityEdit(itemId, "EDIT", quant);
              }}
              className={`${disabled && classes.disableEvents}`}
              disabled={
                disabled ||
                !!(specialityItem && reqTypeId === DELIVERY_REQUEST_TYPE_ID)
              }
              title={
                !!(specialityItem && reqTypeId === DELIVERY_REQUEST_TYPE_ID)
                  ? SPECIALTY_INPUT_NUMBER_DISABLED_TITLE
                  : ""
              }
              onKeyDown={allowOnlyNumbers}
              min={1}
              max={99}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                quantityEdit(itemId, "INCREASE");
              }}
              disabled={disabled || quantity >= 99}
              className={classes.add}
            ></button>
          </div>

          {viewPatientProps?.patients?.length ? (
            <span
              className="linkLikeText"
              onClick={() =>
                setDetails({
                  id: itemId,
                  mode: PATIENTMODAL_VIEW,
                })
              }
            >
              {VIEW_PATIENTS_TEXT}
            </span>
          ) : (
            <div></div>
          )}
        </div>
        {!disabled && (
          <button
            onClick={(e) => {
              e.preventDefault();
              onItemRemove(itemId);
            }}
            className={`${classes.close}`}
          >
            <span>X</span>
          </button>
        )}
      </li>
    );
  }
  return (
    <div className={classes.itemBox}>
      <div className={`${classes.itemsCardImage} ${classes.flexMiddle}`}>
        <div className={classes.itemImage}>
          <img src={image} alt="item" key={itemId} />
          {showDelayFlag && (
            <div className={classes.delay} title={delayDescription}>
              *
            </div>
          )}
          {showSpecialtyFlag && (
            <div className={classes.specialty} title={SPECIALTY_HOVER_TITLE}>
              *
            </div>
          )}
        </div>
      </div>
      <div className={`${classes.itemsCardDescription} ${classes.flexMiddle}`}>
        <div className={classes.truncateText}>
          <Tooltip title={name}>
            <span>{name}</span>
          </Tooltip>
        </div>
        {itemSize && reqTypeId === SHARPS_REQUEST_TYPE_ID && (
          <div className={classes.itemSize}>
            <Tooltip title={itemSize}>
              <span>
                <b>Size:</b>
                &nbsp;{itemSize}
              </span>
            </Tooltip>
          </div>
        )}
        {quantity && <div>Quantity: {quantity}</div>}
        {viewPatientProps?.patients?.length ? (
          <span
            className="linkLikeText"
            onClick={() =>
              setDetails({
                id: itemId,
                mode: PATIENTMODAL_VIEW,
              })
            }
          >
            {VIEW_PATIENTS_TEXT}
          </span>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
export default ItemCard;
