import {
  FETCH_REQUEST_ALL_CAMPUSES_SUCCESS,
  FETCH_REQUEST_ALL_CAMPUSES_FAIL,
  FETCH_CAMPUS_UNITS_SUCCESS,
  FETCH_CAMPUS_UNITS_FAIL,
  CLEAR_LOCATION_ERROR_MSG,
  FETCH_REQ_SLA_SUCCESS,
  FETCH_REQ_SLA_FAIL,
  FETCH_REQUEST_START,
} from "./action-types";

const initialState = {
  loading: false,
  campuses: [] as any,
  units: [] as any,
  slas: [] as any,
  errorMessage: null as null | string,
};

export type RequestDataState = Readonly<typeof initialState>;

const requestData = (state = initialState, action: any): RequestDataState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REQUEST_ALL_CAMPUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        campuses: payload.campuses,
      };
    case FETCH_REQUEST_ALL_CAMPUSES_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        campuses: [],
      };
    case FETCH_CAMPUS_UNITS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        units: payload.units,
      };
    case FETCH_CAMPUS_UNITS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        units: [],
      };
    case CLEAR_LOCATION_ERROR_MSG:
      return {
        ...state,
        errorMessage: null,
      };
    case FETCH_REQ_SLA_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        slas: payload.slas,
      };
    case FETCH_REQ_SLA_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        slas: [],
      };
    default:
      return state;
  }
};
export default requestData;
