import { adminRootInstance } from "../../../common/config/axios-config";
import {
  DELETE_CAMPUS_SUCCESS,
  DELETE_CAMPUS_FAIL,
  UPDATE_CAMPUS_SUCCESS,
  UPDATE_CAMPUS_FAIL,
  CREATE_CAMPUS_SUCCESS,
  CREATE_CAMPUS_FAIL,
  FETCH_CAMPUSES_FAIL,
  FETCH_CAMPUSES_SUCCESS,
  FETCH_CAMPUSES_START,
  FETCH_ALL_CAMPUSES_SUCCESS,
  FETCH_ALL_CAMPUSES_FAIL,
} from "./action-types";

export const fetchCampuses =
  ({ search, limit, page }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: FETCH_CAMPUSES_START,
    });
    await adminRootInstance
      .get("campuses", {
        params: {
          searchKeyword: search,
          pageSize: limit,
          page,
        },
      })
      .then((res: any) => {
        const campuses = res.data.data.campuses.filter(
          (campus: any) => campus.id !== 1
        );
        dispatch({
          type: FETCH_CAMPUSES_SUCCESS,
          payload: {
            campuses: campuses,
            totalCount: res.data.data.pagination.totalCount,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CAMPUSES_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const createCampus = (campus: any) => async (dispatch: any) => {
  return await adminRootInstance
    .post("/campus", campus)
    .then((res) => {
      dispatch({
        type: CREATE_CAMPUS_SUCCESS,
        payload: { successMessage: "Campus Created Successfully" },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: CREATE_CAMPUS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};

export const updateCampus = (campus: any) => async (dispatch: any) => {
  return await adminRootInstance
    .put(`/campus/${campus.id}`, campus)
    .then((res) => {
      dispatch({
        type: UPDATE_CAMPUS_SUCCESS,
        payload: { successMessage: "Campus Updated Successfully" },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_CAMPUS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};

export const deleteCampus =
  ({ id, password }: any) =>
  async (dispatch: any) => {
    return await adminRootInstance
      .delete(`/campus/${id}`, {
        params: {
          password,
        },
      })
      .then((res) => {
        dispatch({
          type: DELETE_CAMPUS_SUCCESS,
          payload: { successMessage: "Campus Deleted Successfully" },
        });
        return { success: true };
      })
      .catch((err) => {
        dispatch({
          type: DELETE_CAMPUS_FAIL,
          payload: { errorMessage: err.response?.data?.message },
        });
        return { success: false };
      });
  };

export const fetchAllCampuses = (excludeAll: any) => async (dispatch: any) => {
  dispatch({ type: FETCH_CAMPUSES_START });
  return await adminRootInstance
    .get("campuses/all")
    .then((res: any) => {
      let campuses = res.data.data;
      if (excludeAll) {
        campuses = res.data.data.filter((campus: any) => campus.id !== 1);
      }
      dispatch({
        type: FETCH_ALL_CAMPUSES_SUCCESS,
        payload: {
          allCampuses: campuses,
        },
      });
      return campuses;
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ALL_CAMPUSES_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return [];
    });
};
