import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import classes from "./InfoPopover.module.scss";
interface InfoPopoverInterface {
  message: string;
}
export default function InfoPopover({ message }: InfoPopoverInterface) {
  return (
    <span className={classes.infoPopover}>
      <Tooltip
        title={message}
        overlayClassName={classes.tooltip}
        trigger="click"
        placement="right"
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      >
        <InfoCircleOutlined className={classes.infoIcon} />
      </Tooltip>
    </span>
  );
}
