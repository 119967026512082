import React from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Spin,
  Alert,
} from "antd";
import classes from "./requestor-login.module.scss";
import Logo from "../../../images/Logo.png";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { login, clearAuthError, continueWithoutLogin } from "./actions";
import { Redirect } from "react-router-dom";

const LoginPage = (props: any) => {
  const onChange = (e: any) => {
    if (props.loginError) {
      props.clearAuthError();
    }
  };

  const onSubmit = async (values: any) => {
    props.login(values, anonymous);
  };

  const { location, isAuthenticated, anonymous } = props;
  const { from } = (location.state as any) || {
    from: { pathname: "/request", search: location.search },
  };
  if (isAuthenticated || anonymous) {
    return <Redirect to={from} />;
  }

  const continueWithoutLogin = () => {
    props.continueWithoutLogin();
  };

  return (
    <div className={classes.loginContainer}>
      <Row align="middle" justify="center" className={classes.titleContainer}>
        <Typography className={classes.portalContent}>
          REQUEST PORTAL
        </Typography>
      </Row>
      <Row>
        <Card className={classes.loginCard}>
          <img alt="" className={classes.loginImg} src={Logo}></img>
          <Typography.Title level={3} className={classes.loginFormHeader}>
            We Got It
          </Typography.Title>
          <Row>
            <Col span={24} className={classes.label}>
              <Form
                layout="vertical"
                className={classes.loginForm}
                onFinish={onSubmit}
                hideRequiredMark
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Username!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    name="email"
                    onChange={(e) => onChange(e)}
                    className={classes.inputField}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    name="password"
                    className={`${classes.inputField} passwordStyle`}
                    onChange={(e) => onChange(e)}
                  />
                </Form.Item>
                {props.loginError ? (
                  <Alert
                    className={`fadeIn ${classes.alertContainer}`}
                    message={props.loginError}
                    type="error"
                    closable
                    afterClose={props.clearAuthError}
                  />
                ) : null}
                <Spin spinning={props.loading} className={classes.formSubmit}>
                  <Button
                    className={classes.submitButton}
                    type="primary"
                    htmlType="submit"
                  >
                    <Typography className={classes.loginButtonText}>
                      Login
                    </Typography>
                  </Button>
                </Spin>
                <Button type="link" block onClick={continueWithoutLogin}>
                  <Typography className={classes.withoutLoginText}>
                    Continue Without Login
                  </Typography>
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  loading: authentication.loading,
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.errorMessage,
  anonymous: authentication.anonymous,
});

const mapDispatchToProps = { login, clearAuthError, continueWithoutLogin };

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
