import { Dispatch } from "redux";
import { adminRootInstance } from "../../../common/config/axios-config";
import {
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAIL,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAIL,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAIL,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  FETCH_ITEMS_START,
  FETCH_CATALOGUE_TYPES_SUCCESS,
  FETCH_CATALOGUE_TYPES_FAIL,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAIL,
  FETCH_ITEM_SIZE_TYPES,
  FETCH_ITEM_SIZE_TYPES_FAIL,
  FETCH_ALL_ITEMS_SUCCESS,
  FETCH_ALL_ITEMS_START,
  FETCH_ALL_ITEMS_FAIL,
} from "./action-types";

export const fetchAllItems = (aim?: boolean) => async (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_ALL_ITEMS_START,
  });
  return await adminRootInstance
    .get(aim ? "/aim/item/list/all" : "item/list/all", {})
    .then((res: any) => {
      dispatch({
        type: FETCH_ALL_ITEMS_SUCCESS,
        payload: {
          items: res.data.data,
        },
      });
      return res.data.data;
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ALL_ITEMS_FAIL,
        payload: { error: err.response?.data?.message },
      });
      return [];
    });
};
export const fetchItems =
  ({ search, limit, page }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: FETCH_ITEMS_START,
    });
    await adminRootInstance
      .get("items", {
        params: {
          searchKeyword: search,
          pageSize: limit,
          page,
        },
      })
      .then((res: any) => {
        dispatch({
          type: FETCH_ITEMS_SUCCESS,
          payload: {
            items: res.data.data.items,
            totalCount: res.data.data.pagination.totalCount,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ITEMS_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const createItem = (item: any) => async (dispatch: any) => {
  return await adminRootInstance
    .post("/item", item)
    .then((res) => {
      dispatch({
        type: CREATE_ITEM_SUCCESS,
        payload: { successMessage: "Item Created Successfully" },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: CREATE_ITEM_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};

export const updateItem = (item: any) => async (dispatch: any) => {
  return await adminRootInstance
    .put(`/item/${item.id}`, item)
    .then((res) => {
      dispatch({
        type: UPDATE_ITEM_SUCCESS,
        payload: { successMessage: "Item Updated Successfully" },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_ITEM_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
      return { success: false };
    });
};

export const deleteItem =
  ({ id, password }: any) =>
  async (dispatch: any) => {
    return await adminRootInstance
      .delete(`/item/${id}`, {
        params: {
          password,
        },
      })
      .then((res) => {
        dispatch({
          type: DELETE_ITEM_SUCCESS,
          payload: { successMessage: "Item Deleted Successfully" },
        });
        return { success: true };
      })
      .catch((err) => {
        dispatch({
          type: DELETE_ITEM_FAIL,
          payload: { errorMessage: err.response?.data?.message },
        });
        return { success: false };
      });
  };

export const fetchItemSizes = () => async (dispatch: any) => {
  return await adminRootInstance
    .get(`/item/sizes`)
    .then((res) => {
      dispatch({
        type: FETCH_ITEM_SIZE_TYPES,
        payload: { itemSizes: res.data.data },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ITEM_SIZE_TYPES_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};

export const fetchCategories = () => async (dispatch: any) => {
  return await adminRootInstance
    .get(`/item/categories`)
    .then((res) => {
      dispatch({
        type: FETCH_CATEGORIES_SUCCESS,
        payload: { categories: res.data.data },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_CATEGORIES_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};

export const fetchCatalogues = () => async (dispatch: any) => {
  return await adminRootInstance
    .get(`/item/catalogues`)
    .then((res) => {
      dispatch({
        type: FETCH_CATALOGUE_TYPES_SUCCESS,
        payload: { catalogues: res.data.data },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_CATALOGUE_TYPES_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};
export const createItemSize =
  (sizeName: string) => async (dispatch: Dispatch) => {
    return await adminRootInstance
      .post("/item/size", { name: sizeName })
      .then((res) => {
        fetchItemSizes()(dispatch);
        return {
          success: true,
          message: "Size Created Successfully",
          data: res.data.data,
        };
      })
      .catch((err) => {
        return {
          success: false,
          message: err.response?.data?.message || "Something went wrong!",
        };
      });
  };
