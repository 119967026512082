import React from "react";
import { connect } from "react-redux";
import { fetchStatusBasedReportSummary } from "../../actions";
import { IRootState } from "../../../../../common/redux/reducers";
import ReportsTable from "../commons/report-table";
import { getColumns } from "../commons/utils";
export const statusDetailedReportColumns = [
  "reqId",
  "status",
  "items",
  "sla",
  "duration",
  "isDelayed",
  "isPartial",
  "assignedTo",
  "unit",
  "dateTime",
  "cancelReason",
];
const ReportDetailTable = ({
  fetchStatusBasedReportSummary,
  reportData,
  reportLoading,
  filterData,
}: any) => {
  const columns: any = getColumns(statusDetailedReportColumns);

  return (
    <ReportsTable
      {...(!reportData?.data?.length && { scrollY: 0 })} //since the scroll props is messing with the nodata inside table
      scrollX={"max-content"}
      loading={reportLoading}
      rowData={reportData?.data}
      columnData={columns}
      totalCount={reportData?.totalCount}
      fetchData={(pagination: any) => {
        fetchStatusBasedReportSummary({
          ...pagination,
          ...filterData,
        });
      }}
    />
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  allSla: reportsState.allSlas,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  reportData: reportsState.reportData,
  reportLoading: reportsState.loading,
  filterData: reportsState.filterData,
});
const mapDispatchToProps = {
  fetchStatusBasedReportSummary,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportDetailTable);
