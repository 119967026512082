import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal, Typography, Button, Row, Col } from "antd";
import classes from "./unit-view-modal.module.scss";
const { Text } = Typography;

const UnitViewModal = ({ handelClose, unit, visible, allCampuses }: any) => {
  return (
    <Modal
      title={
        <div className="commonModalHeader">
          <div className="title">
            <p> View Unit </p>
          </div>
          <div className="close">
            <Button className="closeBtn" onClick={handelClose}>
              <CloseOutlined className="close-icon" />
            </Button>
          </div>
        </div>
      }
      visible={visible}
      maskClosable={false}
      onCancel={handelClose}
      footer={null}
      closable={false}
      width={400}
    >
      <Row className={classes.textContainer}>
        <Col span={11}>
          <Text className={classes.doubleDot}> Name</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}> {unit.name} </Text>
        </Col>
      </Row>
      <Row className={classes.textContainer}>
        <Col span={11}>
          <Text className={classes.doubleDot}> Mapped Campus</Text>
        </Col>
        <Col span={13}>
          <Text className={classes.popValue}>
            {
              allCampuses.find((campus: any) => campus.id === unit.campusId)
                ?.name
            }
          </Text>
        </Col>
      </Row>
      {unit.building && (
        <Row className={classes.textContainer}>
          <Col span={11}>
            <Text className={classes.doubleDot}> Bulding</Text>
          </Col>
          <Col span={13}>
            <Text className={classes.popValue}> {unit.building} </Text>
          </Col>
        </Row>
      )}
      {unit.floor && (
        <Row className={classes.textContainer}>
          <Col span={11}>
            <Text className={classes.doubleDot}> Floor</Text>
          </Col>
          <Col span={13}>
            <Text className={classes.popValue}> {unit.floor} </Text>
          </Col>
        </Row>
      )}
      <Row justify={"center"} className={classes.textContainer}>
        <Button type="primary" htmlType="reset" onClick={handelClose}>
          Close
        </Button>
      </Row>
    </Modal>
  );
};

export default UnitViewModal;
