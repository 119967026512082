import {
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	LOGOUT,
	FETCH_ACCOUNT_SUCCESS,
	AUTO_LOGIN_SUCCESS,
	AUTO_LOGIN_FAIL,
	CLEAR_AUTH_MSG,
	LOGIN_REQ_START,
} from "./action-types";

const initialState = {
	loading: false,
	isAuthenticated: false,
	account: null,
	errorMessage: null as null | string,
	redirectMessage: null as null | string,
	sessionHasBeenFetched: false,
	logout: false,
};

export type AdminAuthenticationState = Readonly<typeof initialState>;

const adminAuthentication = (
	state: AdminAuthenticationState = initialState,
	action: any
): AdminAuthenticationState => {
	const { type, payload } = action;
	switch (type) {
		case LOGIN_REQ_START:
			return {
				...state,
				loading: true,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				errorMessage: null,
				account: payload.user,
				logout: false,
			};
		case LOGIN_FAIL:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				errorMessage: payload.errorMessage,
				account: null,
				logout: false,
			};
		case LOGOUT:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				redirectMessage: payload.message,
				account: null,
				logout: true,
			};
		case FETCH_ACCOUNT_SUCCESS:
			return {
				...state,
				loading: false,
				errorMessage: null,
				account: payload.user,
			};
		case AUTO_LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				errorMessage: null,
				isAuthenticated: true,
				account: payload.user,
				sessionHasBeenFetched: true,
			};
		case AUTO_LOGIN_FAIL:
			return {
				...state,
				loading: false,
				isAuthenticated: false,
				account: null,
				sessionHasBeenFetched: true,
			};
		case CLEAR_AUTH_MSG:
			return {
				...state,
				errorMessage: null,
			};
		default:
			return state;
	}
};

export default adminAuthentication;
