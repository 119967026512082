import React from "react";
import { Tag } from "antd";
import classes from "./typeBadges.module.scss";
import { capitalize } from "lodash";
const SPECIALITY = "speciality";
const DELETED = "deleted";
interface specialityBadgeInterface {
  itemName: string;
  minimal?: boolean;
  type?: typeof SPECIALITY | typeof DELETED;
}
export const SpecialityBadge = ({
  itemName = "",
  minimal = false,
  type = SPECIALITY,
}: specialityBadgeInterface) => {
  let color = "";
  let tooltipText: string = `${capitalize(type)} Item`;
  switch (type) {
    case DELETED:
      color = "red";
      break;
    case SPECIALITY:
    default:
      color = "green";
      break;
  }
  const displayText = capitalize(type);
  return (
    <div className={classes.specialityLabelMinimum}>
      <Tag
        color={color}
        className={`${classes.text} ${minimal && classes.minimumText}`}
        title={tooltipText}
      >
        {minimal ? displayText.charAt(0) : displayText}
      </Tag>
      <span className={`${classes.ellipsis}`}>{itemName}</span>
    </div>
  );
};
