import {
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAIL,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAIL,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAIL,
	DELETE_USER_FAIL,
	DELETE_USER_SUCCESS,
	CLEAR_USER_MSG,
	CLEAR_USER_FETCH_ERROR_MSG,
	FETCH_USERS_START,
} from "./action-types";

const initialState = {
	loading: false,
	users: [] as any,
	totalCount: 0,
	errorMessage: null as null | string,
	successMessage: null as null | string,
	fetchErrorMessage: null as null | string,
};

export type UserManagementState = Readonly<typeof initialState>;

const userManagement = (
	state = initialState,
	action: any
): UserManagementState => {
	const { type, payload } = action;
	switch (type) {
		case FETCH_USERS_START:
			return {
				...state,
				loading: true,
			};
		case FETCH_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				fetchErrorMessage: null,
				users: payload.users,
				totalCount: payload.totalCount,
			};
		case FETCH_USERS_FAIL:
			return {
				...state,
				loading: false,
				fetchErrorMessage: payload.fetchErrorMessage,
				users: null,
			};
		case CREATE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				errorMessage: null,
				successMessage: payload.successMessage,
			};
		case CREATE_USER_FAIL:
			return {
				...state,
				loading: false,
				errorMessage: payload.errorMessage,
				successMessage: null,
			};
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				errorMessage: null,
				loading: false,
				successMessage: payload.successMessage,
			};
		case UPDATE_USER_FAIL:
			return {
				...state,
				loading: false,
				errorMessage: payload.errorMessage,
				successMessage: null,
			};

		case DELETE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				errorMessage: null,
				successMessage: payload.successMessage,
			};
		case DELETE_USER_FAIL:
			return {
				...state,
				loading: false,
				errorMessage: payload.errorMessage,
				successMessage: null,
			};
		case CLEAR_USER_MSG:
			return {
				...state,
				errorMessage: null,
				successMessage: null,
			};
		case CLEAR_USER_FETCH_ERROR_MSG:
			return {
				...state,
				fetchErrorMessage: null,
			};
		default:
			return state;
	}
};

export default userManagement;
