//Validate if selected campus/location is deleted
export const validateIncorrectValue = ({
  allData,
  key,
  currentFilter,
}: any) => {
  if (
    !allData.length ||
    !allData.some(({ id }: any) => id === currentFilter[key])
  ) {
    return false;
  }
  return true;
};
