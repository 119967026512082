/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Modal,
  Input,
  Select,
  Alert,
  message,
  InputNumber,
  Tooltip,
} from "antd";
import classes from "./request-view-modal.module.scss";
import {
  ASSIGNED,
  CANCELLED,
  COMPLETED,
  DELIVERY_REQUEST_TYPE_ID,
  OVERRIDE_CLOSE,
  PATIENTMODAL_SINGLE_NEW,
  PATIENTMODAL_SINGLE_REMOVE,
  PATIENTMODAL_VIEW,
  PICKUP_REQUEST_TYPE_ID,
  reqTypeAddItemMap,
  REQ_EDIT,
  SHARPS_REQUEST_TYPE_ID,
  SPECIALTY_HOVER_TITLE,
  SPECIALTY_INPUT_NUMBER_DISABLED_TITLE,
  UNASSIGNED,
  VIEW_PATIENTS_TEXT,
} from "../../../../common/shared-constants";
import {
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { getCatalogueIdByRequestType } from "../../../../common/utils/catalogue";
import AddItemModal from "../../../../common/components/AddItemModal/add-item-modal";
import {
  locationRoomNameValidator,
  shortDescriptionValidator,
} from "../../../../common/utils/formValidators";
import { CLEAR_MESSAGES } from "../action-types";
import { useDispatch } from "react-redux";
import { getESTFromUTC } from "../../../../common/utils/time";
import {
  allowOnlyNumbers,
  trimValues,
} from "../../../../common/utils/trimUtil";
import PatientDetailsModal from "../../../../common/components/PatientDetailsModal/patient-details-modal";
import CancelReqModal from "../../../../common/components/CancelReqModal/CancelReqModal";
import DelayModal from "../../../../common/components/DelayModal/DelayModal";

const RequestDetailsView = ({
  request,
  allCampuses,
  units,
  slas,
  allItems,
  fetchItems,
  fetchUnits,
  fetchReqSlas,
  editRequest,
  setSelectedRequest,
  successMsg,
  errorMsg,
  clearMessages,
  cancelRequest,
  reorderRequest,
  closeModal: closeParentModal,
}: any) => {
  const { scanned } = request;
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  let disableEditSection = editMode && scanned;
  let disabledSectionTitle =
    (editMode && (scanned ? "Some assets have already been scanned" : "")) ||
    "";
  const [cartItems, setCartItems] = useState<any>([]);
  const [tempCart, setTempCart] = useState<any>([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState<any>();
  const [reorderLoading, setReorderLoading] = useState<boolean>(false);
  const [showPatientModal, setShowPatientModal] = useState<any>(false);
  const { Option } = Select;
  const noSpecialityItem = cartItems.every((items: any) => {
    return !items.isSpecialtyItem;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setCartItems([...request.items]);
    setTempCart([...request.items.map((a: any) => ({ ...a }))]);
    setSelectedUnitId(request.unitId);
  }, [request]);

  useEffect(() => {
    let timer: any;
    if (successMsg) {
      timer = setTimeout(() => {
        clearMessages();
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  const scroll = () => {
    const element: any = document.getElementById("alert");
    element?.scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  };

  const onCancelClick = () => {
    setCancelModalVisible(true);
  };

  const onCancelSubmit = async (cancelReason: string) => {
    const res = await cancelRequest(request.requestId, cancelReason);
    if (res.success) {
      setSelectedRequest({ ...request, status: CANCELLED });
      setCancelModalVisible(false);
    }
  };
  const handleCancel = () => {
    setCancelModalVisible(false);
  };

  const onEditMode = () => {
    setTempCart([...request.items.map((a: any) => ({ ...a }))]);
    setEditMode(true);
  };

  const onReOrder = async (id: any) => {
    await Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      className: "unitSpecificModal",
      content: "Are you sure you want to reorder?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        setReorderLoading(true);
        let resp = await reorderRequest(id);
        if (resp.success) {
          setReorderLoading(false);
          message.success(resp.message);
          closeParentModal();
        } else {
          setReorderLoading(false);
          message.error(resp.message);
        }
      },
      onCancel: () => {},
    });
  };

  const onItemRemove = (removedId: any) => {
    let itemRemoving = [...tempCart];
    itemRemoving = itemRemoving.filter(
      (cartItem) => cartItem.item.id !== removedId
    );
    setTempCart(itemRemoving);
  };

  const onUpdateReq = async (values: any) => {
    const trimedValues = trimValues(values);
    const body = { ...trimedValues, items: [...tempCart] };
    const res = await editRequest(request.requestId, body);
    if (res.success) {
      setSelectedRequest(res.updatedRequest);
      setEditMode(false);
    }
    scroll();
  };

  const quantityEdit = (
    itemId: any,
    action: "DECREASE" | "INCREASE" | "EDIT",
    quantity?: number
  ) => {
    const items = tempCart.map((cartItem: any) => {
      const getPatientsCond =
        request.requestType.id === DELIVERY_REQUEST_TYPE_ID &&
        (cartItem.item.specialtyItem ||
          cartItem.item.specialityItem ||
          cartItem.isSpecialtyItem);
      if (cartItem.item.id === itemId) {
        if (action === "INCREASE") {
          if (getPatientsCond) {
            setShowPatientModal({ id: itemId, mode: PATIENTMODAL_SINGLE_NEW });
          } else cartItem.quantity += 1;
        } else if (action === "EDIT") {
          cartItem.quantity = quantity;
        } else {
          if (getPatientsCond) {
            cartItem?.patients?.length &&
              setShowPatientModal({
                id: itemId,
                mode: PATIENTMODAL_SINGLE_REMOVE,
              });
          } else cartItem.quantity -= 1;
        }
      }
      return cartItem;
    });
    setTempCart(items);
  };

  const onItemsSelected = (items: any) => {
    setTempCart(items);
    setAddItemModalVisible(false);
  };

  const onCampusChange = (campusId: any) => {
    form.setFieldsValue({ unitId: "" });
    setSelectedUnitId("");
    fetchUnits(campusId);
    form.setFieldsValue({ slaId: "" });
    fetchReqSlas(request.requestType.id, campusId);
  };

  const onEditCancel = () => {
    fetchUnits(request.campusId);
    fetchReqSlas(request.requestType.id, request.campusId);
    form.resetFields();
    dispatch({ type: CLEAR_MESSAGES });
    setEditMode(false);
  };

  const onUnitChange = (unitId: any) => {
    const campusId = form.getFieldsValue()?.campusId;
    if (tempCart.length !== 0) {
      const unitSpecificItem = tempCart.filter(({ item }: any) => {
        const allUnitsMapped = item?.unitIds?.includes(1);
        const allCampusesMapped = item?.campusIds?.includes(1);
        if (allUnitsMapped && allCampusesMapped) {
          return false;
        } else if (
          item?.campusIds?.includes(campusId) &&
          (item?.unitIds?.includes(unitId) || allUnitsMapped)
        ) {
          return false;
        } else if (allCampusesMapped && item?.unitIds?.includes(unitId)) {
          return false;
        } else return true;
      });
      if (unitSpecificItem.length > 0) {
        Modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          className: "unitSpecificModal",
          content:
            "Your Cart has some items that are specific to previous unit. It will be removed by the current selection of unit. Do you want to continue?",
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            form.setFieldsValue({ unitId });
            setSelectedUnitId(unitId);
            const removeIds = unitSpecificItem.map(
              (cartItem: any) => cartItem.item.id
            );
            let itemRemoving = [...tempCart];
            itemRemoving = itemRemoving.filter(
              (cartItem) => !removeIds.includes(cartItem.item.id)
            );
            setTempCart(itemRemoving);
          },
          onCancel: () => {
            form.setFieldsValue({ unitId: selectedUnitId });
          },
        });
      } else {
        setSelectedUnitId(unitId);
        form.setFieldsValue({ unitId });
      }
    } else {
      setSelectedUnitId(unitId);
      form.setFieldsValue({ unitId });
    }
  };

  const onAddNewClick = async () => {
    const catalogueId = getCatalogueIdByRequestType(request.requestType.id);
    await fetchItems(
      form.getFieldValue("unitId"),
      catalogueId,
      form.getFieldValue("campusId")
    );
    setAddItemModalVisible(true);
  };

  const closeModal = () => {
    setAddItemModalVisible(false);
  };
  let patientDetailsPresent =
    tempCart.reduce(
      (acc: any, item: any) => (item.patients ? acc + 1 : acc),
      0
    ) > 0;
  //Show SLA Disclaimer on SLA change - if it has any
  const onPriorityChange = (slaId: any) => {
    const sla = slas.find((ele: any) => ele.id === slaId);
    if (sla.disclaimerStatus) {
      info(sla.disclaimerInfo);
    }
  };
  const [showInfo, setShowInfo] = useState("");
  const info = (data: any) => {
    setShowInfo(data);
  };
  return (
    <>
      <div
        id="alert"
        style={{ width: "100%", minHeight: "1px", marginBottom: "10px" }}
      >
        {successMsg ? (
          <Alert
            className={`fadeIn ${classes.alertContainer}`}
            message={successMsg}
            type="success"
            closable
            afterClose={clearMessages}
          />
        ) : null}
        {errorMsg ? (
          <Alert
            className={`fadeIn ${classes.alertContainer}`}
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
          />
        ) : null}
      </div>
      <Form form={form} onFinish={onUpdateReq} initialValues={{ ...request }}>
        <Row className={classes.requestContainer}>
          <Row className={classes.nonEditableRow}>
            <Col span={7} className={classes.reqId}>
              <span>Request ID : {request.requestId}</span>
            </Col>
            <Col span={10} className={classes.dateAndTime}>
              <span>Date: {getESTFromUTC(request.date)}</span>
            </Col>
            <Col span={7} className={classes.status}>
              <span>Status: {request.status}</span>
            </Col>
            {request.cancelReason && (
              <Col span={24} className={classes.reasonParent}>
                Cancellation Reason:{" "}
                <span className={classes.reason}>{request.cancelReason}</span>
              </Col>
            )}
          </Row>
          <Row className={classes.requestDetailCardRow}>
            <Col
              span={24}
              style={{
                cursor: disableEditSection ? "not-allowed" : "default",
              }}
              title={disabledSectionTitle}
            >
              <Card
                title="Location Info"
                className={`${classes.requestDetailCard} ${
                  disableEditSection && classes.disableEvents
                }`}
              >
                <Row className={classes.formFieldGrid}>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <span
                          style={{
                            whiteSpace: "normal",
                          }}
                        >
                          Campus name
                        </span>
                      }
                      name="campusId"
                      labelCol={{ xl: 9, lg: 9 }}
                      wrapperCol={{ xl: 15, lg: 15 }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please select the Campus!",
                        },
                      ]}
                    >
                      {editMode ? (
                        <Select
                          onChange={onCampusChange}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {allCampuses.map((campus: any) => {
                            return (
                              <Option value={campus.id}>{campus.name}</Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <span className={classes.formValue}>
                          {request?.campus?.name}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <span
                          style={{
                            whiteSpace: "normal",
                          }}
                        >
                          Room name
                        </span>
                      }
                      name="room"
                      labelCol={{ xl: 9, lg: 9 }}
                      wrapperCol={{ xl: 15, lg: 15 }}
                      labelAlign="left"
                      rules={[
                        () => ({
                          required: true,
                          validator: locationRoomNameValidator,
                        }),
                      ]}
                    >
                      {editMode ? (
                        <Input />
                      ) : (
                        <span className={classes.formValue}>
                          {request.room}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={classes.formFieldGrid}>
                  <Col span={24}>
                    <Form.Item
                      label="Unit name"
                      name="unitId"
                      className={classes.noMargin}
                      labelCol={{ xl: 9, lg: 9 }}
                      wrapperCol={{ xl: 15, lg: 15 }}
                      labelAlign="left"
                      rules={[
                        { required: true, message: "Please select the Unit!" },
                      ]}
                    >
                      {editMode ? (
                        <Select
                          onChange={onUnitChange}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {units.map((unit: any) => {
                            return <Option value={unit.id}>{unit.name}</Option>;
                          })}
                        </Select>
                      ) : (
                        <span className={classes.formValue}>
                          {request?.unit?.name}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="SLA"
                      name="slaId"
                      className={classes.noMargin}
                      labelCol={{ xl: 9, lg: 9 }}
                      wrapperCol={{ xl: 15, lg: 15 }}
                      labelAlign="left"
                      rules={[
                        () => ({
                          required: true,
                          message: "Please Select a SLA!",
                        }),
                      ]}
                    >
                      {editMode ? (
                        <Select
                          onChange={onPriorityChange}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {slas.map((sla: any) => {
                            return <Option value={sla.id}>{sla.name}</Option>;
                          })}
                        </Select>
                      ) : (
                        <span className={classes.formValue}>
                          {request?.sla?.name}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Contact Info" className={classes.requestDetailCard}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      labelCol={{ xl: 7, lg: 9 }}
                      wrapperCol={{ xl: 17, lg: 15 }}
                      labelAlign="left"
                    >
                      <span className={classes.formValue}>
                        {request.user.name}
                      </span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      labelCol={{ xl: 7, lg: 9 }}
                      wrapperCol={{ xl: 17, lg: 15 }}
                      labelAlign="left"
                    >
                      <span className={classes.formValue}>
                        {request.user.email ? request.user.email : "-"}
                      </span>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Phone"
                      labelCol={{ xl: 7, lg: 9 }}
                      wrapperCol={{ xl: 17, lg: 15 }}
                      labelAlign="left"
                      className={classes.noMargin}
                    >
                      <span className={classes.formValue}>
                        {request.user.phone}
                      </span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Short Description"
                      name="additionalNotes"
                      className={`${classes.noMargin} autoScroll`}
                      labelCol={{ xl: 7, lg: 9 }}
                      wrapperCol={{ xl: 17, lg: 15 }}
                      labelAlign="left"
                      style={{ minHeight: 45 }}
                      rules={[
                        {
                          validator: shortDescriptionValidator,
                          required: false,
                        },
                      ]}
                    >
                      {editMode ? (
                        <Input />
                      ) : (
                        <span className={classes.formValue}>
                          {request.additionalNotes
                            ? request.additionalNotes
                            : "-"}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              cursor: disableEditSection ? "not-allowed" : "default",
            }}
            title={disabledSectionTitle}
          >
            <Col
              span={24}
              style={{
                width: "100%",
              }}
              className={`${disableEditSection && classes.disableEvents}`}
            >
              <Card
                title="Items"
                style={{ width: "100%" }}
                bodyStyle={{ width: "100%" }}
                extra={
                  editMode ? (
                    <Button
                      disabled={
                        selectedUnitId && selectedUnitId !== "" ? false : true
                      }
                      onClick={() => onAddNewClick()}
                      className="nextBtn"
                    >
                      Add New Item
                    </Button>
                  ) : null
                }
              >
                {
                  <div
                    className={`${classes.requestList} ${
                      editMode ? "" : classes.view
                    }`}
                  >
                    {editMode
                      ? tempCart.map((cart: any, index: any) => {
                          const hasOrderSplFlag =
                            Object.keys(cart).includes("isSpecialtyItem");
                          const sizeName = cart?.item?.size?.name;
                          return (
                            <div className={classes.requestGrid}>
                              <div className={classes.requestGridImg}>
                                <div className={classes.itemImageWrapper}>
                                  <img
                                    src={cart.item.image}
                                    alt="operation-bed"
                                  />
                                  <div
                                    className={`imageOverlay_100 decrement`}
                                    onClick={() => {
                                      cart.quantity === 1
                                        ? onItemRemove(cart.item.id)
                                        : quantityEdit(
                                            cart.item.id,
                                            "DECREASE"
                                          );
                                    }}
                                  >
                                    -
                                  </div>
                                  <div
                                    className={`imageOverlay_100 increment`}
                                    onClick={() => {
                                      quantityEdit(cart.item.id, "INCREASE");
                                    }}
                                  >
                                    +
                                  </div>
                                  {cart?.item?.delay &&
                                    !(
                                      request.requestType.id ===
                                      PICKUP_REQUEST_TYPE_ID
                                    ) && (
                                      <div
                                        className={classes.delay}
                                        title="Delay"
                                      >
                                        *
                                      </div>
                                    )}
                                  {(hasOrderSplFlag
                                    ? cart?.isSpecialtyItem
                                    : cart?.item?.specialtyItem) &&
                                    request.requestType.id ===
                                      DELIVERY_REQUEST_TYPE_ID && (
                                      <div
                                        className={classes.specialty}
                                        title={SPECIALTY_HOVER_TITLE}
                                      >
                                        *
                                      </div>
                                    )}
                                </div>
                                <Tooltip title={cart.item.name}>
                                  <div className={classes.itemName}>
                                    {cart.item.name}
                                  </div>
                                </Tooltip>
                                {sizeName && (
                                  <Tooltip title={sizeName}>
                                    {request.requestType.id ===
                                      SHARPS_REQUEST_TYPE_ID && (
                                      <div className={classes.itemName}>
                                        <b>Size:</b> {sizeName}
                                      </div>
                                    )}
                                  </Tooltip>
                                )}
                              </div>
                              <div className={classes.requestQty}>
                                <p>Qty</p>
                                <div className={classes.requestCount}>
                                  {editMode && (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        cart.quantity === 1
                                          ? onItemRemove(cart.item.id)
                                          : quantityEdit(
                                              cart.item.id,
                                              "DECREASE"
                                            );
                                      }}
                                      className={classes.minus}
                                    ></button>
                                  )}
                                  {editMode ? (
                                    <InputNumber
                                      value={cart.quantity}
                                      onChange={(quant) => {
                                        quantityEdit(
                                          cart.item.id,
                                          "EDIT",
                                          quant
                                        );
                                      }}
                                      disabled={
                                        !!(
                                          (hasOrderSplFlag
                                            ? cart?.isSpecialtyItem
                                            : cart.item?.specialtyItem ||
                                              cart.item?.specialityItem) &&
                                          request.requestType.id ===
                                            DELIVERY_REQUEST_TYPE_ID
                                        )
                                      }
                                      title={
                                        !!(
                                          (hasOrderSplFlag
                                            ? cart?.isSpecialtyItem
                                            : cart.item?.specialtyItem ||
                                              cart.item?.specialityItem) &&
                                          request.requestType.id ===
                                            DELIVERY_REQUEST_TYPE_ID
                                        )
                                          ? SPECIALTY_INPUT_NUMBER_DISABLED_TITLE
                                          : ""
                                      }
                                      onKeyDown={allowOnlyNumbers}
                                      min={1}
                                      max={99}
                                    />
                                  ) : (
                                    <input
                                      type="name"
                                      disabled
                                      value={cart.quantity}
                                    />
                                  )}
                                  {editMode && (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        quantityEdit(cart.item.id, "INCREASE");
                                      }}
                                      disabled={cart.quantity >= 99}
                                      className={classes.add}
                                    ></button>
                                  )}
                                </div>
                              </div>
                              {editMode && (
                                <button
                                  onClick={() => {
                                    onItemRemove(cart.item.id);
                                  }}
                                  type="button"
                                  className={classes.close}
                                >
                                  <span>X</span>
                                </button>
                              )}
                              {cart.patients?.length ? (
                                <span
                                  className="linkLikeText"
                                  style={{ position: "relative", left: "5px" }}
                                  onClick={() =>
                                    setShowPatientModal({
                                      id: cart.item.id,
                                      mode: PATIENTMODAL_VIEW,
                                    })
                                  }
                                >
                                  {VIEW_PATIENTS_TEXT}
                                </span>
                              ) : (
                                <div
                                  className={
                                    patientDetailsPresent
                                      ? classes.emptyShowPatientBlock
                                      : ""
                                  }
                                ></div>
                              )}
                            </div>
                          );
                        })
                      : cartItems.map((cart: any, index: any) => {
                          const sizeName = cart?.item?.size?.name;
                          return (
                            <div className={classes.requestGrid}>
                              <div className={classes.requestGridImg}>
                                <img src={cart.item.image} alt="" />
                                <Tooltip title={cart.item.name}>
                                  <span className={classes.itemName}>
                                    {cart.item.name}
                                  </span>
                                </Tooltip>
                                {sizeName && (
                                  <Tooltip title={sizeName}>
                                    {request.requestType.id ===
                                      SHARPS_REQUEST_TYPE_ID && (
                                      <div className={classes.itemName}>
                                        <b>Size:</b> {sizeName}
                                      </div>
                                    )}
                                  </Tooltip>
                                )}

                                {cart?.item?.delay &&
                                  !(
                                    request.requestType.id ===
                                    PICKUP_REQUEST_TYPE_ID
                                  ) && (
                                    <div
                                      className={classes.delay}
                                      title="Delay"
                                    >
                                      *
                                    </div>
                                  )}
                                {cart?.isSpecialtyItem &&
                                  request.requestType.id ===
                                    DELIVERY_REQUEST_TYPE_ID && (
                                    <div
                                      className={classes.specialty}
                                      title={SPECIALTY_HOVER_TITLE}
                                    >
                                      *
                                    </div>
                                  )}
                              </div>
                              <div className={classes.requestQty}>
                                <p>Qty</p>
                                <div className={classes.requestCount}>
                                  {editMode && (
                                    <button
                                      onClick={(id) => {
                                        quantityEdit(cart.item.id, "DECREASE");
                                      }}
                                      className={classes.minus}
                                      disabled={
                                        cart.quantity === 1 ? true : false
                                      }
                                    ></button>
                                  )}
                                  <input
                                    type="name"
                                    disabled
                                    value={cart.quantity}
                                  />
                                  {editMode && (
                                    <button
                                      onClick={(id) => {
                                        quantityEdit(cart.item.id, "INCREASE");
                                      }}
                                      disabled={cart.quantity >= 99}
                                      className={classes.add}
                                    ></button>
                                  )}
                                </div>
                              </div>
                              {editMode && (
                                <button
                                  onClick={() => {
                                    onItemRemove(cart.item.id);
                                  }}
                                  className={classes.close}
                                >
                                  <span>X</span>
                                </button>
                              )}
                              {cart.patients?.length ? (
                                <span
                                  className="linkLikeText"
                                  onClick={() =>
                                    setShowPatientModal({
                                      id: cart.item.id,
                                      mode: PATIENTMODAL_VIEW,
                                    })
                                  }
                                >
                                  {VIEW_PATIENTS_TEXT}
                                </span>
                              ) : (
                                <div
                                  className={
                                    patientDetailsPresent
                                      ? classes.emptyShowPatientBlock
                                      : ""
                                  }
                                ></div>
                              )}
                            </div>
                          );
                        })}
                  </div>
                }
              </Card>
            </Col>
          </Row>
          {editMode ? (
            <Row align="middle" className={classes.modalBtns} justify="center">
              <Button onClick={() => onEditCancel()} className="backBtn">
                Cancel
              </Button>
              <Button htmlType="submit" className="nextBtn">
                Update
              </Button>
            </Row>
          ) : (
            <Row align="middle" className={classes.modalBtns} justify="center">
              {request.status !== COMPLETED ? (
                <>
                  {request.status !== CANCELLED &&
                    request.status !== OVERRIDE_CLOSE && (
                      <Button className="backBtn" onClick={onCancelClick}>
                        Cancel Request
                      </Button>
                    )}
                  {request.status === UNASSIGNED ||
                  request.status === ASSIGNED ? (
                    <Button
                      onClick={() => {
                        onEditMode();
                      }}
                      className="nextBtn"
                    >
                      Edit Request
                    </Button>
                  ) : null}
                </>
              ) : null}
              {(request.status === COMPLETED ||
                request.status === OVERRIDE_CLOSE) &&
              (noSpecialityItem ||
                request.requestType.id !== DELIVERY_REQUEST_TYPE_ID) ? (
                <Button
                  onClick={() => {
                    onReOrder(request.id);
                  }}
                  className="nextBtn"
                  loading={reorderLoading}
                >
                  Reorder
                </Button>
              ) : null}
            </Row>
          )}
        </Row>
      </Form>
      {addItemModalVisible ? (
        <AddItemModal
          allItems={allItems}
          visible={addItemModalVisible}
          handleCancel={closeModal}
          onItemsSelected={onItemsSelected}
          editCart={tempCart}
          type={reqTypeAddItemMap[request?.requestType?.id] || REQ_EDIT}
          showDelayFlag={request.requestType.id !== PICKUP_REQUEST_TYPE_ID}
          showSpecialityFlag={
            request.requestType.id === DELIVERY_REQUEST_TYPE_ID
          }
          patientDetails
        />
      ) : null}
      {/* <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> Cancel Order</p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={cancelModalVisible}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered={true}
      >
        <Row
          className={classes.flexMiddle}
          style={{ width: "100%", textAlign: "center" }}
        >
          <InfoCircleOutlined className={classes.exclamationIcon} />
          <span style={{ paddingLeft: 10 }}>
            Are you sure you want to cancel this Request?
          </span>
          <Row className={`${classes.modalFooter} ${classes.footerButtons}`}>
            <Button
              type="default"
              className={`${classes.cancelButton}`}
              onClick={handleCancel}
            >
              No
            </Button>
            <Button
              type="default"
              className={`${classes.fwdButton} ${classes.modalFooterButton}`}
              onClick={onCancelSubmit}
            >
              Yes
            </Button>
          </Row>
        </Row>
      </Modal> */}
      <CancelReqModal
        showModal={cancelModalVisible}
        requestId={request.requestId}
        toggleModal={() => handleCancel()}
        onCancelSubmit={onCancelSubmit}
      />
      {request.requestType.id === DELIVERY_REQUEST_TYPE_ID && (
        <PatientDetailsModal
          visible={!!showPatientModal}
          toggleModal={() => setShowPatientModal(false)}
          cart={tempCart}
          setCart={setTempCart}
          itemId={showPatientModal.id}
          mode={showPatientModal.mode}
        />
      )}

      <DelayModal
        key="info-modal"
        handleCancel={() => {
          setShowInfo("");
        }}
        handleOk={() => {
          setShowInfo("");
        }}
        isModalVisible={!!showInfo}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text={showInfo}
        okayText="Okay"
        hideCancel={true}
      />
    </>
  );
};

export default RequestDetailsView;
