import React, { useEffect } from "react";
import Countdown, { zeroPad } from "react-countdown";

const updateColorScheme = () => {
  const children = Array.from(document.querySelectorAll(".timeLeft .alert"));
  children.map((child: any) => {
    child?.closest("tr").classList.add("timeExpired");
    child?.closest("tr").classList.remove("timeWarning");
    return child;
  });

  const warningchildren = Array.from(
    document.querySelectorAll(".timeLeft .warning")
  );
  warningchildren.map((child: any) => {
    child?.closest("tr").classList.add("timeWarning");
    return child;
  });
  const removeWarnAlert = Array.from(
    document.querySelectorAll(".timeLeft .noAlert")
  );
  removeWarnAlert.map((child: any) => {
    child?.closest("tr").classList.remove("timeExpired", "timeWarning");
    return child;
  });
};

const Timer = (props: any) => {
  useEffect(() => {
    const interval = setInterval(() => {
      updateColorScheme();
    }, 1000);
    return () => {
      clearInterval(interval);
      updateColorScheme();
    };
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (days > 0) {
      hours = days * 24 + hours;
    }

    return (
      <>
        <span
          className={
            completed === true
              ? "alert"
              : hours === 0 &&
                minutes <= 4 &&
                seconds <= 59 &&
                completed !== true
              ? "warning"
              : "noAlert"
          }
          style={{ fontSize: "12px" }}
        >
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      </>
    );
  };

  return (
    <Countdown
      daysInHours={true}
      date={props.timeLeft}
      overtime={true}
      renderer={renderer}
    />
  );
};

export default Timer;
