import React from "react";
import { connect } from "react-redux";
import { fetchStatusBasedReportSummary } from "../../actions";
import { IRootState } from "../../../../../common/redux/reducers";
import moment from "moment";
import { ORDER_STATUS_MAP } from "../../../../../common/shared-constants";
import ReportsTable from "../commons/report-table";

const ReportSummaryTable = ({
  fetchStatusBasedReportSummary,
  reportData,
  reportLoading,
  filterData,
}: any) => {
  const visibleColumns =
    reportData?.data?.reduce((acc: any, curr: any) => {
      const availableStatuses: any = Object.keys(curr.statusData);
      return {
        ...availableStatuses.reduce((a: any, c: any) => ({ ...a, [c]: 1 }), {}),
        ...acc,
      };
    }, {}) || {};

  const columns: any = [
    {
      title: "Date",
      dataIndex: "date",
      fixed: "left",
      width: 100,
      align: "center",
      render: (record: any) => moment(record).format("MM/DD/YYYY"),
    },
    {
      title: "Total Orders",
      dataIndex: "total",
      render: (record: any) => record || "-",
      width: 100,
      align: "center",
    },
    ...Object.keys(visibleColumns).map((id: any, ind: any) => {
      const selectedRecord: any = ORDER_STATUS_MAP.find(
        (val: any) => val.id === parseInt(id)
      );
      return {
        title: selectedRecord.name,
        render: (record: any) => record?.statusData[id] || "-",
        width: 100,
        align: "center",
      };
    }),
  ];
  return (
    <ReportsTable
      loading={reportLoading}
      rowData={reportData?.data}
      columnData={columns}
      totalCount={reportData?.totalCount}
      fetchData={(pagination: any) => {
        fetchStatusBasedReportSummary({
          ...pagination,
          ...filterData,
        });
      }}
    />
  );
};
const mapStateToProps = ({ reportsState }: IRootState) => ({
  dataLoading: reportsState.loading,
  allSla: reportsState.allSlas,
  allOrderTypes: reportsState.allOrderTypes,
  successMsg: reportsState.successMessage,
  reportData: reportsState.reportData,
  reportLoading: reportsState.loading,
  filterData: reportsState.filterData,
});
const mapDispatchToProps = {
  fetchStatusBasedReportSummary,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportSummaryTable);
