import { Row } from "antd";
import classes from "./back-button.module.scss";
import { Link, useHistory } from "react-router-dom";

interface GenericBackButtonInterface {
  title?: string;
  link?: string;
}
const GenericBackButton = ({ title, link }: GenericBackButtonInterface) => {
  const history = useHistory();
  const button = (
    <button
      {...{
        ...(!link && { onClick: () => history.goBack() }),
        title: title || "Click to go back",
      }}
    ></button>
  );
  return (
    <Row className={classes.goBackButton_sm}>
      {link ? <Link to={link}>{button}</Link> : button}
    </Row>
  );
};

export { GenericBackButton };
