import {
  FETCH_UNITS_SUCCESS,
  FETCH_UNITS_FAIL,
  CREATE_UNIT_SUCCESS,
  CREATE_UNIT_FAIL,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  DELETE_UNIT_FAIL,
  DELETE_UNIT_SUCCESS,
  CLEAR_UNIT_MSG,
  CLEAR_UNIT_FETCH_ERROR_MSG,
  FETCH_UNITS_START,
  FETCH_ALL_UNITS_SUCCESS,
  FETCH_ALL_UNITS_FAIL,
} from "./action-types";

const initialState = {
  loading: false,
  units: [] as any,
  totalCount: 0,
  errorMessage: null as null | string,
  successMessage: null as null | string,
  fetchErrorMessage: null as null | string,
  allUnits: [] as any,
};

export type UnitManagementState = Readonly<typeof initialState>;

const unitManagement = (
  state = initialState,
  action: any
): UnitManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_UNITS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_UNITS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchErrorMessage: null,
        units: payload.units,
        totalCount: payload.totalCount,
      };
    case FETCH_UNITS_FAIL:
      return {
        ...state,
        loading: false,
        fetchErrorMessage: payload.fetchErrorMessage,
        units: null,
      };
    case CREATE_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: payload.successMessage,
      };
    case CREATE_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };
    case UPDATE_UNIT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        successMessage: payload.successMessage,
      };
    case UPDATE_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };

    case DELETE_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: payload.successMessage,
      };
    case DELETE_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };
    case CLEAR_UNIT_MSG:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      };
    case CLEAR_UNIT_FETCH_ERROR_MSG:
      return {
        ...state,
        fetchErrorMessage: null,
      };
    case FETCH_ALL_UNITS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        allUnits: payload.allUnits,
        loading: false,
      };
    case FETCH_ALL_UNITS_FAIL:
      return {
        ...state,
        allUnits: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default unitManagement;
