/* eslint-disable react-hooks/exhaustive-deps*/
import { Helmet } from "react-helmet";
import {
  ALL_CAMPUS_ID,
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  PICKUP_READY_ASSET,
  RETIRED_ASSET,
  SOILED_ASSET,
} from "../../../../common/shared-constants";
import { ExclamationCircleOutlined, BarcodeOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { IRootState } from "../../../../common/redux/reducers";
import { useEffect, useState } from "react";
import { clearMessage } from "../../../../common/actions/common-actions";
import { fetchAllCampuses } from "../../campus/actions";
import TableComp from "../AIMDashboard/components/TableComp/TableComp";
import { fetchAllItems } from "../../items/actions";
import { clearAll, fetchCheckInData, setFilterData } from "./actions";
import { CLEAR_ALL_MESSAGES } from "./action-types";
import useScanner from "../hooks/useScanner";
import { message, Modal, notification } from "antd";
import { adminRootInstance } from "../../../../common/config/axios-config";
import classes from "./AssetCheckin.module.scss";
import { validateIncorrectValue } from "../utils/utils";

const localFilterDataKey = "aimAssetCheckInFilter";
function AssetCheckIn({
  fetchCheckInData,
  assets,
  assetsLoading,
  errorMessage,
  clearMessage,
  setFilterData = () => {},
  filterData,
  fetchAllCampuses,
  allCampuses,
  campusLoading,
  fetchAllItems,
  allItems,
  clearAll,
}: any) {
  const barcodeRead = useScanner();
  const [loaders, setLoaders] = useState({ okayButton: false });
  const [errorMsg, setErrorMsg] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  useEffect(() => {
    const timeout = errorMsg && setTimeout(() => setErrorMsg(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [errorMsg]);

  const flow = async ({ controlNumber, override = false }: any) => {
    return await adminRootInstance
      .put(`aim/checkin/${controlNumber}`, {
        override,
      })
      .then((res: any) => {
        setErrorMsg("");
        return res.data;
      })
      .catch((err: any) => {
        let statusCode = err.response.status;
        if ([406].includes(statusCode)) {
          return { ...err.response.data, proceed: true };
        }
        setErrorMsg(err.response?.data?.message || "Something went wrong!");
      });
  };
  const afterScan = async (scannedCode: string) => {
    setOperation({
      ...tableOpt,
      search: scannedCode,
      page: DEFAULT_PAGE,
    });
    document.getElementById("hiddenInput")?.focus();
    if (scannedCode) {
      notification.info({
        message: (
          <span className={classes.notification}>Data read: {scannedCode}</span>
        ),
        placement: "bottomRight",
        duration: 2,
        icon: <BarcodeOutlined />,
        style: { width: "max-content" },
        onClose: () => document.getElementById("hiddenInput")?.focus(),
      });
      //First api without override is hit
      const response: any = await flow({ controlNumber: scannedCode });
      if (!response) {
        return onSearch("");
      }
      if (response.status === "success") {
        //if successfull - show message
        setErrorMsg("");
        message.success(
          `Asset moved to ${response.data.status} status sucessfully.`
        );
        setFilterData(filterInitialValues);
        localStorage.setItem(
          localFilterDataKey,
          JSON.stringify({ ...filterInitialValues })
        );
        onSearch(scannedCode);
      } else if (response.proceed && response.status === "error") {
        //if error
        if (!response.currentStatus) {
          return setErrorMsg(response.message);
        }
        //if already in soiled status
        if (
          response.currentStatus === SOILED_ASSET ||
          response.currentStatus === PICKUP_READY_ASSET
        ) {
          setFilterData(filterInitialValues);
          localStorage.setItem(
            localFilterDataKey,
            JSON.stringify({ ...filterInitialValues })
          );
          onSearch(scannedCode);
          setErrorMsg(
            `Asset already moved to ${response.currentStatus} status!`
          );
          return;
        }
        //If status is other than SOILED or INUSE
        if (!modalConfirm) {
          //to prevent simultaneous popup of modal
          setModalConfirm(true);
          Modal.confirm({
            title: `Move asset to ${SOILED_ASSET} status?`,
            icon: (
              <ExclamationCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.85)" }}
              />
            ),
            className: "sharpDelayModal",
            content: (
              <span>
                <b>{scannedCode}</b> is in <b>{response.currentStatus}</b>{" "}
                status. <br />
                Do you want to move it to <b>{SOILED_ASSET}</b> status?
              </span>
            ),
            okText: "Move it",
            cancelText: "Don't move",
            width: "max-content",
            okButtonProps: {
              loading: loaders.okayButton,
              className: "nextBtn",
              onFocus: (e: any) => e.target.blur(),
            },
            cancelButtonProps: {
              className: "backBtn",
              onFocus: (e: any) => e.target.blur(),
            },
            afterClose: () => {
              setModalConfirm(false);
              document.getElementById("hiddenInput")?.focus();
            },
            onOk: async () => {
              setLoaders((loaders: any) => ({ ...loaders, okayButton: true }));
              const response: any = await flow({
                controlNumber: scannedCode,
                override: true,
              });
              if (response?.status === "success") {
                setErrorMsg("");
                setFilterData(filterInitialValues);
                localStorage.setItem(
                  localFilterDataKey,
                  JSON.stringify({ ...filterInitialValues })
                );
                onSearch(scannedCode);
                message.success("Asset moved successfully!!");
              } else setErrorMsg(response?.message || "Something went wrong!");
              setLoaders((loaders: any) => ({ ...loaders, okayButton: false }));
            },
            onCancel: () => {
              setErrorMsg("");
              onSearch("");
            },
          });
        }
      }
    }
  };

  const manualScanInput = (e: any) => {
    if (
      (e.keyCode === 10 || e.keyCode === 13 || e.key === "Enter") &&
      (e.ctrlKey || e.metaKey)
    ) {
      afterScan(search);
    }
  };
  useEffect(() => {
    !modalConfirm && afterScan(barcodeRead);
  }, [barcodeRead]);
  const campusFilterKey = "campus";
  const locationFilterKey = "item";
  const filterConfig = [
    {
      itemLabel: "Campus",
      itemName: campusFilterKey,
      itemProps: {},
      fieldPlaceholder: "Select Campus",
      notFoundDesc: "No Campuses",
      loading: campusLoading,
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: allCampuses,
    },
    {
      itemLabel: "Check-in",
      itemName: "checkin",
      type: "daterange",
      itemProps: {},
      fieldPlaceholder: "Select Date",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
    },
    {
      itemLabel: "Equipment Name",
      itemName: locationFilterKey,
      itemProps: {},
      fieldPlaceholder: "Select Equipment",
      notFoundDesc: "",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: [{ id: "all", name: "All" }].concat(
        allItems
          .filter((item: any) => item.assetTracking)
          .map((item: any) => ({
            name: item.name,
            id: item.id,
          }))
      ),
    },
  ];
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: ["30", "50", "100", "120"],
    showSizeChanger: true,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const { search, limit, page } = tableOpt;
  let filterInitialValues = {
    campus: ALL_CAMPUS_ID,
    item: "all",
    checkin: [],
  };
  useEffect(() => {
    const localStore = localStorage.getItem(localFilterDataKey);
    let filterValues = localStore
      ? JSON.parse(localStore)
      : filterInitialValues;
    (async () => {
      const campuses = await fetchAllCampuses();
      const items = await fetchAllItems(true);
      const validCampus = validateIncorrectValue({
        allData: campuses,
        key: campusFilterKey,
        currentFilter: filterValues,
      });
      const validItem = validateIncorrectValue({
        allData: items,
        key: locationFilterKey,
        currentFilter: filterValues,
      });
      let rectifiedFilter = {
        ...filterValues,
      };

      if (!validCampus || !validItem) {
        !validCampus && (rectifiedFilter[campusFilterKey] = ALL_CAMPUS_ID);
        !validItem && (rectifiedFilter[locationFilterKey] = "all");
        localStorage.setItem(
          localFilterDataKey,
          JSON.stringify(rectifiedFilter)
        );
      }
      setFilterData(rectifiedFilter);
      fetchCheckInData({ ...tableOpt, filter: rectifiedFilter });
    })();
    return () => {
      clearAll();
    };
  }, []);
  //Table Columns
  const nullRenderer = (value: any) => (value ? value : "-");
  const commonObj = {
    align: "center",
    ellipsis: true,
  };
  const columns: any = [
    {
      ...commonObj,
      title: "Equipment Name",
      dataIndex: "equipmentName",
      key: "equipmentName",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Control number",
      dataIndex: "controlNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Serial number",
      dataIndex: "serialNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Status",
      dataIndex: "status",
      width: 60,
      render: (value: any, record: any) =>
        record.deleted ? RETIRED_ASSET : value || "-", //Check if deleted flag is there & show status
    },
    {
      ...commonObj,
      title: "Check In",
      dataIndex: "checkInTime",
      width: 60,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Check In by",
      dataIndex: "checkInBy",
      width: 60,
      render: nullRenderer,
    },
  ];
  const onFilterSubmit = (filterValues: any) => {
    setErrorMsg("");
    setFilterData(filterValues);
    setOperation({
      ...tableOpt,
      page: DEFAULT_PAGE,
    });
    fetchCheckInData({
      filter: filterValues,
      search,
      limit,
      page: DEFAULT_PAGE,
    });
    setFilterData(filterValues);
    localStorage.setItem(
      localFilterDataKey,
      JSON.stringify({ ...filterValues })
    );
  };
  const onSearch = (searchTerm: string) => {
    fetchCheckInData({
      filter: filterData,
      search: searchTerm.trim(),
      limit,
      page,
    });
    setOperation({
      ...tableOpt,
      search: searchTerm,
      page: DEFAULT_PAGE,
    });
  };
  const onFilterReset = () => {
    setErrorMsg("");
    setOperation({
      ...tableOpt,
      page: DEFAULT_PAGE,
    });
    fetchCheckInData({
      filter: filterInitialValues,
      search,
      limit,
      page: DEFAULT_PAGE,
    });
    localStorage.removeItem(localFilterDataKey);
    setFilterData(filterInitialValues);
  };
  return (
    <>
      {/* Hidden input field for barcode focussing purpose */}
      <input
        id="hiddenInput"
        autoFocus
        autoComplete="false"
        className={classes.hiddenInput}
      />
      <Helmet>
        <title>Asset Check In | AIM</title>
      </Helmet>
      <div className="checkInTable">
        <TableComp
          title="Asset Check In"
          data={assets.assets}
          dataPagination={assets.pagination}
          onSearch={onSearch}
          onFilterSubmit={onFilterSubmit}
          onFilterReset={onFilterReset}
          columns={columns}
          errorMessage={errorMessage || errorMsg}
          loading={assetsLoading}
          clearMessages={() => {
            clearMessage(CLEAR_ALL_MESSAGES);
            setErrorMsg("");
            document.getElementById("hiddenInput")?.focus();
          }}
          filterData={filterData}
          filterConfig={filterConfig}
          tableConfig={tableOpt}
          changeTableConfig={(tableConf: any) => {
            setOperation(tableConf);
            fetchCheckInData({ filter: filterData, search, ...tableConf });
            document.getElementById("hiddenInput")?.focus();
          }}
          filterInitialValues={filterInitialValues}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          allCampuses={allCampuses}
          scannedAsset={barcodeRead}
          onSearchKeyPress={manualScanInput} //afterScan(barcodeRead);
        />
      </div>
    </>
  );
}

const mapStateToProps = ({
  aimAssetCheckInState,
  campusManagement,
  itemManagement,
}: IRootState) => ({
  assets: aimAssetCheckInState.assets,
  assetsLoading: aimAssetCheckInState.assetsLoading,
  errorMessage: aimAssetCheckInState.errorMessage,
  filterData: aimAssetCheckInState.filterData,
  allCampuses: campusManagement.allCampuses,
  campusLoading: campusManagement.loading,
  allItems: itemManagement.allItems,
});
const mapDispatchToProps = {
  fetchAllItems,
  clearMessage,
  setFilterData,
  fetchAllCampuses,
  fetchCheckInData,
  clearAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetCheckIn);
